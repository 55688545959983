// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._31iA9{padding-right:20px;padding-left:20px}._3DjEE{max-width:1200px;margin:0 auto;padding:0 0 40px}", ""]);
// Exports
exports.locals = {
	"root": "_31iA9",
	"container": "_3DjEE"
};
module.exports = exports;
