import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './RankingList.scss';
import ListFilter from '../ListFilter';
import Top3 from '../Top3';
import RankingListTable from '../RankingListTable/RankingListTable';

const CommunityRankPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  micrositeLogoUrl: PropTypes.string,
  url: PropTypes.string.isRequired,
  currentRank: PropTypes.number.isRequired,
  previousRank: PropTypes.number.isRequired,
  timeInMinutes: PropTypes.number,
});

const COMMUNITIES_PER_PAGE = 50;

const FILTER_THRESHOLD = 3;

class RankingList extends React.Component {
  static propTypes = {
    group: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      short: PropTypes.string,
    }).isRequired,
    rest: PropTypes.arrayOf(CommunityRankPropType).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  state = {
    page: 1,
    filterText: '',
    list: this.props.rest.slice(0, COMMUNITIES_PER_PAGE),
  };

  onFilterChange(text) {
    const { rest } = this.props;

    if (!text || text.length < 3) {
      this.setState({
        page: 1,
        filterText: text,
        list: rest.slice(0, COMMUNITIES_PER_PAGE),
      });
    } else {
      const filteredList = this.filterList(rest, text);

      this.setState({
        filterText: text,
        list: filteredList,
      });
    }
  }

  showMore = () => {
    const { rest } = this.props;
    const { page } = this.state;

    this.setState({
      page: page + 1,
      list: rest.slice(0, (page + 1) * COMMUNITIES_PER_PAGE),
    });
  }

  filterList(list, text) {
    if (!text) {
      return list;
    }

    return list.filter(community => {
      const name = community.name.toLowerCase();
      const searchText = text.toLowerCase();

      return name.indexOf(searchText) > -1;
    });
  }

  render() {
    const { group, rest } = this.props;
    const { i18n } = this.context;
    const { list, filterText } = this.state;

    const [first, second, third] = rest;

    const filtered = filterText && filterText.length >= FILTER_THRESHOLD;

    return (
      <div className="componentContainer">
        <div className={s.categoryDescription}>{group.subtitle}</div>
        <p className={s.prizes}>{i18n.t('ranking.prizes')}</p>
        <div>
          <Top3 rank1={first} rank2={second} rank3={third} short={group.short} />
        </div>
        <div className={s.listFilter}>
          <ListFilter
            className={s.listFilterContainer}
            filter={filterText}
            placeholder={i18n.t('ranking.search')}
            onChange={text => this.onFilterChange(text)}
          />
        </div>
        <RankingListTable
          list={list.slice(filtered ? 0 : 3)}
          displayShowMore={!filtered && list.length < rest.length}
          onShowMore={this.showMore}
        />
      </div>
    );
  }
}

export default withStyles(s)(RankingList);
