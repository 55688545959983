import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './MobileNavigationOverlay.scss';
import classNames from '../../utils/classNames';

class MobileNavigationOverlay extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { open } = this.props;

    const navigationOverlayClasses = classNames(s, {
      'navigation-overlay': true,
      'navigation-overlay--open': open,
    });

    return (
      <div className={navigationOverlayClasses}>
        <div className={s['navigation-overlay__backdrop']} role="presentation" />
        <div className={s['navigation-overlay__content']}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(MobileNavigationOverlay);
