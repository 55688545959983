// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tabContainer{padding-top:15px}._1miJ4{display:block;overflow:hidden}._1miJ4,._2l1gj{position:relative}._2l1gj{display:-webkit-box;display:-ms-flexbox;display:flex;margin:0 auto;border-bottom:1px solid rgba(148,158,178,.4);text-align:center;overflow:scroll}._32jVe{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;padding:0 15px}.SGCCH{display:inline-block;position:relative;margin:0 5px 0 0;padding:12px 40px;border:0;border-radius:5px 5px 0 0;background:rgba(148,158,178,.2);color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase;white-space:nowrap;z-index:2}.SGCCH:last-of-type{margin-right:0}.SGCCH.active{outline:0;background:#ea5b0c;color:#fff}.SGCCH.active:hover{color:#fff}.SGCCH:hover{outline:0;color:#ea5b0c;cursor:pointer}@media(min-width:768px){.SGCCH{padding:12px 82px}._2l1gj{display:block;overflow:hidden}._1miJ4{margin:0;padding:0;overflow:visible}._1miJ4:after,._1miJ4:before{height:47px}}", ""]);
// Exports
exports.locals = {
	"tabList": "_1miJ4",
	"tabNav": "_2l1gj",
	"tabNavInner": "_32jVe",
	"tabButton": "SGCCH"
};
module.exports = exports;
