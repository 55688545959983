import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import s from './Event.scss';
import PdfDownloadLink from '../PdfDownloadLink';
import { createUrlsInString } from '../../utils/utility';

class Event extends React.Component {
  static propTypes = {
    event: PropTypes.shape({}).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    const { i18n } = this.context;

    moment.locale(i18n.t('moment.locale'));
  }

  getFilenameFromUrl(url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  render() {
    const { i18n } = this.context;
    const { event } = this.props;

    const startDate = moment.tz(event.startDate, i18n.t('moment.timezone'));
    const endDate = moment.tz(event.endDate, i18n.t('moment.timezone'));
    const isSameDay = startDate.isSame(endDate, 'day');

    moment.locale(i18n.t('moment.locale'));

    return (
      <div>
        <h3 className={s.title}>{event.name}</h3>

        <div className={s.container}>
          <div className={s.infoWrap}>
            <div className={s.tableCellDate}>
              <div className={s.dateWrap}>
                <span className={s.date}>
                  {!isSameDay && `${moment.tz(event.startDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateShort'))} - `}
                  {moment.tz(event.endDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateFormat'))}
                </span>
                {!event.isAllDay && (
                  <span className={s.time}>
                    {`${moment.tz(event.startDate, i18n.t('moment.timezone')).format('H:mm')} -
                    ${moment.tz(event.endDate, i18n.t('moment.timezone')).format('H:mm')} ${i18n.t('moment.hourShort')}`}
                  </span>
                )}
              </div>
            </div>
            <div className={`${s.tableCell} ${s.tableCellDivider}`}>
              <div className={s.divider} />
            </div>
            <div className={s.tableCell}>
              {event.address && <span className={s.location}>{event.address}</span>}
              {event.location && <span className={s.location}>{event.location}</span>}
            </div>
          </div>
          <div className={s.description}>
            {event.description && <p dangerouslySetInnerHTML={{ __html: createUrlsInString(event.description) }} />}
          </div>
          {event.linkUrl && (
            <div className={s.link}>
              <a href={event.linkUrl} target="_blank" rel="noopener noreferrer">
                {event.linkTitle || event.linkUrl}
              </a>
            </div>
          )}
          <div className={`row ${s.appendix}`}>
            {event.documents.length > 0 && (
              <div className={`col-md-6 ${s.links}`}>
                {event.documents.length > 0 && (
                  <div className={s.appendixTitle}>
                    {i18n.t('community.info.links')}
                  </div>
                )}
                {event.documents.map(item => (
                  <div className={s.downloadLinkWrap}>
                    <PdfDownloadLink link={item.url} label={this.getFilenameFromUrl(item.url)} />
                  </div>
                ))}
              </div>
            )}
            <div className={`col-md-6 ${s.organiser}`}>
              <div className={s.appendixTitle}>
                {i18n.t('registration.organizer')}
              </div>
              {event.contactName && <div>{event.contactName}</div>}
              {event.contactPhone && <div>{event.contactPhone}</div>}
              {event.contactEmail && (
                <div><a className={s.mail} href={`mailto:${event.contactEmail}`} target="_blank">{event.contactEmail}</a></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Event);
