import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import s from './SimpleEventItem.scss';
import Link from '../../Link';

class SimpleEventItem extends React.Component {
    static propTypes = {
      event: PropTypes.shape({}).isRequired,
    };

    static contextTypes = {
      i18n: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);

      const { i18n } = this.context;

      moment.locale(i18n.t('moment.locale'));
    }

    render() {
      const { i18n } = this.context;
      const { event } = this.props;

      return (
        <div className={s.eventWrap}>
          <Link to={event.url} target={event.target} className={s.link}>
            <div className={s.event}>
              <div className={s.name}>
                {event.communityName}
              </div>
              {event.isAllDay && (
                <div className={s.date}>
                  {moment.tz(event.startDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateFormat'))}
                </div>
              )}
              {!event.isAllDay && (
                <div className={s.date}>
                  <div>{`${moment.tz(event.startDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateFormat'))}`}</div>
                  <div>{`${moment.tz(event.startDate, i18n.t('moment.timezone')).format('HH:mm')} - ${moment.tz(event.endDate, i18n.t('moment.timezone')).format('HH:mm')} ${i18n.t('moment.hourShort')}`}</div>
                </div>
              )}
              <i className={`icon icon-chevron-right ${s.arrow}`} />
            </div>
          </Link>
        </div>
      );
    }
}

export default withStyles(s)(SimpleEventItem);
