import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './MarginalNavigation.scss';
import Link from '../Link';
import LocaleSwitcher from '../LocaleSwitcher';

class MarginalNavigation extends React.Component {
  static contextTypes = {
    store: PropTypes.object,
  };

  static propTypes = {
    simplified: PropTypes.bool,
  };

  static defaultProps = {
    simplified: false,
  };

  constructor(props, context) {
    super(props);

    const { store } = context;

    this.state = {
      items: store.get('marginal-navigation') || [],
    };
  }

  render() {
    const { items } = this.state;
    const { simplified } = this.props;

    return (
      <ul className={`nav ${s.marginalNavigation}`}>
        {!simplified && (
          <>
            {items.map(item => (
              <li className={`nav-item ${s.navItem}`} key={item.id}>
                <Link
                  className={`nav-link ${s.navLink}`}
                  key={item.id}
                  to={item.redirect || item.route}
                  target={item.redirect ? '_blank' : null}
                >
                  {item.name}
                </Link>
              </li>
            ))}
            <LocaleSwitcher />
          </>
        )}
      </ul>
    );
  }
}

export default withStyles(s)(MarginalNavigation);
