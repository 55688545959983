import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CommunityBadge.scss';

class CommunityBadge extends React.Component {
  static propTypes = {
    rank: PropTypes.number,
    micrositeLogoUrl: PropTypes.string,
    short: PropTypes.string.isRequired,
    size: PropTypes.string,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    rank: null,
    size: 'medium',
    micrositeLogoUrl: null,
  };

  getBadge(micrositeLogoUrl) {
    if (micrositeLogoUrl && micrositeLogoUrl !== 'string') {
      return `url(${micrositeLogoUrl})`;
    }

    return 'url(/badge_placeholder.gif)';
  }

  getSize(size) {
    if (size === 'medium') {
      return s.medium;
    }

    if (size === 'big') {
      return s.big;
    }

    return s.medium;
  }

  render() {
    const { rank, micrositeLogoUrl, short, size } = this.props;
    const { i18n } = this.context;

    return (
      <div>
        <div className={`${s.badgeContainer} ${this.getSize(size)}`}>
          {rank && (
            <div className={s.rankWrap}>
              <span className={s.rankLabel}>{i18n.t('map.rank')}</span>
              <span className={s.rank}>{rank}</span>
            </div>
          )}
          <img src={`/badge_${short}.svg`} className={s.badge} />
          <div className={s.communityBadge} style={{ backgroundImage: this.getBadge(micrositeLogoUrl) }} />
          <div className={s.badgeBackground} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CommunityBadge);
