import React from 'react';
import PropTypes from 'prop-types';
import ImageList from './ImageList';
import Paragraph from './Paragraph';
import PageTeaser from './PageTeaser';
import SponsorTeaser from './SponsorTeaser';
import CommunitiesTeaser from './CommunitiesTeaser';
import ImageGallery from './ImageGallery';
import TabList from './TabList';
import SimpleEventList from './SimpleEventList';
import HtmlEmbed from './HtmlEmbed';

class ComponentType extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
    wrapInContainer: PropTypes.bool,
    additionalClasses: PropTypes.string,
  };

  static defaultProps = {
    wrapInContainer: true,
    additionalClasses: '',
  };

  componentTypeSwitcher(data) {
    switch (data.type) {
      case 'default_page_teaser':
      case 'home_page_teaser':
      case 'ranking_page_teaser':
      case 'empty_page_teaser':
      case 'events_teaser':
        return <PageTeaser orientation={data.orientation} data={data} />;
      case 'communities_page_teaser':
        return <CommunitiesTeaser data={data} />;
      case 'sponsors_page_teaser':
        return <SponsorTeaser data={data} />;
      case 'paragraph':
        return <Paragraph content={data.content} />;
      case 'imageList':
        return <ImageList items={data.items} />;
      case 'tabList':
        return <TabList name={data.name} items={data.items} />;
      case 'event_list':
      case 'gym_event_list':
        return <SimpleEventList title={data.title} description={data.description} events={data.events} />;
      case 'imageGallery':
        return <ImageGallery items={data.items} />;
      case 'html':
        return <HtmlEmbed content={data.content} />;
      default:
        return <div>{data.type}</div>;
    }
  }

  render() {
    const { data, wrapInContainer, additionalClasses } = this.props;
    const component = this.componentTypeSwitcher(data);


    if (data.type === 'tabList') {
      return <div className={`componentContainer ${additionalClasses} tabContainer`}>{component}</div>;
    }

    if (data.type === 'event_list' || data.type === 'gym_event_list') {
      return (
        <div className="componentContainer componentContainerWhite">
          <div className="contentContainer">
            {component}
          </div>
        </div>
      );
    }

    if (data.type === 'imageGallery') {
      return (
        <div className="componentContainer">
          <div className="contentContainer contentContainerGallery">
            {component}
          </div>
        </div>
      );
    }

    if (data.type === 'communities_page_teaser') {
      return <div className={`componentContainer ${additionalClasses}`}><div className="contentContainer contentContainerMap">{component}</div></div>;
    }

    if (data.type === 'default_page_teaser' || data.type === 'empty_page_teaser' || data.type === 'ranking_page_teaser') {
      return (
        <div className="componentContainer">
          <div className="contentContainer teaserContainer">
            {component}
          </div>
        </div>
      );
    }

    if (data.type === 'sponsors_page_teaser') {
      return (
        <div className="componentContainer componentContainerWhite">
          <div className="contentContainer">
            {component}
          </div>
        </div>
      );
    }

    if (wrapInContainer) {
      return <div className={`componentContainer ${additionalClasses}`}><div className="contentContainer">{component}</div></div>;
    }

    return this.componentTypeSwitcher(data);
  }
}
/**
 *
 */

export default ComponentType;
