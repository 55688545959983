import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './NewsletterForm.scss';

class NewsletterForm extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object,
    fetch: PropTypes.func,
  };

  state = {
    confirmed: false,
    error: false,
  };

  onSubmit(event) {
    const { fetch } = this.context;

    event.preventDefault();

    const data = Object.fromEntries(new FormData(event.target));

    fetch('/api/newsletter/subscribe', {
      method: 'POST',
      body: JSON.stringify({
        email: data.EMAIL,
      }),
    })
      .then(() => {
        this.setState({ confirmed: true, error: false });
      })
      .catch(() => {
        this.setState({ error: true, confirmed: false });
      });
  }

  render() {
    const { i18n } = this.context;
    const { confirmed, error } = this.state;

    return (
      <>
        <form onSubmit={e => this.onSubmit(e)}>
          <div className="form-group">
            <input className={`form-control ${s.emailInput}`} placeholder={i18n.t('form.email')} id="EMAIL" name="EMAIL" type="email" />
          </div>
          <input type="submit" className={`btn btn-outline-secondary ${s.submit}`} name="subscribe" id="mc-embedded-subscribe" value={i18n.t('form.signup')} />
        </form>
        {confirmed && <p className={s.hint}>{i18n.t('form.success')}</p>}
        {error && <p className={s.hint}>{i18n.t('form.error')}</p>}
      </>
    );
  }
}

export default withStyles(s)(NewsletterForm);
