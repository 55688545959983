import React from 'react';
import PropTypes from 'prop-types';

import Cookies from '../../utils/Cookies';

const COOKIE_PRIVACY_TERMS = 'privacyTermsAccepted';
const COOKIE_PRIVACY_TERMS_ACCEPTED = '1';
const COOKIE_PRIVACY_TERMS_OPTOUT = '0';
const COOKIE_EXPIRATION = 31; // Cookie lifetime in days

// States the component can be in, using classes
const CLASS_STATE_FADE_IN = 'privacy-banner--fade-in';
const CLASS_STATE_FADE_OUT = 'privacy-banner--fade-out';
const CLASS_STATE_VISIBLE = 'privacy-banner--visible';
const CLASS_STATE_INVISIBLE = 'privacy-banner--invisible';

/**
 * Marks the privacy terms as accepted
 */
function setCookieAsAccepted() {
  Cookies.set(COOKIE_PRIVACY_TERMS, COOKIE_PRIVACY_TERMS_ACCEPTED, { expires: COOKIE_EXPIRATION });
}

/**
 * The cookie is regarded as set, if the user has allowed tracking or opted out.
 * @return {Boolean}
 */
function isCookieSet() {
  const cookie = Cookies.get(COOKIE_PRIVACY_TERMS);
  return cookie && (cookie === COOKIE_PRIVACY_TERMS_ACCEPTED || cookie === COOKIE_PRIVACY_TERMS_OPTOUT);
}

/**
 * Only appears if the cookie hasn't been set yet. It doesn't matter
 * if the user opted out or accepted the terms.
 */
export default class PrivacyBanner extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string,
    setCookie: PropTypes.bool,

    onPrivacy: PropTypes.func,
    onAccepted: PropTypes.func,
    onClosed: PropTypes.func,
  };

  static defaultProps = {
    linkUrl: null,
    setCookie: true,
    onPrivacy: () => {},
    onAccepted: () => {},
    onClosed: () => {},
  };

  state = {
    classState: CLASS_STATE_INVISIBLE,
  };

  componentDidMount() {
    const cookie = isCookieSet();

    this.setState({ classState: !cookie ? CLASS_STATE_VISIBLE : CLASS_STATE_INVISIBLE });
  }

  onAnimationEnd() {
    const { classState } = this.state;
    const { onClosed } = this.props;

    if (classState === CLASS_STATE_FADE_OUT) {
      onClosed();
      this.setState({ classState: CLASS_STATE_INVISIBLE });
    }
  }

  /**
   * Shows the privacy using the embedded link.
   * If no URL is passed, only the onPrivacy callback will be triggered.
   */
  showPrivacy(event) {
    const { linkUrl, onPrivacy } = this.props;

    if (linkUrl === null) {
      event.stopPropagation();
      event.preventDefault();
    }

    onPrivacy();
  }

  /**
   * Triggers an accepted event after setting the cookie
   * and closes the component.
   */
  accept() {
    const { onAccepted, setCookie } = this.props;

    if (setCookie) {
      setCookieAsAccepted();
    }

    onAccepted();
    this.close();
  }

  /**
   * Triggers a closed event and fades the component.
   */
  close() {
    this.setState({
      classState: CLASS_STATE_FADE_OUT,
    });
  }

  render() {
    const { title, text, linkUrl, linkText, buttonText } = this.props;
    const { classState } = this.state;

    return (
      <div
        className={`privacy-banner ${classState}`}
        role="alert"
        onAnimationEnd={() => this.onAnimationEnd()}
      >
        <div className="privacy-banner__container container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-xl-8">
              <span className="privacy-banner__title h4">{title}</span>
              <p className="privacy-banner__text">{text}</p>
            </div>
            <div className="privacy-banner__actions col-sm-12 col-md-5 col-xl-4">
              <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="privacy-banner__link btn btn-link"
                onClick={event => this.showPrivacy(event)}
              >
                {linkText}
              </a>
              <button
                className="btn-outline-primary privacy-banner__button btn btn-primary"
                onClick={() => this.accept()}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
