// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2k7vG{padding-right:20px;padding-left:20px}._17V-6{max-width:1200px;margin:0 auto;padding:0 0 40px}.cIsel{display:block;position:relative;margin-top:-24px;margin-bottom:40px;color:#949eb2;font-family:Montserrat,sans-serif;font-size:14px;font-weight:600;text-align:center;text-transform:uppercase}.cIsel:hover{color:#727a8b;text-decoration:none}.cIsel .icon{margin-right:10px}.IKWVs{max-width:1200px;margin:0 auto}.tabContainer{padding-top:15px}._1A72I{display:block;overflow:hidden}._1A72I,.POXmv{position:relative}.POXmv{display:-webkit-box;display:-ms-flexbox;display:flex;margin:0 auto;border-bottom:1px solid rgba(148,158,178,.4);text-align:center;overflow:scroll}._3mzOM{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;padding:0 15px}._2leNk{display:inline-block;position:relative;margin:0 5px 0 0;padding:12px 40px;border:0;border-radius:5px 5px 0 0;background:rgba(148,158,178,.2);color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase;white-space:nowrap;z-index:2}._2leNk:last-of-type{margin-right:0}._2leNk.active{outline:0;background:#ea5b0c;color:#fff}._2leNk.active:hover{color:#fff}._2leNk:hover{outline:0;color:#ea5b0c;cursor:pointer}._37GWV{display:none;position:relative;padding:32px 15px 42px;background-color:#fff}._37GWV.active{display:block}._37GWV>div{margin-bottom:50px}._37GWV>div:last-child{margin-bottom:0}@media(min-width:768px){.cIsel{display:inline-block;position:absolute;top:36px;margin-top:0;margin-bottom:0;text-align:left}._2leNk{padding:12px 82px}.POXmv{display:block;overflow:hidden}._1A72I{margin:0;padding:0;overflow:visible}._1A72I:after,._1A72I:before{height:47px}}", ""]);
// Exports
exports.locals = {
	"root": "_2k7vG",
	"container": "_17V-6",
	"backLink": "cIsel",
	"tabContentContainer": "IKWVs",
	"tabList": "_1A72I",
	"tabNav": "POXmv",
	"tabNavInner": "_3mzOM",
	"tabButton": "_2leNk",
	"tabContent": "_37GWV"
};
module.exports = exports;
