import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Communities.scss';
import CircleButton from '../../components/CircleButton';
import PageHeader from '../../components/PageHeader';
import { withInjectedLinks } from '../helper';
import SelectBox from '../../components/SelectBox';
import Link from '../../components/Link';
import ComponentType from '../../components/ComponentType';
import CommunitiesMap from '../../components/CommunitiesMap';
import ListFilter from '../../components/ListFilter';
import getTeaserOrientation from '../../utils/teaserOrientation';

/* eslint-disable no-nested-ternary */
const strcmp = (a, b) => (a < b ? -1 : (a > b ? 1 : 0));

class CommunityList extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      alphabeticalList: this.createAlphabeticalList(props.list.filter(community => !community.isPassive)),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.list) {
      this.setState({
        alphabeticalList: this.createAlphabeticalList(nextProps.list.filter(community => !community.isPassive)),
      });
    }
  }

  createAlphabeticalList(list) {
    const alphabeticalMap = {};
    const sortedAlphabeticalList = {};

    list.forEach(community => {
      if (community.expandedName) {
        const letter = community.expandedName[0].toLowerCase();

        if (!alphabeticalMap[letter]) {
          alphabeticalMap[letter] = [];
        }

        alphabeticalMap[letter].push(community);
      }
    });

    // Sort alphabetical list and sub lists
    Object.keys(alphabeticalMap).sort().forEach(letter => {
      sortedAlphabeticalList[letter] = alphabeticalMap[letter].sort((a, b) => strcmp(a.expandedName.toLowerCase(), b.expandedName.toLowerCase()));
    });

    return sortedAlphabeticalList;
  }

  renderCols() {
    const { alphabeticalList } = this.state;

    return Object.keys(alphabeticalList).map((letter, index) => {
      const list = alphabeticalList[letter];

      return <CommunityListCol key={index} letter={letter} list={list} />;
    });
  }

  render() {
    return (
      <div className="row">{ this.renderCols() }</div>
    );
  }
}

class CommunityListCol extends React.Component {
  static propTypes = {
    letter: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
  };

  render() {
    const { letter, list } = this.props;

    return (
      <div className="col-md-3 col-sm-6">
        <span className={s.colTitle}>{letter.toUpperCase()}</span>
        <ul className={s.colList}>
          {list.map(community => (
            <li key={community.id}>
              {community.url ? <Link to={community.url}>{community.expandedName}</Link> : community.expandedName}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

class Communities extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      navigation_name: PropTypes.string,
      pageTitle: PropTypes.string,
      communities: PropTypes.array,
      regions: PropTypes.array,
      season: PropTypes.shape({
        startDateTime: PropTypes.string,
        endDateTime: PropTypes.string,
        sandboxEndDateTime: PropTypes.string,
        micrositesActive: PropTypes.bool,
        isActive: PropTypes.bool,
      }),
      slices: PropTypes.array,
      footerTeasers: PropTypes.array,
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  state = {
    filterText: '',
    selectedRegion: null,
  };

  onRegionChange(region) {
    this.setState({
      selectedRegion: region,
    });
  }

  onFilterChange(text) {
    this.setState({ filterText: text });
  }

  resetFilter() {
    this.setState({
      filterText: '',
      selectedRegion: null,
    });
  }

  filterListByRegion(list, region) {
    return list.filter(community => {
      if (community.region && community.region.id) {
        return community.region.id === region.value;
      }
      return false;
    });
  }

  filterListByText(list, text) {
    return list.filter(community => {
      const base = community.baseCommunity;
      const zip = base && base.zip ? `${base.zip}` : '';
      const name = community.expandedName.toLowerCase();
      const searchText = text.toLowerCase();

      return name.indexOf(searchText) > -1 || zip.indexOf(searchText) > -1;
    });
  }

  filterList(list) {
    const { filterText, selectedRegion } = this.state;

    if (!filterText && selectedRegion === null) {
      return list;
    }

    if (selectedRegion) {
      list = this.filterListByRegion(list, selectedRegion);
    }

    if (filterText) {
      list = this.filterListByText(list, filterText);
    }

    return list;
  }

  isSeasonRunning() {
    const { content: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);
    const end = +new Date(season.endDateTime);

    return now >= start && now <= end;
  }

  hasSeasonStarted() {
    const { content: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);

    return now >= start;
  }

  render() {
    const { content } = this.props;
    const { i18n } = this.context;
    const { regions, communities } = content;
    const { filterText, selectedRegion } = this.state;

    let footerTeaserOrientationStart = 'left';
    let footerTeaser = '';

    let circleButton = null;

    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton label={contest.text} link={contest.link} />;
    }

    const regionsOptions = regions.map(region => ({
      value: region.id,
      label: region.name,
    }));

    const list = this.filterList(communities);
    const showCounter = this.hasSeasonStarted();

    return (
      <div>
        <PageHeader
          title={content.pageTitle}
          subheadline={content.subheadline}
          image={content.pageHeader}
        />
        {circleButton}
        <div className="contentContainer">
          <p className={s.intro}>{i18n.t('communities.intro')}</p>
          <ListFilter
            filter={filterText}
            placeholder={i18n.t('communities.search')}
            onChange={text => this.onFilterChange(text)}
          />
          <SelectBox
            name="regionsFilter"
            selected={selectedRegion}
            options={regionsOptions}
            onChange={option => this.onRegionChange(option)}
            className={s.select}
            placeholder={i18n.t('communities.selectCanton')}
          />
          <div className={s.buttonContainer}>
            <button
              className={s.resetButton}
              onClick={() => this.resetFilter()}
            >
              {i18n.t('communities.resetFilter')}
            </button>
          </div>
          <CommunityList list={list} />
        </div>
        {content.footerTeasers[0] && (
          <div className="footerTeasers">
            {content.footerTeasers.map(teaser => {
              teaser.orientation = footerTeaserOrientationStart;
              footerTeaser = <ComponentType key={teaser.id} additionalClasses="" data={teaser} />;
              footerTeaserOrientationStart = getTeaserOrientation(footerTeaserOrientationStart);
              return footerTeaser;
            })}
          </div>
        )}
        <div className="contentContainer contentContainerMap">
          <CommunitiesMap
            communities={communities}
            showCounter={showCounter}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(withInjectedLinks(Communities));
