import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TabItem.scss';
import ComponentType from '../../ComponentType';

class TabItem extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    slices: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
    index: PropTypes.number,
  };

  static defaultProps = {
    name: '',
    slices: [],
    index: null,
  };

  render() {
    const { name, slices, index } = this.props;

    let activeClass = '';

    if (index === 0) {
      activeClass = 'active';
    }

    return (
      <div className={`tabContent ${s.tabContent} ${activeClass}`} data-tab={index}>
        {slices.map((slice, mapIndex) => (
          <ComponentType data={slice} key={mapIndex} wrapInContainer={false} />
        ))}
      </div>
    );
  }
}

export default withStyles(s)(TabItem);
