import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SimpleEventList.scss';
import SimpleEventItem from './SimpleEventItem';
import ListFilter from '../ListFilter';

class SimpleEventList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    events: PropTypes.array.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    title: null,
    description: null,
  };

  state = {
    counter: this.props.events.length < 10 ? this.props.events.length : 10,
    events: this.props.events.slice(0, 10),
    filterText: '',
    showMoreButton: this.props.events.length > 10,
  };

  onFilterChange(text, events) {
    this.setState({ filterText: text, showMoreButton: false });

    if (!text) {
      this.setState({
        counter: events.length < 10 ? events.length : 10,
        events: events.slice(0, 10),
        showMoreButton: events.length > 10,
      });
    }
  }

  resetFilter() {
    this.setState({
      filterText: '',
    });
  }

  filterListByText(list, text) {
    return list.filter(community => {
      const name = community.communityName.toLowerCase();
      const searchText = text.toLowerCase();

      return name.indexOf(searchText) > -1;
    });
  }

  filterList(allEvents, limitedEvents) {
    const { filterText } = this.state;

    if (filterText) {
      return this.filterListByText(allEvents, filterText);
    }

    return limitedEvents;
  }

  showMore() {
    const { events } = this.props;
    const counter = this.state.counter + 10;

    this.setState({
      counter,
      events: events.slice(0, counter),
      showMoreButton: counter < events.length,
    });
  }

  render() {
    const { i18n } = this.context;
    const { title, description, events } = this.props;
    const { filterText, showMoreButton } = this.state;

    const list = this.filterList(events, this.state.events);

    return (
      <div className={`${s.container} eventListWrap`}>
        {title && (
          <h3 className={s.title}>{title}</h3>
        )}
        {description && (
          <div>
            <div className={`paragraph ${s.paragraph}`} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        )}
        <ListFilter
          filter={filterText}
          placeholder={i18n.t('communities.search')}
          onChange={text => this.onFilterChange(text, events)}
        />
        {list.map((event, index) => (
          <SimpleEventItem key={event.id} index={index} event={event} />
        ))}
        {showMoreButton && (
          <div className={s.moreLinkWrap}>
            <button className={s.moreLink} onClick={(e => this.showMore(e))}>{i18n.t('communities.showMore')}</button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(SimpleEventList);
