import React from 'react';
import Layout from '../../components/Layout';
import NotFound from './NotFound';

async function action({ i18n, path }) {
  const title = i18n.t('notFound.title');

  return {
    title,
    id: 'not-found',
    path,
    uid: 'not-found',
    component: (
      <Layout>
        <NotFound title={title} />
      </Layout>
    ),
    status: 404,
  };
}

export default action;
