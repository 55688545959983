export const HTTP_ERROR_NETWORK_FAILURE = 0;
export const HTTP_STATUS_CODE_BAD_REQUEST = 400;
export const HTTP_STATUS_CODE_NOT_FOUND = 404;

export class HttpError extends Error {
  constructor(statusCode, message) {
    super(message);

    this.statusCode = statusCode;
  }
}

export default HttpError;
