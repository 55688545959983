/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
} from 'react-vis';
import { timeFormatDefaultLocale, timeFormat } from 'd3-time-format';
import s from './LineGraph.scss';
import { getTeamColor } from '../../utils/utility';

class LineGraph extends React.Component {
  static propTypes = {
    series: PropTypes.array.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const { i18n } = context;

    timeFormatDefaultLocale(i18n.t('d3', { returnObjects: true }));
  }

  render() {
    const { i18n } = this.context;
    const { series } = this.props;

    let newTickTotal = 5;

    if (series.length !== 0) {
      if (series[0].data.length < 5) {
        newTickTotal = (series[0].data.length - 1);
      }
    }

    return (
      <FlexibleXYPlot xType="time-utc">
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis
          tickFormat={v => timeFormat('%d %b')(v).toUpperCase()}
          tickTotal={newTickTotal}
        />
        <YAxis title={i18n.t('ranking.minutes')} />
        {series.map((set, i) => (
          <LineSeries
            data={set.data}
            key={set.teamId}
            color={getTeamColor(i)}
            strokeWidth={4}
          />
        ))}
      </FlexibleXYPlot>
    );
  }
}

export default withStyles(s)(LineGraph);
