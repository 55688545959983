import moment from 'moment-timezone';

export function renderDateTime(event, i18n) {
  moment.locale(i18n.t('moment.locale'));

  const today = moment.tz(i18n.t('moment.timezone')).startOf('day');
  const endToday = moment.tz(i18n.t('moment.timezone')).endOf('day');

  const startDate = moment.tz(event.startDate, i18n.t('moment.timezone'));
  const endDate = moment.tz(event.endDate, i18n.t('moment.timezone'));

  if (startDate.isAfter(endToday)) {
    let time = '';
    if (!event.isAllDay) {
      time = `<div class="eventTime">${startDate.format('H:mm')} - ${endDate.format('H:mm')} ${i18n.t('moment.hourShort')}</div>`;
    }

    if (startDate.isSame(endDate, 'day')) {
      return `<div class="eventDate">${startDate.format(i18n.t('moment.dateShort'))}</div>${time}`;
    }
    return `<div class="eventDate">${startDate.format(i18n.t('moment.dateShort'))} - ${endDate.format(i18n.t('moment.dateShort'))}</div>${time}`;
  }

  // today
  if (startDate.isSameOrAfter(today) && endDate.isSameOrBefore(endToday)) {
    if (event.isAllDay) {
      return i18n.t('moment.allDay');
    }
    return `${startDate.format('H:mm')} - ${endDate.format('H:mm')} ${i18n.t('moment.hourShort')}`;
  }

  // started in the past
  if (startDate.isBefore(today)) {
    // ends today
    if (endDate.isSameOrBefore(endToday)) {
      // is allday (no time)
      if (event.isAllDay) {
        return i18n.t('moment.untilToday');
      }
      // has time
      return `${i18n.t('moment.untilTime').toLowerCase()} ${endDate.format('H:mm')} ${i18n.t('moment.hourShort')}`;
    }

    // ends in the future
    if (endDate.isAfter(endToday)) {
      if (event.isAllDay) {
        return `${i18n.t('moment.untilDate')} ${endDate.format(i18n.t('moment.dateFormat'))}`;
      }
      return `${i18n.t('moment.untilDate')} ${endDate.format(i18n.t('moment.dateFull'))} ${i18n.t('moment.hourShort')}`;
    }
  }

  // starts today, ends on another day
  if (startDate.isSameOrAfter(today) && endDate.isAfter(endToday)) {
    if (event.isAllDay) {
      return `${i18n.t('moment.untilDate')} ${endDate.format(i18n.t('moment.dateFormat'))}`;
    }
    return `${i18n.t('moment.from')} ${startDate.format('H:mm')} ${i18n.t('moment.hourShort')}`;
  }
  return null;
}
