// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1wdpd{margin-top:0;margin-bottom:70px;text-align:center}._1wdpd:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._3EyQD{display:block;margin-top:2.625rem;text-align:center}._2e9s5{position:relative;color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase}._2e9s5:hover{color:#727a8b;text-decoration:none}._2e9s5:after{position:absolute;padding-top:3px;padding-left:20px;font-family:Material-Design-Iconic-Font;content:\"\"}", ""]);
// Exports
exports.locals = {
	"title": "_1wdpd",
	"moreLinkWrap": "_3EyQD",
	"moreLink": "_2e9s5"
};
module.exports = exports;
