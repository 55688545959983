// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dFm8u{width:100%;max-width:1200px;margin:0 auto}._2UdpH{position:relative;margin-bottom:60px}._2UdpH:last-child{margin-bottom:0}._1TSrf{position:relative;margin-bottom:22px;text-align:center}._28y-o{width:100%}._1iOu7{display:block;position:relative;bottom:0}._3CWDK{padding-bottom:6px;color:#373a3c}._2wQKj:hover{text-decoration:none}._3WIdC{padding-right:10px;color:#949eb2;font-family:Montserrat,sans-serif;font-size:14px;font-weight:600;text-transform:uppercase}._3WIdC:hover{color:#727a8b}.moreIcon{display:inline-block}.moreIcon:after{padding-left:20px;font-family:Material-Design-Iconic-Font;white-space:nowrap;content:\"\"}@media(min-width:768px){._2ooaj{margin-bottom:-60px}}@media(min-width:992px){._3CWDK{padding-bottom:50px}._1iOu7{position:absolute}._1TSrf{margin-bottom:0}._6d6B-{padding:0 6px}._28y-o{width:280px}}", ""]);
// Exports
exports.locals = {
	"imageList": "dFm8u",
	"imageListItem": "_2UdpH",
	"imageWrap": "_1TSrf",
	"image": "_28y-o",
	"linkWrap": "_1iOu7",
	"text": "_3CWDK",
	"link": "_2wQKj",
	"more": "_3WIdC",
	"imageListRow": "_2ooaj",
	"imageCol": "_6d6B-"
};
module.exports = exports;
