import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TrendIcon.scss';

class TrendIcon extends React.Component {
  static propTypes = {
    now: PropTypes.number.isRequired,
    previous: PropTypes.number.isRequired,
    circle: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    circle: true,
    className: '',
  };

  render() {
    const { now, previous, circle, className } = this.props;
    let circleClass = '';

    if (!circle) {
      circleClass = 'no-circle';
    }

    if (previous === 0 || previous === null) {
      // same
      return <i className={`icon icon-arrow-right ${s.trendIcon} ${circleClass} ${s.same}`} />;
    }

    if (now > previous) {
      // down
      return <i className={`icon icon-arrow-right ${s.trendIcon} ${circleClass} ${s.down}`} />;
    }

    if (now < previous) {
      // up
      return <i className={`icon icon-arrow-right-top ${s.trendIcon} ${circleClass} ${s.up}`} />;
    }

    // same
    return <i className={`icon icon-arrow-right ${s.trendIcon} ${circleClass} ${s.same} ${className}`} />;
  }
}

export default withStyles(s)(TrendIcon);
