// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3iczC{padding-top:10px;background-color:#f5f7fa}._3aeQx{max-width:1200px;margin-right:auto;margin-left:auto;padding:61px 15px 82px}", ""]);
// Exports
exports.locals = {
	"top3": "_3iczC",
	"wrap": "_3aeQx"
};
module.exports = exports;
