// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2iiU9{max-width:580px;margin:0 auto;text-align:left}._2iiU9 p{margin-bottom:24px}._2iiU9 h1{position:relative;margin-top:60px;margin-bottom:70px;text-align:center}._2iiU9 h1:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}", ""]);
// Exports
exports.locals = {
	"container": "_2iiU9"
};
module.exports = exports;
