// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3He7g{width:24px;height:24px;border:1px solid #949eb2;border-radius:50px;background:#fff;font-size:22px;text-align:center}._3He7g.no-circle{border:0;color:#949eb2!important}._1mdVG{border-color:#5cb85c;color:#5cb85c}._1PUOs{color:#949eb2}._2oXQ2{-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);border-color:#e40521;color:#e40521}", ""]);
// Exports
exports.locals = {
	"trendIcon": "_3He7g",
	"up": "_1mdVG",
	"same": "_1PUOs",
	"down": "_2oXQ2"
};
module.exports = exports;
