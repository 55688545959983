// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-FMHI{position:relative;margin-top:60px;margin-bottom:70px;padding:0 15px;text-align:center}.-FMHI:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._2IWAv{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:120px;margin:0 0 30px;background-color:hsla(0,0%,100%,.25);text-align:center;text-transform:uppercase}._2IWAv .tick{font-size:2.6875rem}._2IWAv .tick-group{margin-right:10px;margin-left:10px}._2IWAv .tick-group .tick-flip{margin:0;line-height:1.8em}._2IWAv .tick-group .tick-flip-panel-text-wrapper{line-height:1.8em}._2IWAv .tick-separator{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:10px;height:22px;margin-top:-22px;border-radius:3.5px;background:#373737;opacity:.5}._2IWAv .tick-label{font-size:.625rem;display:block;margin-top:10px;color:rgba(55,55,55,.5);font-family:Roboto Condensed,sans-serif}._2IWAv .tick-flip-panel{color:#000}._2IWAv .tick-flip-panel-text-wrapper{line-height:1.45}._2IWAv .tick-flip-panel{background-color:#fff}._2IWAv .tick-flip{border-radius:.12em}._3rLEo{display:block;margin-bottom:5.3125rem;text-align:center}._27kjc{position:relative;color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase}._27kjc:hover{color:#727a8b;text-decoration:none}._27kjc:after{position:absolute;padding-top:3px;padding-left:20px;font-family:Material-Design-Iconic-Font;content:\"\"}@media(min-width:768px){.Nwt4O{padding:12px 82px}._3_vTL{display:block;overflow:hidden}._1lKvs{margin:0;padding:0;overflow:visible}._1lKvs:after,._1lKvs:before{height:47px}._2IWAv{height:162px;margin:0 0 25px}._2IWAv .tick{font-size:3.375rem}._2IWAv .tick-label{font-size:.8125rem}}", ""]);
// Exports
exports.locals = {
	"countdownTitle": "-FMHI",
	"countdownContainer": "_2IWAv",
	"moreLinkWrap": "_3rLEo",
	"moreLink": "_27kjc",
	"tabButton": "Nwt4O",
	"tabNav": "_3_vTL",
	"tabList": "_1lKvs"
};
module.exports = exports;
