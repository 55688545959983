import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import ChallengeRankingList from '../../components/ChallengeRankingList/ChallengeRankingList';
import PageHeader from '../../components/PageHeader';
import s from './Challenge.scss';

class Challenge extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      name: PropTypes.string,
      logoUrl: PropTypes.string,
      backgroundImageUrl: PropTypes.string,
      primaryColor: PropTypes.string,
      season: PropTypes.shape({
        startDateTime: PropTypes.string,
        endDateTime: PropTypes.string,
        sandboxEndDateTime: PropTypes.string,
        micrositesActive: PropTypes.bool,
        isActive: PropTypes.bool,
      }),
      ranking: PropTypes.array,
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  render() {
    const { content } = this.props;

    return (
      <div className={s.challengePage}>
        <PageHeader
          title={content.name}
          image={{ url: content.backgroundImageUrl }}
          logo={{ url: content.logoUrl }}
          faded={!!content.backgroundImageUrl}
        />
        <ChallengeRankingList
          top3={content.ranking.slice(0, 3)}
          rest={content.ranking.slice(3)}
        />
      </div>
    );
  }
}

export default withStyles(s)(Challenge);
