// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1yaRH{margin-top:0;margin-bottom:70px;text-align:center}._1yaRH:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._1_YZw{margin-top:30px}._1_YZw +.homeBodyTeasers{margin-top:-30px}@media(min-width:768px){._1_YZw{margint-top:0}}", ""]);
// Exports
exports.locals = {
	"title": "_1yaRH",
	"circleButton": "_1_YZw"
};
module.exports = exports;
