import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PageTeaser.scss';
import Link from '../Link';

class PageTeaser extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      lead: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      linkLabel: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
      }),
    }).isRequired,
    orientation: PropTypes.string.isRequired,
  };

  getText(title, lead, linkLabel) {
    return (
      <div className={`col-md-6 ${s.textCol}`}>
        <h4 className={s.title}>{title}</h4>
        <div className={s.lead} dangerouslySetInnerHTML={{ __html: lead }} />
        <div className={s.more}>{linkLabel}</div>
      </div>
    );
  }

  getImage(image) {
    return (
      <div className={`col-md-6 ${s.imageCol}`}>
        <div className={s.imageWrap} style={{ backgroundImage: `url(${image.url})` }} />
      </div>
    );
  }

  render() {
    const { data: { title, lead, id, linkLabel, image, link }, orientation } = this.props;

    if (orientation === 'left') {
      return (
        <div className={`teaser teaser-${orientation} ${s.teaser}`}>
          <Link to={link.url} target={link.target} className={s.link}>
            <div className="row">
              <div className="col-md-12">
                <h4 className={s.titleMobile}>{title}</h4>
              </div>
              {this.getText(title, lead, linkLabel)}
              {this.getImage(image)}
            </div>
          </Link>
        </div>
      );
    }

    return (
      <div className={`teaser teaser-${orientation} ${s.teaser}`}>
        <Link to={link.url} target={link.target} className={s.link}>
          <div className="row">
            <div className="col-md-12">
              <h4 className={s.titleMobile}>{title}</h4>
            </div>
            {this.getImage(image)}
            {this.getText(title, lead, linkLabel)}
          </div>
        </Link>
      </div>
    );
  }
}

export default withStyles(s)(PageTeaser);
