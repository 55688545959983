/**
 * ES6 version of JavaScript Cookie.
 * @see https://github.com/js-cookie/js-cookie
 * Converted to ES6 by Christoph Dörfel <christoph.doerfel@yooapps.com>
 */
class Cookies {
  /**
   * @param converter
   */
  constructor(converter) {
    this.converter = converter;
    this.defaults = {};
  }

  /**
   * @param {String} key
   * @param {String|Array|Object} value
   * @param {Object} [attributes]
   * @returns {String|Boolean}
   */
  set(key, value, attributes = {}) {
    if (typeof document === 'undefined') {
      return false;
    }

    const combinedAttributes = { path: '/', ...this.defaults, ...attributes };

    if (typeof combinedAttributes.expires === 'number') {
      const expires = new Date();
      expires.setMilliseconds(expires.getMilliseconds() + (combinedAttributes.expires * 864e+5));
      combinedAttributes.expires = expires;
    }

    // We're using "expires" because "max-age" is not supported by IE
    combinedAttributes.expires = combinedAttributes.expires ? combinedAttributes.expires.toUTCString() : '';

    let encodedValue = value;
    try {
      const result = JSON.stringify(encodedValue);
      if (/^[{[]/.test(result)) {
        encodedValue = result;
      }
    } catch (e) {
      // Do nothing
    }

    if (!this.converter.write) {
      encodedValue = encodeURIComponent(String(encodedValue))
        .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);
    } else {
      encodedValue = this.converter.write(encodedValue, key);
    }

    let encodedKey = encodeURIComponent(String(key));
    encodedKey = encodedKey.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
    encodedKey = encodedKey.replace(/(?=[()])/g, '\\');

    let stringifiedAttributes = '';

    const attributeNames = Object.keys(combinedAttributes);
    for (let i = 0, l = attributeNames.length, attributeName; i < l; i += 1) {
      attributeName = attributeNames[i];
      if (combinedAttributes[attributeName]) {
        stringifiedAttributes += `; ${attributeName}`;
        if (combinedAttributes[attributeName] !== true) {
          stringifiedAttributes += `=${combinedAttributes[attributeName]}`;
        }
      }
    }
    const cookie = `${encodedKey}=${encodedValue}${stringifiedAttributes}`;
    document.cookie = cookie;
    return cookie;
  }

  /**
   * @param {String} [key]
   * @param {Boolean} [json]
   * @returns {*}
   */
  get(key, json = false) {
    let result;
    if (typeof document === 'undefined') {
      return result;
    }

    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all. Also prevents odd result when
    // calling "get()"
    const cookies = document.cookie ? document.cookie.split('; ') : [];
    const rdecode = /(%[0-9A-Z]{2})+/g;

    for (let i = 0; i < cookies.length; i += 1) {
      const parts = cookies[i].split('=');
      let cookie = parts.slice(1).join('=');

      if (cookie.charAt(0) === '"') {
        cookie = cookie.slice(1, -1);
      }

      try {
        const name = parts[0].replace(rdecode, decodeURIComponent);
        cookie = this.converter.read
          ? this.converter.read(cookie, name) : this.converter(cookie, name)
          || cookie.replace(rdecode, decodeURIComponent);

        if (json === true) {
          try {
            cookie = JSON.parse(cookie);
          } catch (e) {
            // Do nothing
          }
        }

        if (key === name) {
          result = cookie;
          break;
        }

        if (!key) {
          result[name] = cookie;
        }
      } catch (e) {
        // Do nothing
      }
    }

    return result;
  }

  /**
   * @param {String} [key]
   * @returns {*}
   */
  getJSON(key) {
    return this.get(key, true);
  }

  /**
   * @param {String} key
   * @param {Object} [attributes]
   * @returns {String|Boolean}
   */
  remove(key, attributes = {}) {
    return this.set(key, '', { ...attributes, expires: -1 });
  }

  /**
   * @param converter
   * @returns {Cookies}
   */
  static withConverter(converter) {
    return new Cookies(converter);
  }
}

export default new Cookies(() => {});
