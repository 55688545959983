// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._200UM{display:inline-block;max-width:100%}._2zu0W{margin-bottom:60px}@media(min-width:768px){._200UM{width:180px}}", ""]);
// Exports
exports.locals = {
	"image": "_200UM",
	"sponsor": "_2zu0W"
};
module.exports = exports;
