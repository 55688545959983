// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ox0N{position:absolute;top:0;left:0}._3srOr{position:fixed;top:-10px;left:0;width:100%;height:0;-webkit-transition:opacity .2s ease-in-out,visibility 0s linear .2s,top 0s linear .2s,height 0s linear .2s;transition:opacity .2s ease-in-out,visibility 0s linear .2s,top 0s linear .2s,height 0s linear .2s;background:#f5f7fa;opacity:0;visibility:hidden;z-index:1000}._dTp2 ._3srOr{top:0;height:100%;-webkit-transition:opacity .2s ease-in-out;transition:opacity .2s ease-in-out;opacity:1;visibility:visible}.CrMH3{position:fixed;top:0;left:50%;width:100%;max-width:1170px;max-height:100vh;padding:49px 0 100px;-webkit-transform:translate(-50%,-100%);-ms-transform:translate(-50%,-100%);transform:translate(-50%,-100%);-webkit-transition:opacity 0s linear .2s,-webkit-transform .2s ease-in-out;transition:opacity 0s linear .2s,-webkit-transform .2s ease-in-out;transition:transform .2s ease-in-out,opacity 0s linear .2s;transition:transform .2s ease-in-out,opacity 0s linear .2s,-webkit-transform .2s ease-in-out;background:#fff;-webkit-box-shadow:0 0 10px 0 rgba(0,0,0,.1);box-shadow:0 0 10px 0 rgba(0,0,0,.1);opacity:0;overflow-y:scroll;z-index:1000;-webkit-box-sizing:border-box;box-sizing:border-box}._dTp2 .CrMH3{-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);-webkit-transition:opacity 0s linear,-webkit-transform .2s ease-in-out;transition:opacity 0s linear,-webkit-transform .2s ease-in-out;transition:transform .2s ease-in-out,opacity 0s linear;transition:transform .2s ease-in-out,opacity 0s linear,-webkit-transform .2s ease-in-out;opacity:1}", ""]);
// Exports
exports.locals = {
	"navigation-overlay": "_3Ox0N",
	"navigation-overlay__backdrop": "_3srOr",
	"navigation-overlay--open": "_dTp2",
	"navigation-overlay__content": "CrMH3"
};
module.exports = exports;
