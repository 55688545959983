// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3CAJa{display:none;padding-top:24px;float:right}.eEz4a{font-size:.875rem}.Ahnte{color:#949eb2;font-family:Montserrat,sans-serif;font-weight:600;text-transform:uppercase}.Ahnte.active,.Ahnte:hover{position:relative;color:#ea5b0c}.Ahnte.active:before,.Ahnte:hover:before{position:absolute;top:-18px;right:1rem;left:1rem;width:auto;height:1px;padding:0 1rem;background:#ea5b0c;content:\"\"}@media(min-width:992px){._3CAJa{display:-webkit-box;display:-ms-flexbox;display:flex}.is-header-shrunk ._3CAJa{padding-top:2px;-webkit-transition:all .3s ease;transition:all .3s ease}}", ""]);
// Exports
exports.locals = {
	"mainNavigation": "_3CAJa",
	"navItem": "eEz4a",
	"navLink": "Ahnte"
};
module.exports = exports;
