export function loaded(fn) {
  if (document.readyState === 'complete') {
    fn();
  } else {
    window.addEventListener('load', fn, false);
  }
}

/* eslint-disable no-param-reassign */
export function addClass(el, c) {
  if (el.classList) {
    el.classList.add(c);
  } else {
    el.className = `${el.className}  ${c}`;
  }
}

export function removeClass(el, c) {
  if (el.classList) {
    el.classList.remove(c);
  } else {
    el.className = el.className.replace(new RegExp(`(^|\\b)${c.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
  }
}

export function hasClass(el, c) {
  if (el.classList) {
    return el.classList.contains(c);
  }
  return new RegExp(`(^| )${c}( |$)`, 'gi').test(el.className);
}

// Transforms locale: de-ch to de-CH
export function upperLocale(locale, split = '-', join = '-') {
  // Transforms de-ch to de-CH
  let localeLang = locale.split(split);
  localeLang[1] = localeLang[1].toUpperCase();
  localeLang = localeLang.join(join);

  return localeLang;
}

function checkDomain(url) {
  const { location } = document;

  if (url.indexOf('//') === 0) {
    url = location.protocol + url;
  }
  return url.toLowerCase().replace(/([a-z])?:\/\//, '$1').split('/')[0];
}

export function isExternalUrl(url) {
  const { location } = document;
  return ((url.indexOf(':') > -1 || url.indexOf('//') > -1) && checkDomain(location.href) !== checkDomain(url));
}

export function getInvariantPath(defaultLocale, route) {
  const { alternateLocales, path } = route;

  if (!alternateLocales[defaultLocale]) {
    // No default alternate found, use current path
    return path;
  }
  // Default locale is an alternate, use it
  return alternateLocales[defaultLocale].path;
}

export function pushDataLayer(eventObject) {
  if (process.env.BROWSER) {
    const { dataLayer } = window;

    if (dataLayer) {
      dataLayer.push(eventObject);
    }
  }
}

export function pathsMatch(actualPath, paths) {
  const actual = actualPath.split('/');

  return paths.some(p => {
    const check = p.split('/');
    const len = check.length;

    if (actual.length < check.length) {
      return false;
    }

    let block = true;
    let i = 0;
    while (block && i < len) {
      const actualPart = actual[i];
      const checkPart = check[i];

      if (checkPart === '?') {
        // Ignore
      } else if (actualPart !== checkPart) {
        block = false;
      }

      i += 1;
    }

    return block;
  });
}

export function getBaseUrl(protocol, host, port) {
  const portPrefix = port ? `:${port}` : '';
  return `${protocol}://${host}${portPrefix}/`;
}

export function isValidEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
}

/**
 * JavaScript Get URL Parameter
 *
 * @param String prop The specific URL parameter you want to retreive the value for
 * @return String|Object If prop is provided a string value is returned,
 * otherwise an object of all properties is returned
 */
export function getUrlParams(href, prop) {
  const params = {};
  const search = decodeURIComponent(href.slice(href.indexOf('?') + 1));
  const definitions = search.split('&');

  definitions.forEach((val, key) => {
    const parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });

  return (prop && prop in params) ? params[prop] : null;
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
}

export function getTeamColor(index) {
  const colors = [
    '#5cb85c',
    '#ee1034',
    '#31b0d5',
    '#eb9316',
    '#ef5350',
    '#949eb2',
    '#b8e986',
    '#ff4700',
    '#5bc0de',
    '#5d5a5a',
  ];

  return colors[index];
}

export function createUrlsInString(string) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return string.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
}
