/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './DuelTeams.scss';
import Link from '../Link';
import LineGraph from '../LineGraph';
import TrendIcon from '../TrendIcon';
import { formatNumber, getTeamColor } from '../../utils/utility';

class DuelTeams extends React.Component {
  static propTypes = {
    duelId: PropTypes.number.isRequired,
    currentCommunityId: PropTypes.number.isRequired,
    hasSeasonStarted: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
    fetch: PropTypes.func,
  };

  state = {
    teams: [],
    stats: [],
    tooltipOpen: false,
  };

  componentDidMount() {
    this.fetchTeams();
  }

  fetchTeams() {
    const { duelId } = this.props;
    const { fetch } = this.context;

    fetch(`/api/duels/${duelId}`,
      {
        method: 'GET',
      })
      .then(async res => {
        const responseData = await res.json();
        this.setState({ teams: responseData.teams, stats: responseData.stats });
      })
      .catch(error => {
      });
  }

  getBadge(micrositeLogoUrl) {
    if (micrositeLogoUrl && micrositeLogoUrl !== 'string') {
      return `url(${micrositeLogoUrl})`;
    }

    return 'url(/badge_placeholder.gif)';
  }

  getTotalMinutesBar(team, maxMinutes) {
    const barCalculator = 1.87;

    const onePercent = maxMinutes / 100;
    const barPercent = team.currentTotalMinutes / onePercent;
    const barWidth = barPercent * barCalculator;
    let styles = {};

    if (barWidth) {
      styles = {
        background: getTeamColor((team.currentRank - 1)),
        width: barWidth,
      };
    }

    return (
      <div className={`${s.minutesBar}`} style={styles} />
    );
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    const { duelId, currentCommunityId, hasSeasonStarted } = this.props;
    const { i18n, fetch } = this.context;
    const { teams, stats } = this.state;

    return (
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6">
          {teams && (
            <div className={s.teamWrap}>
              {teams.map((team, teamIndex) => (
                <div className={s.team} key={`${teamIndex}-${team.id}`}>
                  <div className={s.rank}>
                    {team.currentRank}.
                  </div>
                  <div className={s.teamInfo}>
                    <div className={s.teamName}>
                      {team.name}
                    </div>
                    <div className={s.communities}>
                      {team.communities.map((community, communityIndex) => (
                        <span className={s.communityLink} key={`${communityIndex}-${community.id}`}>
                          {(currentCommunityId === community.id) && (
                            <span
                              className={s.communityBadge}
                              style={{ backgroundImage: this.getBadge(community.micrositeLogoUrl) }}
                            />
                          )}
                          {(currentCommunityId !== community.id) && (
                            <Link
                              to={community.url}
                            >
                              <span
                                className={s.communityBadge}
                                style={{ backgroundImage: this.getBadge(community.micrositeLogoUrl) }}
                              />
                            </Link>
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={s.stats}>
                    <div className={s.minutes}>
                      {formatNumber(team.currentTotalMinutes)} {i18n.t('community.duel.minutes')}
                      {team.duelFactor && (team.duelFactor !== 1) && (
                        <span className={s.factorText}>
                            &nbsp;({i18n.t('community.duel.factorLabel')} x{team.duelFactor})
                        </span>
                      )}
                    </div>
                    <div className={s.trendWrap}>
                      {this.getTotalMinutesBar(team, teams[0].currentTotalMinutes)}
                      <TrendIcon now={team.currentRank} previous={team.previousRank} circle={false} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {hasSeasonStarted && (
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h4 className={s.trendTitle}>{i18n.t('community.duel.trend')}</h4>
            <div className={s.graphWrap} style={{ height: 350 }}>
              {stats && (
                <LineGraph series={stats} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(DuelTeams);
