/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ChallengeTop3.scss';
import { formatNumber } from '../../utils/utility';

class ChallengeTop3 extends React.Component {
  static propTypes = {
    rank1: PropTypes.shape().isRequired,
    rank2: PropTypes.shape().isRequired,
    rank3: PropTypes.shape().isRequired,
    short: PropTypes.string.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  getTeamInfo(team, i18n) {
    const minutes = formatNumber(team.currentTotalMinutes);

    return (
      <div className={s.teamInfoWrap}>
        <div className={s.teamNameWrap}>
          <div className={s.teamName}>
            {team.name || team.participantName}
          </div>
        </div>
        <div className={s.teamMinutes}>
          {minutes}
          {' '}
          {i18n.t('ranking.minutes')}
        </div>
      </div>
    );
  }

  render() {
    const { rank1, rank2, rank3 } = this.props;
    const { i18n } = this.context;

    return (
      <div className={s.wrap}>
        {rank2 && (
          <div className={`${s.outer} ${s.rank2}`}>
            <img src="/silver.png" className={s.position} />
            {this.getTeamInfo(rank2, i18n)}
          </div>
        )}
        {rank1 && (
          <div className={`${s.inner} ${s.rank1}`}>
            <img src="/gold.png" className={s.position} />
            {this.getTeamInfo(rank1, i18n)}
          </div>
        )}
        {rank3 && (
          <div className={`${s.outer} ${s.rank3}`}>
            <img src="/bronze.png" className={s.position} />
            {this.getTeamInfo(rank3, i18n)}
          </div>
        )}
      </div>

    );
  }
}

export default withStyles(s)(ChallengeTop3);
