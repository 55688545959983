import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Home.scss';
import CircleButton from '../../components/CircleButton';
import ComponentType from '../../components/ComponentType';
import Countdown from '../../components/Countdown';
import Flockler from '../../components/Flockler';
import getTeaserOrientation from '../../utils/teaserOrientation';
import { withInjectedLinks } from '../helper';

class Home extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      navigation_name: PropTypes.string,
      bodyTeasers: PropTypes.array,
      slices: PropTypes.array,
      footerTeasers: PropTypes.array,
      season: PropTypes.shape({
        startDateTime: PropTypes.string,
        endDateTime: PropTypes.string,
        sandboxEndDateTime: PropTypes.string,
        micrositesActive: PropTypes.bool,
        timeInMinutes: PropTypes.number,
      }),
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  render() {
    const { content } = this.props;
    const { i18n } = this.context;
    let circleButton = null;
    let footerTeaserOrientationStart = 'right';
    let footerTeaser = '';

    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton className={s.circleButton} label={contest.text} link={contest.link} />;
    }

    return (
      <div>
        <Countdown
          startDate={content.season.startDateTime}
          endDate={content.season.endDateTime}
          minutes={content.season.timeInMinutes}
          pageHeaderUrl={content.pageHeader.url}
        />
        {circleButton}

        <div className="homeBodyTeasers">
          {content.bodyTeasers.map(teaser => {
            teaser.orientation = footerTeaserOrientationStart;
            footerTeaser = <ComponentType key={teaser.id} additionalClasses="" data={teaser} />;
            footerTeaserOrientationStart = getTeaserOrientation(footerTeaserOrientationStart);
            return footerTeaser;
          })}
        </div>
        {content.slices.map(slice => (
          <ComponentType key={slice.id} data={slice} />
        ))}
        {content.footerTeasers.map(teaser => (
          <ComponentType key={teaser.id} data={teaser} />
        ))}

        {/* Deactivated until needed */}
        {/* <div className="contentContainer"> */}
        {/*  <h3 className={s.title}>{i18n.t('social.socialWallTitle')}</h3> */}
        {/*  <Flockler siteId={i18n.t('social.flockler.siteId')} /> */}
        {/* </div> */}
      </div>
    );
  }
}

export default withStyles(s)(withInjectedLinks(Home));
