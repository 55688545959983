/**
 * Helper functions for performance optimized scroll event handling.
 *
 * This plugin relies on JavaScript features that are not available in every browser
 * and need to be provided through polyfills to guarantee compatibility:
 * - Set
 * - requestAnimationFrame
 *
 * @author Christoph Dörfel <christoph.doerfel@yooapps.com>
 */

/**
 * A scroll position.
 * @typedef {{x: number, y: number}} ScrollPosition
 */

/**
 * A viewport dimensions object.
 * @typedef {{width: number, height: number}} ViewportDimensions
 */

/**
 * Signature for element data cache.
 *
 * @typedef {Object} ElementScrollDataCache
 * @property {Set.<Function>} scrollHandlers
 * @property {Set.<Function>} resizeHandlers
 * @property {boolean} scrollEventRegistered
 * @property {boolean} resizeEventRegistered
 * @property {boolean} ticking
 * @property {boolean} initialize
 * @property {boolean} needsResize
 * @property {boolean} deferredResize
 * @property {ScrollPosition} lastScrollPosition
 * @property {ScrollPosition} currentScrollPosition
 * @property {ViewportDimensions} viewportDimensions
 */

/**
 * Signature for scroll event handlers.
 *
 * @callback scrollEventHandler
 * @param {ScrollPosition} currentScrollPosition
 * @param {ScrollPosition} lastScrollPosition
 * @param {ViewportDimensions} viewportDimensions
 * @param {boolean} [resized=false] Whether the scroll event has been triggered by a resize event.
 */

/**
 * Signature for resize event handlers.
 *
 * @callback resizeEventHandler
 * @param {ScrollPosition} currentScrollPosition
 * @param {ScrollPosition} lastScrollPosition
 * @param {ViewportDimensions} viewportDimensions
 * @param {boolean} [deferred=false] Whether the resize event has been triggered after a delay.
 */

let resizeReLayoutTimer = 0;
const resizeReLayoutDelay = 150;

// Minimum amount of height change of viewport in pixels. Relevant for mobile.
const viewportHeightMinChangeInPixel = 72;
const viewportHeightMaxChangeInPixel = 120; // Maximum amount of height change of viewport in pixels.
const viewportHeightChangeFactor = 0.1; // Percentage of viewport height to use in calculation.

/** @type {ScrollPosition} */
const defaultScrollPosition = Object.freeze({ x: 0, y: 0 });

/**
 * The internal data cache.
 *
 * @type {WeakMap.<Window|Element, ElementScrollDataCache>}
 */
const elementData = new WeakMap();

/**
 * Returns the horizontal scroll position of the root element.
 *
 * @returns {Number}
 */
function getWindowScrollLeft() {
  if (window.pageXOffset !== undefined) {
    return window.pageXOffset;
  }
  return (document.documentElement || document.body.parentNode || document.body).scrollLeft;
}

/**
 * Returns the vertical scroll position of the root element.
 *
 * @returns {Number}
 */
function getWindowScrollTop() {
  if (window.pageYOffset !== undefined) {
    return window.pageYOffset;
  }
  return (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

/**
 * Checks whether a given element is higher or equal in the DOM hierarchy to the document body.
 *
 * @param {Window|Document|Element} element
 * @returns {boolean}
 */
function isRootElement(element) {
  return (element === window
    || element === document
    || element === document.documentElement
    || element === document.body.parentNode
    || element === document.body);
}

/**
 * Returns the element that is used as a key for the element data cache.
 * This is necessary because the window object, document, document.documentElement and
 * document.body are treated the same internally.
 *
 * @param {Window|Document|Element} element
 * @returns {Window}
 */
function getKeyElement(element) {
  return (isRootElement(element) ? window : element);
}

/**
 * Dispatches an event from a given element.
 *
 * @param {Window|Element} element
 * @param {CustomEvent} event
 */
function dispatchCustomEvent(element, event) {
  if (isRootElement(element)) {
    if (window.dispatchEvent) {
      window.dispatchEvent(event);
    } else if (document.documentElement && document.documentElement.dispatchEvent) {
      document.documentElement.dispatchEvent(event);
    } else if (document.body && document.body.dispatchEvent) {
      document.body.dispatchEvent(event);
    }
  } else {
    element.dispatchEvent(event);
  }
}

/**
 * Returns the scroll position of a given element.
 *
 * @param {Window|Document|Element} element
 * @returns {ScrollPosition}
 */
function calculateScrollPosition(element) {
  const isRoot = isRootElement(element);
  const x = (isRoot ? getWindowScrollLeft() : element.scrollLeft);
  const y = (isRoot ? getWindowScrollTop() : element.scrollTop);
  return Object.freeze({ x, y });
}

/**
 * Returns the visible dimensions (bounding box) of a given element.
 *
 * @param {Window|Document|Element} element
 * @returns {ViewportDimensions}
 */
function calculateViewportDimensions(element) {
  const isRoot = isRootElement(element);
  const width = (isRoot ? window.innerWidth : element.clientWidth);
  const height = (isRoot ? window.innerHeight : element.clientWidth);
  return Object.freeze({ width, height });
}

/**
 * Initializes all the required internal data caches on a given element.
 *
 * @param {Window|Document|Element} element
 */
function initializeElementData(element) {
  elementData.set(element, {
    scrollHandlers: new Set(),
    resizeHandlers: new Set(),
    scrollEventRegistered: false,
    resizeEventRegistered: false,
    ticking: false,
    initialize: false,
    needsResize: false,
    deferredResize: false,
    /** @type {ScrollPosition} */
    lastScrollPosition: defaultScrollPosition,
    /** @type {ScrollPosition} */
    currentScrollPosition: defaultScrollPosition,
    /** @type {ViewportDimensions} */
    viewportDimensions: calculateViewportDimensions(element),
  });
}


/**
 * Iterates through all scroll handlers and executes them.
 *
 * @param {Set} handlers
 * @param {ScrollPosition} currentScrollPosition
 * @param {ScrollPosition} lastScrollPosition
 * @param {ViewportDimensions} viewportDimensions
 * @param {boolean} [resized=false] Whether the scroll event has been triggered by a resize event.
 */
function triggerScrollHandlers(handlers, currentScrollPosition, lastScrollPosition, viewportDimensions, resized) {
  if (handlers.size > 0) {
    handlers.forEach(callbackFn => {
      callbackFn(currentScrollPosition, lastScrollPosition, viewportDimensions, resized);
    });
  }
}

/**
 * Iterates through all resize handlers and executes them.
 *
 * @param {Set} handlers
 * @param {ScrollPosition} currentScrollPosition
 * @param {ScrollPosition} lastScrollPosition
 * @param {ViewportDimensions} viewportDimensions
 * @param {boolean} [deferred=false] Whether the resize event has been triggered after a delay.
 */
function triggerResizeHandlers(handlers, currentScrollPosition, lastScrollPosition, viewportDimensions, deferred) {
  if (handlers.size > 0) {
    handlers.forEach(callback => {
      callback(currentScrollPosition, lastScrollPosition, viewportDimensions, deferred);
    });
  }
}

/**
 * Handles the resizing of an element.
 *
 * @param {Window|Element} element
 * @param {ElementScrollDataCache} data
 * @return {boolean} - Whether the resize resulted in a change in viewport dimensions.
 */
function handleResize(element, data) {
  // Horizontal update or bigger vertical update (10%)
  const lastViewportDimensions = data.viewportDimensions;
  const currentViewportDimensions = calculateViewportDimensions(element);

  const absHeight = Math.abs(lastViewportDimensions.height - currentViewportDimensions.height);
  const minChangeInPixels = (
    isRootElement(element) ? Math.max(viewportHeightMinChangeInPixel,
      Math.min(viewportHeightChangeFactor * lastViewportDimensions.height,
        viewportHeightMaxChangeInPixel)) : 1
  );

  const realDirty = (data.initialize || absHeight > 0);
  const dirty = (
    data.initialize ? true : (
      currentViewportDimensions.width !== lastViewportDimensions.width || absHeight >= minChangeInPixels
    )
  );

  if (dirty || data.deferredResize) {
    data.viewportDimensions = currentViewportDimensions;
    // For immediate layouting...
    triggerResizeHandlers(
      data.resizeHandlers,
      data.currentScrollPosition,
      data.lastScrollPosition,
      data.viewportDimensions,
      (!dirty && data.deferredResize),
    );
  }

  if (!data.deferredResize || realDirty) {
    window.clearTimeout(resizeReLayoutTimer);
    // ...and after a short delay (~100ms) to fix issues (mainly on iOS)
    // where the page has not always finished updating the layout with the correct values.
    resizeReLayoutTimer = window.setTimeout(
      () => {
        const customDeferredResizeEvent = new CustomEvent(
          'resize',
          {
            detail: {
              deferredResize: true,
            },
            bubbles: true,
            cancelable: true,
          },
        );
        dispatchCustomEvent(window, customDeferredResizeEvent);
      },
      resizeReLayoutDelay,
    );
  }

  return dirty;
}

/**
 * The update function that handles all scroll and resize events.
 *
 * @param {Window|Element} element
 * @param {ElementScrollDataCache} data
 */
function update(element, data) {
  const currentScrollPosition = (data.initialize ? defaultScrollPosition : data.currentScrollPosition);
  const scrollPosition = calculateScrollPosition(element);
  const dirty = (
    data.initialize ? true : (
      scrollPosition.y !== currentScrollPosition.y || scrollPosition.x !== currentScrollPosition.x
    )
  );

  if (dirty) {
    data.lastScrollPosition = currentScrollPosition;
    data.currentScrollPosition = scrollPosition;
  }

  let dirtyResize = false;
  if (data.needsResize) {
    dirtyResize = handleResize(element, data);
  }

  if (dirty || dirtyResize) {
    triggerScrollHandlers(
      data.scrollHandlers,
      data.currentScrollPosition,
      data.lastScrollPosition,
      data.viewportDimensions,
      dirtyResize,
    );
  }

  data.initialize = false;
  data.needsResize = false;
  data.deferredResize = false;
  data.ticking = false;
}

/**
 * Helper function to request an update of a given element.
 *
 * @param {Window|Element} element
 * @param {ElementScrollDataCache} data
 */
function requestTick(element, data) {
  if (!data.ticking) {
    data.ticking = true;
    window.requestAnimationFrame(() => {
      update(element, data);
    });
  }
}

/**
 * Initializes the internal scroll and resize handling for the given element.
 *
 * @param {Window|Element} element
 */
function initializeScrollEvent(element) {
  let data = elementData.get(getKeyElement(element));

  const onScroll = function onScroll(event) {
    const keyElement = getKeyElement(event.target);
    data = elementData.get(keyElement);

    if (data) {
      requestTick(keyElement, data);
    }
  };

  const onResize = function onResize(event) {
    const keyElement = getKeyElement(event.target);
    data = elementData.get(keyElement);

    if (data) {
      data.needsResize = true;
      if (event.detail && event.detail.deferredResize) {
        data.deferredResize = true;
      }
      if (event.detail && event.detail.initialize) {
        data.initialize = true;
      }
      requestTick(keyElement, data);
    }
  };

  if (!data.scrollEventRegistered) {
    data.scrollEventRegistered = true;
    element.addEventListener('scroll', onScroll.bind(this), false);
  }

  if (!data.resizeEventRegistered) {
    data.resizeEventRegistered = true;

    if (isRootElement(element)) {
      element.addEventListener('resize', onResize.bind(this), false);
    } else {
      // @todo Implement ResizeSensor from https://github.com/marcj/css-element-queries/blob/master/src/ResizeSensor.js
    }
  }
}

/**
 * Registers a scroll event handler.
 *
 * @param {Window|Document|Element} element - The element to register the handler to.
 * @param {scrollEventHandler} [callbackFn] - The event handling function.
 */
function registerScrollHandler(element, callbackFn) {
  const keyElement = getKeyElement(element);
  if (!elementData.has(keyElement)) {
    initializeElementData(keyElement);
    initializeScrollEvent(keyElement);
  }
  const data = elementData.get(keyElement);
  data.scrollHandlers.add(callbackFn);
}

/**
 * Removes a scroll event handler.
 *
 * @param {Window|Document|Element} element - The element to remove the handler from.
 * @param {scrollEventHandler} callbackFn - The event handling function.
 */
function unregisterScrollHandler(element, callbackFn) {
  const keyElement = getKeyElement(element);
  if (elementData.has(keyElement)) {
    const data = elementData.get(keyElement);
    data.scrollHandlers.delete(callbackFn);
  }
}

/**
 * Adds a resize event handler.
 *
 * @param {Window|Document|Element} element - The element to register the handler to.
 * @param {resizeEventHandler} callbackFn - The event handling function.
 */
function registerResizeHandler(element, callbackFn) {
  const keyElement = getKeyElement(element);
  if (!elementData.has(keyElement)) {
    initializeElementData(keyElement);
    // initializeScrollEvent(keyElement);
  }
  const data = elementData.get(keyElement);
  data.resizeHandlers.add(callbackFn);
}

/**
 * Removes a resize event handler.
 *
 * @param {Window|Document|Element} element - The element to remove the handler from.
 * @param {resizeEventHandler} callbackFn - The event handling function.
 */
function unregisterResizeHandler(element, callbackFn) {
  const keyElement = getKeyElement(element);
  if (elementData.has(keyElement)) {
    const data = elementData.get(keyElement);
    data.resizeHandlers.delete(callbackFn);
  }
}

/**
 * A helper function to trigger a resize event after the document has loaded.
 */
function onDocumentLoaded() {
  const customResizeEvent = new CustomEvent(
    'resize',
    {
      detail: {
        initialize: true,
      },
      bubbles: true,
      cancelable: true,
    },
  );
  dispatchCustomEvent(window, customResizeEvent);
}

/**
 * Initializes this module.
 * Registers default events on the window object.
 */
function initialize() {
  initializeElementData(window);
  initializeScrollEvent(window);
  // Utility.loaded(onDocumentLoaded);
}

/**
 * Adds a scroll event handler.
 *
 * @param {Window|Document|Element|scrollEventHandler} element -
 * The element to attach the handler to.
 * @param {scrollEventHandler} [callbackFn] - The event handling function.
 */
export function addScrollHandler(element, callbackFn) {
  registerScrollHandler(
    (arguments.length === 1 ? window : element), (arguments.length === 1 ? element : callbackFn),
  );
}

/**
 * Removes a scroll event handler.
 *
 * @param {Window|Document|Element|scrollEventHandler} element -
 * The element to remove the handler from.
 * @param {scrollEventHandler} [callbackFn] - The event handling function.
 */
export function removeScrollHandler(element, callbackFn) {
  unregisterScrollHandler(
    (arguments.length === 1 ? window : element), (arguments.length === 1 ? element : callbackFn),
  );
}

/**
 * Adds a resize event handler.
 *
 * @param {Window|Document|Element|scrollEventHandler} element -
 * The element to attach the handler to.
 * @param {resizeEventHandler} [callbackFn] - The event handling function.
 */
export function addResizeHandler(element, callbackFn) {
  registerResizeHandler(
    (arguments.length === 1 ? window : element), (arguments.length === 1 ? element : callbackFn),
  );
}

/**
 * Removes a resize event handler.
 *
 * @param {Window|Document|Element|scrollEventHandler} element -
 * The element to remove the handler from.
 * @param {resizeEventHandler} [callbackFn] - The event handling function.
 */
export function removeResizeHandler(element, callbackFn) {
  unregisterResizeHandler((
    arguments.length === 1 ? window : element), (arguments.length === 1 ? element : callbackFn),
  );
}

/**
 * Returns the current scroll position.
 *
 * @param {Window|Document|Element} [element] -
 * The element to retrieve the current ScrollPosition from.
 * @returns {ScrollPosition}
 */
export function getCurrentScrollPosition(element) {
  const keyElement = getKeyElement((element === undefined ? window : element));
  const data = elementData.get(keyElement);
  if (data && data.currentScrollPosition) {
    return data.currentScrollPosition;
  }
  return calculateScrollPosition(element);
}

/**
 * Returns the last scroll position.
 *
 * @param {Window|Document|Element} [element] -
 * The element to retrieve the current ScrollPosition from.
 * @returns {ScrollPosition}
 */
export function getLastScrollPosition(element) {
  const keyElement = getKeyElement((element === undefined ? window : element));
  const data = elementData.get(keyElement);
  if (data && data.lastScrollPosition) {
    return data.lastScrollPosition;
  }
  return defaultScrollPosition;
}

/**
 * Returns the current viewport dimensions.
 *
 * @param {Window|Document|Element} [element] -
 * The element to retrieve the current ViewportDimensions from.
 * @returns {ViewportDimensions}
 */
export function getViewportDimensions(element) {
  const keyElement = getKeyElement((element === undefined ? window : element));
  const data = elementData.get(keyElement);
  if (data && data.viewportDimensions) {
    return data.viewportDimensions;
  }
  return calculateViewportDimensions(element);
}
