import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './BurgerMenu.scss';
import classNames from '../../utils/classNames';

class BurgerMenu extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool,
  };

  static defaultProps = {
    onClick: null,
    active: false,
  };

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { active } = this.props;

    const burgerMenuClasses = classNames(s, {
      'burger-menu': true,
      'burger-menu--active': active,
    });

    const bar1Classes = classNames(s, {
      'burger-menu__bar': true,
      'burger-menu__bar--1': true,
    });

    const bar2Classes = classNames(s, {
      'burger-menu__bar': true,
      'burger-menu__bar--2': true,
    });

    const bar3Classes = classNames(s, {
      'burger-menu__bar': true,
      'burger-menu__bar--3': true,
    });

    return (
      <button className={burgerMenuClasses} onClick={() => this.handleClick()}>
        <span className={bar1Classes} />
        <span className={bar2Classes} />
        <span className={bar3Classes} />
      </button>
    );
  }
}

export default withStyles(s)(BurgerMenu);
