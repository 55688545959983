import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import FlipClock from '../FlipClock';
import s from './Countdown.scss';

class Countdown extends React.Component {
  static propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    minutes: PropTypes.number,
    pageHeaderUrl: PropTypes.string,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    minutes: 0,
    pageHeaderUrl: './background.png',
  };

  state = {
    renderedClient: false,
  };

  componentDidMount() {
    this.setState({
      renderedClient: true,
    });
  }

  /**
   * Show countdown if the event hasn't started yet.
   *
   * @returns {boolean}
   */
  shouldShowCountdown() {
    const { startDate } = this.props;
    const now = +new Date();
    const to = +new Date(startDate);
    const diff = to - now;

    return diff > 0;
  }

  shouldIncrementCounter() {
    const { startDate, endDate } = this.props;
    const now = +new Date();
    const start = +new Date(startDate);
    const end = +new Date(endDate);

    if (now >= start && now <= end) {
      return true;
    }
    return false;
  }

  render() {
    const { startDate, minutes, pageHeaderUrl } = this.props;
    const { renderedClient } = this.state;
    const { i18n } = this.context;
    const showCountdown = this.shouldShowCountdown();
    const shouldIncrement = this.shouldIncrementCounter();


    return (
      <div className={s.wrapper} style={{ backgroundImage: `url(${pageHeaderUrl})` }}>
        <div className={s.card}>
          <div className={s.only}>
            {renderedClient && (
              <div>
                {showCountdown ? i18n.t('countdown.only') : i18n.t('countdown.collectedMinutes')}
              </div>
            )}
          </div>

          {renderedClient && (
          <div className={s.clockContainer}>
            {showCountdown ? (
              <FlipClock
                id="countdown-flip"
                type="countdown"
                toDate={startDate}
              />
            ) : (
              <FlipClock
                id="global-counter-flip"
                layout="horizontal"
                type="count"
                value={minutes}
                interval={5}
                padZeros={9}
                increment={shouldIncrement}
              />
            )}
          </div>
          )}
          {renderedClient && (
            <div>
              {showCountdown && <div className={s.until}>{i18n.t('countdown.until')}</div>}
              {showCountdown && <div className={s.title}>{i18n.t('countdown.title')}</div>}
              {!showCountdown && <div className={s.national}>{i18n.t('countdown.national')}</div>}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Countdown);
