import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SponsorList.scss';
import SponsorItem from './SponsorItem/SponsorItem';

class SponsorList extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      sponsors: PropTypes.array,
    }),
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data: { name, sponsors } } = this.props;

    return (
      <div className="componentContainer">
        <div className="contentContainer">
          <div className={s.sponsorList}>
            <h3>{name}</h3>
            <div className="row justify-content-center">
              {sponsors.map((item, index) => (
                <div key={index} className="col-sm-4 col-xs-6 text-center">
                  <SponsorItem data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SponsorList);
