import HttpError, { HTTP_ERROR_NETWORK_FAILURE } from '../api/http-error';

export default function createFetch(fetch, { baseUrl, locale, defaultLocale }) {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    mode: baseUrl ? 'cors' : 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  // Appends the language parameter to the API calls
  const langParam = () => {
    if (locale !== defaultLocale) {
      return `?lang=${locale}`;
    }

    return '';
  };

  return function doFetch(url, options = {}) {
    let modifiedUrl = url;
    let modifiedOptions = options;

    if (url.startsWith('/api')) {
      modifiedUrl = `${baseUrl}${url}${langParam()}`;
      modifiedOptions = {
        ...defaults,
        ...options,
        headers: {
          ...defaults.headers,
          ...(options && options.headers),
        },
      };
    }

    return fetch(modifiedUrl, modifiedOptions)
      .then(response => {
        if (response.ok === true) {
          return response;
        }

        throw new HttpError(response.status, response.statusText);
      })
      .catch(error => {
        if (error.statusCode != null) {
          throw error;
        }

        // Other error occurred
        throw new HttpError(HTTP_ERROR_NETWORK_FAILURE, `${error}`);
      });
  };
}
