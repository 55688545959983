import i18next from 'i18next';

i18next
  .init({
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18next;
