import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './FlipClock.scss';
import polyfill from './tick.core.polyfill';
import Tick from './tick.core.module';
import Flip from './tick.view.flip.module';

const TYPE_COUNTDOWN = 'countdown';

class FlipClock extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    toDate: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.number,
    interval: PropTypes.number,
    increaseMax: PropTypes.number,
    initialOffset: PropTypes.number,
    padZeros: PropTypes.number,
    increment: PropTypes.bool,
    layout: PropTypes.string,
    onTick: PropTypes.func,
  };

  static defaultProps = {
    toDate: null,
    value: 0,
    interval: 4,
    increaseMax: 8,
    initialOffset: 15,
    padZeros: 6,
    increment: false,
    layout: 'horizontal fit',
    onTick: () => {},
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  componentDidMount() {
    // create an empty ticker with no options
    const element = this.getTickDOMElement();

    // Wrap in try catch, so no errors are thrown when
    // hot-reloading
    try {
      Tick.plugin.add(Flip);
    } catch (err) {
      //
    }

    this.tick = Tick.DOM.create(element, {
      didInit: tick => this.onInit(tick),
    });
  }

  componentWillUnmount() {
    Tick.DOM.destroy(this.getTickDOMElement());

    if (this.timer != null) {
      clearInterval(this.timer);
    }
  }

  onInit(tick) {
    const { type } = this.props;

    if (type === TYPE_COUNTDOWN) {
      this.initCountdown(tick);
    } else {
      this.initCounter(tick);
    }
  }

  getTickDOMElement() {
    const { id } = this.props;
    return document.querySelector(`#${id}`);
  }

  calculateUnits() {
    const { toDate } = this.props;
    const to = +new Date(toDate);
    const now = +new Date();

    const diff = to - now;

    if (diff <= 0) {
      return [0, 0, 0, 0];
    }

    return Tick.helper.duration(diff, ['d', 'h', 'm', 's']);
  }

  initCountdown(tick) {
    this.timer = setInterval(() => {
      const [days, hours, minutes, seconds] = this.calculateUnits();
      tick.value = {
        sep: '',
        days,
        hours,
        minutes,
        seconds,
      };
    }, 1000);
  }

  initCounter(tick) {
    const {
      value,
      increaseMax,
      increment,
      interval,
      initialOffset,
      onTick,
    } = this.props;

    let initialValue = value;

    if (increment) {
      initialValue = value - initialOffset;
    }

    tick.value = initialValue < 0 ? 0 : initialValue;

    if (increment) {
      this.timer = setInterval(() => {
        tick.value += Math.floor(Math.random() * increaseMax);
        onTick(tick.value);
      }, interval * 1000);
    }
  }

  renderCountdown() {
    const { i18n } = this.context;
    const { layout } = this.props;

    return (
      <div data-layout={`${layout}`}>
        <div className="tick-group">
          <span data-view="flip" data-transform="pad(00)" data-key="days" />
          <span className="tick-label">{i18n.t('countdown.days')}</span>
        </div>
        <span className="tick-text-inline tick-separator" data-view="text" data-key="sep" />
        <div className="tick-group">
          <span data-view="flip" data-transform="pad(00)" data-key="hours" />
          <span className="tick-label">{i18n.t('countdown.hours')}</span>
        </div>
        <span className="tick-text-inline tick-separator" data-view="text" data-key="sep" />
        <div className="tick-group">
          <span data-view="flip" data-transform="pad(00)" data-key="minutes" />
          <span className="tick-label">{i18n.t('countdown.minutes')}</span>
        </div>
        <span className="tick-text-inline tick-separator" data-view="text" data-key="sep" />
        <div className="tick-group">
          <span data-view="flip" data-transform="pad(00)" data-key="seconds" />
          <span className="tick-label">{i18n.t('countdown.seconds')}</span>
        </div>
      </div>
    );
  }

  renderCounter() {
    const { padZeros, layout, value } = this.props;
    const pad = Array(padZeros + 1).join('0');
    let digits = padZeros;

    if (value > 0) {
      const valueDigits = `${value}`.length;
      digits = valueDigits > digits ? valueDigits : digits;
    }

    return (
      <div className="counter-flip" data-layout={`${layout}`}>
        <span className={`counter-flip-inner counter-flip-pad-${digits}`} data-transform={`arrive(5, .01) -> round -> pad(${pad}) -> split -> delay(rtl, 100, 150)`} data-repeat="true">
          <span data-view="flip" />
        </span>
      </div>
    );
  }

  render() {
    const { id, type } = this.props;
    let elements;

    if (type === TYPE_COUNTDOWN) {
      elements = this.renderCountdown();
    } else {
      elements = this.renderCounter();
    }

    return (
      <div>
        <div id={id} className="tick">
          {elements}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(FlipClock);
