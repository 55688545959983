import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import ChallengeTop3 from '../ChallengeTop3';
import RankingListTable from '../RankingListTable/RankingListTable';
import s from './ChallengeRankingList.scss';

const TeamRankPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  currentRank: PropTypes.number.isRequired,
  previousRank: PropTypes.number.isRequired,
  minutes: PropTypes.number,
});

class ChallengeRankingList extends React.Component {
  static propTypes = {
    group: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      short: PropTypes.string,
    }).isRequired,
    top3: PropTypes.arrayOf(TeamRankPropType).isRequired,
    rest: PropTypes.arrayOf(TeamRankPropType).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  state = {
    completeList: this.props.rest,
    list: this.props.rest.slice(0, 7),
  };

  showMore = () => {
    const { list, completeList } = this.state;
    const len = list.length;

    this.setState({
      list: completeList.slice(0, len + 10),
    });
  }

  render() {
    const { top3 } = this.props;
    const { list, completeList } = this.state;

    return (
      <>
        <div className={s.top3}>
          <ChallengeTop3 rank1={top3[0]} rank2={top3[1]} rank3={top3[2]} />
        </div>
        {list.length > 1 && (
          <div className={s.wrap}>
            <RankingListTable
              list={list}
              displayShowMore={list.length < completeList.length}
              onShowMore={this.showMore}
              type="teams"
            />
          </div>
        )}
      </>
    );
  }
}

export default withStyles(s)(ChallengeRankingList);
