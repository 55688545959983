import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Microsite.scss';
import PageHeaderMicrosite from '../../components/PageHeaderMicrosite';
import PdfDownloadLink from '../../components/PdfDownloadLink';
import FlipClock from '../../components/FlipClock';
import NewsList from '../../components/NewsList';
import EventList from '../../components/EventList';
import EventItem from '../../components/EventList/EventItem/EventItem';
import SponsorTeaser from '../../components/SponsorTeaser';
import EngageTeaser from '../../components/EngageTeaser';
import RegionalSponsors from '../../components/RegionalSponsors';
import * as Utility from '../../utils/utility';
import CircleButton from '../../components/CircleButton';
import DuelTeams from '../../components/DuelTeams';
import ImageGallery from '../../components/ImageGallery';

const activeClass = 'active';

class Microsite extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  state = {
    activeTab: null,
  };

  componentDidMount() {
    this.changeTabUsingHash();
    this.onHashChange = () => this.changeTabUsingHash();

    window.addEventListener('hashchange', this.onHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
    this.onHashChange = null;
  }

  getFilenameFromUrl(url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  handleClick(e) {
    if (!Utility.hasClass(e.target, 'active')) {
      const activeTabValue = e.target.dataset.tab;

      if (activeTabValue) {
        window.location.hash = activeTabValue;
      }
    }
  }

  changeTabUsingHash() {
    const { hash } = window.location;
    if (hash) {
      const parts = hash.split('#');

      if (parts[1]) {
        // Removes the # and extracts the key
        this.changeTab(parts[1]);
      }
    }
  }

  changeTab(activeTabValue) {
    const { tabList } = this;
    const activeElements = tabList.getElementsByClassName(activeClass);
    const activeElementsArray = Array.from(activeElements);
    const newActiveElements = tabList.querySelectorAll(`[data-tab='${activeTabValue}']`);


    if (newActiveElements && newActiveElements.length) {
      activeElementsArray.forEach(element => {
        Utility.removeClass(element, activeClass);
      });

      [].forEach.call(newActiveElements, element => {
        Utility.addClass(element, activeClass);
      });
    }

    this.setState({
      activeTab: activeTabValue,
    });
  }

  hasSeasonStarted() {
    const { content: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);
    return now >= start;
  }

  isSeasonRunning() {
    const { content: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);
    const end = +new Date(season.endDateTime);
    return now >= start && now <= end;
  }

  render() {
    const { i18n } = this.context;
    const { content } = this.props;
    const { activeTab } = this.state;
    const downloadAsPdf = i18n.t('community.downloadAsPdf');
    const seasonIsRunning = this.isSeasonRunning();
    const seasonStarted = this.hasSeasonStarted();
    const rank = seasonStarted ? content.rank : null;

    let circleButton = null;
    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton className={s.circleButton} label={contest.text} link={contest.link} />;
    }

    return (
      <div>
        <PageHeaderMicrosite
          name={content.name}
          short={content.group.short}
          rank={rank}
          micrositeLogoUrl={content.micrositeLogoUrl}
          micrositeBackgroundImageUrl={content.micrositeBackgroundImageUrl}
        />
        {circleButton}
        {seasonStarted && (
          <div className="componentContainer">
            <div className={s.minutesContainer}>
              <span className={s.minutesContainerLabel}>{i18n.t('map.collectedMinutes')}</span>
              <FlipClock
                key={`community-minutes-counter-${content.id}`}
                id="community-minutes-counter"
                type="count"
                value={content.timeInMinutes}
                interval={5}
                padZeros={6}
                layout="fit"
                initialOffset={0}
                increaseMax={4}
              />
            </div>
          </div>
        )}
        <div className="componentContainer componentContainerWhite tabContainer">
          <div className={`tabWrap ${s.tabList}`} ref={el => { this.tabList = el; }}>
            {content.nextEvent.id && (
              <div>
                <h3 className={s.nextEventTitle}>{i18n.t('community.nextEventTitle')}</h3>
                <div className={`${s.nextEventContainer} next-event-wrap`}>
                  <EventItem event={content.nextEvent} />
                </div>
              </div>
            )}
            <div className={s.tabNav}>
              <div className={s.tabNavInner}>
                <button onClick={(e => this.handleClick(e))} key="news" data-tab="news" className={`tabButton ${s.tabButton} ${activeClass}`}>
                  {i18n.t('community.newsTab')}
                </button>
                {content.images.length > 0 && (
                <button onClick={(e => this.handleClick(e))} key="gallery" data-tab="gallery" className={`tabButton ${s.tabButton}`}>
                  {i18n.t('community.galleryTab')}
                </button>
                )}
                <button onClick={(e => this.handleClick(e))} key="events" data-tab="events" className={`tabButton ${s.tabButton}`}>
                  {i18n.t('community.eventTab')}
                </button>
                {content.duelId && (
                  <button onClick={(e => this.handleClick(e))} key="duel" data-tab="duel" className={`tabButton ${s.tabButton}`}>
                    {i18n.t('community.duelTab')}
                  </button>
                )}
                <button onClick={(e => this.handleClick(e))} key="information" data-tab="information" className={`tabButton ${s.tabButton}`}>
                  {i18n.t('community.infoTab')}
                </button>
              </div>
            </div>
            <div className={`tabContent ${s.tabContent} ${activeClass}`} data-tab="news">
              <div className={s.tabContentContainer}>
                {content.news.length > 0 && <NewsList news={content.news} />}
                {content.news.length === 0 && <div className="text-center">{i18n.t('community.noNews')}</div>}
              </div>
            </div>
            <div className={`tabContent ${s.tabContent}`} data-tab="gallery">
              <div className={s.tabContentContainer}>
                {(content.images.length > 0) && (
                  <ImageGallery items={content.images} />
                )}
              </div>
            </div>
            <div className={`tabContent ${s.tabContent} ${s.tabContentEvent}`} data-tab="events">
              <div className={s.tabContentContainer}>
                <div>
                  {content.programDocumentUrl && (
                    <div className={s.programDocument}>
                      <PdfDownloadLink link={content.programDocumentUrl} label={downloadAsPdf} showArrow />
                    </div>
                  )}
                  <EventList groupedEvents={content.events} nextHighlight={content.highlightedEvent} />
                </div>
              </div>
            </div>
            {content.duelId && (
              <div className={`tabContent ${s.tabContent}`} data-tab="duel">
                <div className={s.tabContentContainer}>
                  {activeTab === 'duel' && (
                    <DuelTeams duelId={content.duelId} currentCommunityId={content.id} hasSeasonStarted={this.hasSeasonStarted()} />
                  )}
                </div>
              </div>
            )}
            <div className={`tabContent ${s.tabContent}`} data-tab="information">
              <div className={s.tabContentContainer}>
                <div className={s.infoContainer}>
                  <div className="row">
                    {content.informationContent && (
                      <div className={`col-md-12 ${s.infoCol}`}>
                        <p className={s.infoText}>{content.informationContent}</p>
                      </div>
                    )}
                    {content.documents.length > 0 && (
                      <div className={`col-md-6 ${s.infoCol}`}>
                        <div className={s.appendixTitle}>{i18n.t('community.info.downloads')}</div>
                        {content.documents.map((item, index) => (
                          <div key={index} className={s.downloadLinkWrap}>
                            <PdfDownloadLink link={item.url} label={this.getFilenameFromUrl(item.url)} />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className={`col-md-6 ${s.infoCol}`}>
                      <div className={s.appendixTitle}>
                        {i18n.t('community.info.oc')}
                        {' '}
                        {content.name}
                      </div>
                      {content.contactFirstName && (
                        <div>
                          {content.contactFirstName}
                          {' '}
                          {content.contactLastName}
                        </div>
                      )}
                      {content.contactPhone && (
                        <div>
                          {i18n.t('community.info.phone')}:
                          {' '}
                          {content.contactPhone}
                        </div>
                      )}
                      {content.contactEmail && (
                        <div>
                          {i18n.t('community.info.email')}:
                          {' '}
                          {content.contactEmail}
                        </div>
                      )}
                      {content.contactFunction && (
                        <div>
                          {i18n.t('community.info.function')}:
                          {' '}
                          {content.contactFunction}
                        </div>
                      )}
                    </div>
                    {(content.facebookLink || content.websiteUrl || content.twitterUsername) && (
                      <div className={`col-md-6 ${s.infoCol}`}>
                        <div className={s.appendixTitle}>{i18n.t('community.info.links')}</div>
                        {content.twitterUsername && (
                          <div className={s.infoLink}>
                            <a href={content.twitterUsername} target="_blank">{content.twitterUsername}</a>
                          </div>
                        )}
                        {content.websiteUrl && (
                          <div className={s.infoLink}>
                            <a href={content.websiteUrl} target="_blank">{content.websiteUrl}</a>
                          </div>
                        )}
                        {content.facebookLink && (
                          <div className={s.infoLink}>
                            <a href={content.facebookLink} target="_blank">{content.facebookLink}</a>
                          </div>
                        )}
                        {content.instagramUsername && (
                          <div className={s.infoLink}>
                            <a href={content.instagramUsername} target="_blank">{content.instagramUsername}</a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {content.sponsorTeaser && content.sponsorTeaser.id && (
          <div className="componentContainer componentContainerWhite">
            <div className="contentContainer">
              <SponsorTeaser data={content.sponsorTeaser} />
            </div>
          </div>
        )}
        {(content.sponsors.length > 0) && (
          <div className="componentContainer componentContainerWhite">
            <div className="contentContainer">
              <RegionalSponsors sponsors={content.sponsors} />
            </div>
          </div>
        )}
        {seasonIsRunning && (
          <div className="componentContainer">
            <EngageTeaser
              title={i18n.t('community.duelRuntime')}
              toDate={content.season.endDateTime}
              linkLabel={i18n.t('community.participateNow')}
              link={{ url: i18n.t('community.participateNowUrl') }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(Microsite);
