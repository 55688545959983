import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import globals from '../../styles/globals.scss';

// external-global styles must be imported in your JS.
import s from './LayoutIframe.scss';
import classNames from '../../utils/classNames';

class LayoutIframe extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  state = {
    modalOpen: false,
  };

  toggleModal(forced) {
    // if (this.modalOpen)
    this.setState({
      modalOpen: forced,
    });
  }

  render() {
    const {
      className,
      ...props
    } = this.props;

    let rootClassNames = classNames(s, {
      'modal--open': this.state.modalOpen,
    });

    if (className) {
      rootClassNames += ` ${className}`;
    }

    return (
      <div className={rootClassNames} {...props}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(globals, s)(LayoutIframe);
