// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2gcR4{position:relative;width:100%;max-width:1200px;margin:0 auto}._2gcR4 a{color:inherit}._2gcR4 a:hover{color:inherit;text-decoration:none}._1Fss3{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;position:relative;width:120px;height:120px;margin:-60px auto 0;border:4px solid #fff;border-radius:120px;background-color:#e40521;color:#fff;font-family:Montserrat,sans-serif;font-weight:700;text-align:center;text-transform:uppercase;-webkit-box-shadow:0 6px 10px 0 rgba(0,0,0,.14);box-shadow:0 6px 10px 0 rgba(0,0,0,.14);cursor:pointer;z-index:100}._1Fss3 i{font-size:1.25rem;display:block;top:10px}._1Fss3 i,.NUAsr{position:relative}.NUAsr{top:6px}._2M9Mq{display:block}._2M9Mq:first-child,._2M9Mq:nth-child(3){font-size:.6875rem}._2M9Mq:nth-child(2){font-size:.8125rem}@media(min-width:768px){._1Fss3{position:absolute;right:30px;width:192px;height:192px;margin-top:-138px;border-radius:192px}._1Fss3 i{font-size:2rem;display:block;top:16px}._1Fss3 i,.NUAsr{position:relative}.NUAsr{top:12px}._2M9Mq:first-child,._2M9Mq:nth-child(3){font-size:1rem}._2M9Mq:nth-child(2){font-size:1.34375rem}}", ""]);
// Exports
exports.locals = {
	"container": "_2gcR4",
	"button": "_1Fss3",
	"label": "NUAsr",
	"part": "_2M9Mq"
};
module.exports = exports;
