import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AppGuide.scss';

class AppGuide extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object,
  };

  render() {
    const { i18n } = this.context;

    return (
      <div className="componentContainer">
        <div className="contentContainer">
          <div className={s.container}>
            <div className={s.imageList}>
              <div className="row">
                <div className="col-md-12">
                  <h3 className={s.title}>{i18n.t('appguide.title')}</h3>
                  <p className={s.description}>{i18n.t('appguide.description')}</p>
                </div>
                <div className={`col-md-12 ${s.imageListItem}`}>
                  <div className="row">
                    <div className={`col-md-6 col-sm-12 ${s.imageCol}`}>
                      <div className={s.imageWrap}>
                        <img className={s.image} src="./step1.png" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className={s.text}>
                        {i18n.t('appguide.step1')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-12 ${s.imageListItem}`}>
                  <div className="row">
                    <div className={`col-md-6 col-sm-12 ${s.imageCol}`}>
                      <div className={s.imageWrap}>
                        <img className={s.image} src="./step2.png" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className={s.text}>
                        {i18n.t('appguide.step2')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-12 ${s.imageListItem}`}>
                  <div className="row">
                    <div className={`col-md-6 col-sm-12 ${s.imageCol}`}>
                      <div className={s.imageWrap}>
                        <img className={s.image} src="./step3.png" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className={s.text}>
                        {i18n.t('appguide.step3')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-12 ${s.imageListItem}`}>
                  <div className="row">
                    <div className={`col-md-6 col-sm-12 ${s.imageCol}`}>
                      <div className={s.imageWrap}>
                        <img className={s.image} src="./step4.png" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className={s.text}>
                        {i18n.t('appguide.step4')}
                      </div>
                    </div>
                  </div>
                </div>
                {i18n.exists('app.ios.url') && (
                  <div className="col-md-6">
                    <a href={i18n.t('app.ios.url')} target="_blank">
                      <img className={s.appIcon} src="./appstore.png" />
                    </a>
                  </div>
                )}
                {i18n.exists('app.android.url') && (
                  <div className="col-md-6">
                    <a href={i18n.t('app.android.url')} target="_blank">
                      <img className={s.appIcon} src="./playstore.png" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(AppGuide);
