import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import s from './EventItemToday.scss';
import Link from '../../Link';
import { renderDateTime } from '../../../utils/eventItemUtil';

class EventItemToday extends React.Component {
  static propTypes = {
    event: PropTypes.shape({

    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    const { i18n } = this.context;

    moment.locale(i18n.t('moment.locale'));
  }

  render() {
    const { i18n } = this.context;
    const { event } = this.props;

    let eventClassName = '';

    if (event.isHighlighted) {
      eventClassName = `${s.highlighted}`;
    }

    let selfScanningVisibility = '';
    if (event.typeId === 3) {
      selfScanningVisibility = 'visible';
    }

    return (
      <Link to={event.url} target={event.target} className={s.link}>
        <div className={`${s.event} ${eventClassName}`}>
          <div className="row">
            <div className="col-md-11 offset-md-1">
              <div className={s.innerWrapLeft}>
                <div className={s.iconWrap}>
                  <div className={`${s.iconSelfscanning} ${selfScanningVisibility}`}>
                    <i className="icon icon-smartphone-android" />
                  </div>
                </div>
                <div className={s.dateWrap}>
                  <span className={s.title}>
                    <span className={`${selfScanningVisibility} ${s.selfScanningMobile}`}><i className="icon icon-smartphone-android" /></span> {event.name}
                  </span>
                  <div className={s.dateTime} dangerouslySetInnerHTML={{ __html: renderDateTime(event, i18n) }} />
                  {event.address && <span className={s.address}>{event.address}</span>}
                  {event.location && <span className={s.location}> / {event.location}</span>}
                </div>
                <div className={s.moreLink}><span>{i18n.t('map.more')}</span></div>
                <div className={s.clear} />
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default withStyles(s)(EventItemToday);
