import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import withStyles from 'isomorphic-style-loader/withStyles';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? 'transparent transparent #d9d9d9' : 'transparent',
    backgroundColor: state.isFocused ? '#fff' : '#fcfcfc',
    boxShadow: 'none',
    cursor: 'text',
    '&:hover': {
      borderColor: 'transparent transparent #d9d9d9',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: '#aaa',
    textTransform: 'uppercase',
  }),
  clearIndicator: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? '#fff' : '#333',
    backgroundColor: state.isSelected || state.isFocused ? '#ea5b0c' : 'transparent',
    cursor: 'pointer',
  }),
};

class SelectBox extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    noResultsText: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    selected: PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  };

  static defaultProps = {
    onChange: () => {},
    placeholder: '',
    noResultsText: '',
    className: '',
    selected: null,
  };

  state = {
    selectedOption: this.props.selected,
  };

  componentWillReceiveProps(nextProps) {
    const { selected } = nextProps;
    const { selectedOption } = this.state;

    if (selected === null) {
      this.setState({
        selectedOption: null,
      });
    } else if (selected.value && selected.value !== selectedOption) {
      this.setState({
        selectedOption: selected,
      });
    }
  }

  handleChange(selectedOption) {
    const { onChange } = this.props;

    this.setState({ selectedOption });
    onChange(selectedOption);
  }

  render() {
    const { className, name, noResultsText, options, placeholder } = this.props;
    const { selectedOption } = this.state;
    const value = selectedOption && selectedOption.value;

    return (
      <Select
        styles={selectStyles}
        name={name}
        defaultValue={value}
        onChange={option => this.handleChange(option)}
        options={options}
        className={className}
        classNamePrefix="Select"
        placeholder={placeholder}
        noResultsText={noResultsText}
        isClearable
      />
    );
  }
}

export default SelectBox;
