// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1cFlN{margin:0 -10px}.VFEJr{margin-bottom:20px;padding:0 10px}._25VFd{width:100%;height:74px;background-position:50%;background-size:cover}@media(min-width:992px){._25VFd{width:100%;height:158px}}", ""]);
// Exports
exports.locals = {
	"imageGalleryRow": "_1cFlN",
	"imageWrap": "VFEJr",
	"image": "_25VFd"
};
module.exports = exports;
