import React from 'react';
import CommunitiesMap from '../../components/CommunitiesMap';

class Map extends React.Component {
  render() {
    return (
      <div>
        <CommunitiesMap />
      </div>
    );
  }
}

export default Map;
