// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ZzipP:nth-child(2n){background:#f5f7fa}.ZzipP:nth-child(odd){background:rgba(245,247,250,.4)}.ZzipP a:hover{text-decoration:none}.ZzipP.hidden{display:none}._3K5YY{position:relative;padding:25px}._3zSYU{display:block}._1i2wX{color:#ef5350;font-weight:700}._31xs8{position:relative;color:#373a3c}._2IUk_{position:absolute;top:0;right:40px;bottom:0;height:24px;margin:auto 0;color:rgba(0,0,0,.38);font-size:24px}@media(min-width:992px){._3K5YY{padding:25px 90px}._2IUk_{right:150px}}", ""]);
// Exports
exports.locals = {
	"eventWrap": "ZzipP",
	"event": "_3K5YY",
	"link": "_3zSYU",
	"name": "_1i2wX",
	"date": "_31xs8",
	"arrow": "_2IUk_"
};
module.exports = exports;
