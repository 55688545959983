// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".XEvRO h3{margin-top:0;margin-bottom:70px;font-size:1.375rem;text-align:center}.XEvRO h3:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}.WE_Hx{display:block;margin-top:2.625rem;text-align:center}.swmA3{position:relative;color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase}.swmA3:hover{color:#727a8b;text-decoration:none}.swmA3:after{position:absolute;padding-top:3px;padding-left:20px;font-family:Material-Design-Iconic-Font;content:\"\"}._1CZM_{display:block;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}._14CF0{width:100%;text-align:center}._2YGJI{width:180px}.LMbdy{display:block}.LMbdy:hover{cursor:pointer}@media(min-width:768px){._1CZM_{display:-webkit-box;display:-ms-flexbox;display:flex}._14CF0{width:auto;padding:0 20px}}@media(min-width:992px){._14CF0{padding:0 60px}}", ""]);
// Exports
exports.locals = {
	"sponsorTeaser": "XEvRO",
	"moreLinkWrap": "WE_Hx",
	"moreLink": "swmA3",
	"sponsorTeaserWrap": "_1CZM_",
	"item": "_14CF0",
	"image": "_2YGJI",
	"link": "LMbdy"
};
module.exports = exports;
