// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3B9Wo{display:none;position:relative;padding:32px 15px 42px;background-color:#fff}._3B9Wo.active{display:block}._3B9Wo>div{margin-bottom:50px}._3B9Wo>div:last-child{margin-bottom:0}", ""]);
// Exports
exports.locals = {
	"tabContent": "_3B9Wo"
};
module.exports = exports;
