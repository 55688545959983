import React from 'react';
import Empty from './Empty';
import Layout from '../../components/Layout';

async function action(context) {
  const { content, route } = context;

  return {
    ...route,
    ...content.meta,
    component: (
      <Layout>
        <Empty content={content} />
      </Layout>
    ),
  };
}

export default action;
