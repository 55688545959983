// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._27g86{display:inline-block;position:absolute;top:-24px;right:11px;width:48px;height:48px;border:0;border-radius:100%;outline:none;background:#ea5b0c;text-align:center;-webkit-box-shadow:0 10px 10px 0 rgba(0,0,0,.3);box-shadow:0 10px 10px 0 rgba(0,0,0,.3)}._27g86:hover{cursor:pointer}.oYSo0{color:#fff;font-size:1.875rem}@media(min-width:992px){._27g86{top:-40px;width:80px;height:80px}.oYSo0{font-size:3.75rem}}", ""]);
// Exports
exports.locals = {
	"backToTop": "_27g86",
	"icon": "oYSo0"
};
module.exports = exports;
