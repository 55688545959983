import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Navigation.scss';
import Link from '../Link';

class Navigation extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    classes: PropTypes.string,
  };

  static defaultProps = {
    classes: null,
  };

  static contextTypes = {
    store: PropTypes.object,
  };

  state = {
    items: this.context.store.get(this.props.id) || [],
  };

  render() {
    const { items } = this.state;
    const { classes } = this.props;

    return (
      <div className={`${classes} ${s.root}`} role="navigation">
        {items.map(item => (
          <Link
            className={s.link}
            key={item.id}
            to={item.redirect || item.route}
            target={item.redirect ? '_blank' : null}
          >
            {item.name}
          </Link>
        ))}
      </div>
    );
  }
}

export default withStyles(s)(Navigation);
