import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import s from './EventItem.scss';
import Link from '../../Link';

class EventItem extends React.Component {
  static propTypes = {
    event: PropTypes.shape({

    }).isRequired,
    title: PropTypes.string,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    title: null,
  };

  static today = moment().startOf('day');

  static endToday = moment().endOf('day');

  constructor(props, context) {
    super(props, context);

    const { i18n } = this.context;

    moment.locale(i18n.t('moment.locale'));
  }

  render() {
    const { i18n } = this.context;
    const { event, title } = this.props;

    const startDate = moment.tz(event.startDate, i18n.t('moment.timezone'));
    const endDate = moment.tz(event.endDate, i18n.t('moment.timezone'));
    const isSameDay = startDate.isSame(endDate, 'day');

    let eventClassName = '';

    if (event.isHighlighted) {
      eventClassName = `${s.highlighted}`;
    }

    return (
      <Link to={event.url} target={event.target} className={s.link}>
        <div className={`${s.event} ${eventClassName}`}>
          {title && <h3 className={s.heading}>{title}</h3>}
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <div className={s.innerWrapLeft}>
                <div className={s.iconWrap}>
                  {event.typeId === 3 && (
                    <div className={s.iconSelfscanning}>
                      <i className="icon icon-smartphone-android" />
                    </div>
                  )}
                </div>
                <div className={s.dateWrap}>
                  <span className={s.titleMobile}>{event.name}</span>
                  <span className={s.date}>
                    {!isSameDay && `${moment.tz(event.startDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateShort'))} - `}
                    {moment(event.endDate).format(i18n.t('moment.dateFormat'))}
                  </span>
                  {!event.isAllDay && (
                    <span className={s.time}>
                      {`${moment.tz(event.startDate, i18n.t('moment.timezone')).format('H:mm')} - ${moment.tz(event.endDate, i18n.t('moment.timezone')).format('H:mm')} ${i18n.t('moment.hourShort')}`}
                    </span>
                  )}
                </div>
                <div className={s.clear} />
              </div>
            </div>
            <div className="col-md-1">
              <div className={s.divider} />
            </div>
            <div className="col-md-6">
              <div className={s.innerWrap}>
                <span className={s.title}>{event.name}</span>
                {event.address && <span className={s.address}>{event.address}</span>}
                {event.location && <span className={s.location}>{event.location}</span>}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default withStyles(s)(EventItem);
