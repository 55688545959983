import React from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import GoogleMapReact from 'google-map-react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CommunitiesMap.scss';
import mapStyles from './mapStyles.json';
import Link from '../Link';
import FlipClock from '../FlipClock';

class MarkerContainer extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    showCounter: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
    fetch: PropTypes.func,
  };

  state = {
    opened: false,
    minutes: null,
    rank: null,
  };

  componentDidMount() {
    this.token = PubSub.subscribe('Marker', (_, item) => {
      const { community } = this.props;

      if (item && item.id !== community.id && this.state.opened) {
        this.hide();
      } else if (item === null && this.state.opened) {
        this.hide();
      }
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  async fetchStats() {
    const { community } = this.props;
    const { fetch } = this.context;
    const { minutes } = this.state;

    if (minutes > 0) {
      // Data available, only fetch once
      return;
    }

    // Display rank of parent community if it exists
    const id = community.parentCommunityId || community.id;

    const stats = await fetch(`/api/communities/${id}/stats`)
      .then(res => res.json());

    if (stats) {
      this.setState({
        minutes: stats.timeInMinutes,
        rank: stats.rank,
      });
    }
  }

  hide() {
    this.setState({ opened: false });
  }

  toggle() {
    const opened = !this.state.opened;
    this.setState({
      opened,
    });

    if (opened) {
      this.fetchStats();
    }

    PubSub.publish('Marker', this.props.community);
  }

  setMinutes(value) {
    this.setState({
      minutes: value,
    });
  }

  getBadge(micrositeLogoUrl) {
    if (micrositeLogoUrl && micrositeLogoUrl !== 'string') {
      return `url(${micrositeLogoUrl})`;
    }

    return 'url(/badge_placeholder.gif)';
  }

  render() {
    const { community, showCounter } = this.props;
    const { opened, minutes, rank } = this.state;
    const { i18n } = this.context;
    const infoBoxClass = opened ? s.infoBoxOpened : s.infoBoxHidden;
    const pinClass = opened ? s.pinSelected : s.pinUnselected;
    return (
      <div>
        <i
          className={`${s.pin} ${pinClass} icon icon-pin`}
          onClick={() => this.toggle()}
          title={community.baseCommunity.name}
        />

        <div className={`${s.infoBoxOuter} ${infoBoxClass}`}>
          <div className={s.infoBoxInner}>
            <div className={s.infoBoxLogoWrap}>
              {showCounter && rank && (
                <div className={s.infoBoxRankWrap}>
                  <span className={s.infoBoxRankLabel}>{i18n.t('map.rank')}</span>
                  <span className={s.infoBoxRank}>{rank}</span>
                </div>
              )}
              <img src={`/badge_${community.group.short}.svg`} className={s.infoBoxLogo} />
              <div className={s.infoBoxLogoInner} style={{ backgroundImage: this.getBadge(community.micrositeLogoUrl) }} />
            </div>
            <div className={s.infoBoxText}>
              <h3>{community.baseCommunity.name}</h3>
              {showCounter && minutes != null && opened && (
                <div className={s.minutesContainer}>
                  <span className={s.minutesContainerLabel}>
                    {i18n.t('map.collectedMinutes')}
                  </span>
                  <FlipClock
                    id={`counter-${community.id}`}
                    type="count"
                    value={minutes}
                    interval={5}
                    padZeros={6}
                    layout="fit"
                    initialOffset={0}
                    increaseMax={4}
                  />
                </div>
              )}
              { community.url && (
                <Link
                  className={s.infoBoxLink}
                  to={community.url}
                >
                  {i18n.t('map.more')}
                </Link>
              )}
            </div>
          </div>
          <div className={s.infoBoxTriangle} />
        </div>
      </div>
    );
  }
}

class CommunitiesMap extends React.Component {
  static propTypes = {
    communities: PropTypes.array,
    showCounter: PropTypes.bool,
  };

  static defaultProps = {
    communities: [],
    showCounter: false,
  };

  static contextTypes = {
    fetch: PropTypes.func,
    i18n: PropTypes.object,
  };

  static mapOptions() {
    return {
      styles: mapStyles,
    };
  }

  state = {
    communities: this.props.communities,
  };

  componentDidMount() {
    const { communities } = this.state;

    if (communities.length === 0) {
      this.getCommunities()
        .then(entries => this.setState({ communities: entries }));
    }

    this.token = PubSub.subscribe('Marker', (_, item) => {
      if (this.map && item && item.baseCommunity) {
        const lat = item.baseCommunity.positionLatitude;
        const lng = item.baseCommunity.positionLongitude;

        this.map.panTo({
          lat,
          lng,
        });

        this.map.panBy(0, -60);
      }
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  onMapClick() {
    PubSub.publish('Marker', null);
  }

  getCommunities() {
    const { fetch } = this.context;
    return fetch('/api/communities').then(res => res.json());
  }

  apiIsLoaded(map) {
    if (map) {
      this.map = map;
    }
  }

  createMarker(item) {
    const { showCounter } = this.props;

    if (item.baseCommunity && item.baseCommunity.positionLatitude && item.baseCommunity.positionLongitude) {
      const { baseCommunity } = item;
      const cords = {
        lat: baseCommunity.positionLatitude,
        lng: baseCommunity.positionLongitude,
      };

      return (
        <MarkerContainer
          key={item.id}
          {...cords}
          community={item}
          showCounter={showCounter}
        />
      );
    }

    return '';
  }

  render() {
    const { communities } = this.state;
    const { i18n } = this.context;
    const activeCommunities = communities.filter(community => !community.isPassive);

    return (
      <div>
        <div className="cookieconsent-optout-marketing">
          <div className={s.consent}>
            <span dangerouslySetInnerHTML={{ __html: i18n.t('map.cookieConsent') }} />
          </div>
        </div>
        {typeof window !== 'undefined' && window.Cookiebot && window.Cookiebot.consent.marketing && (<div className={s.mapWrap}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCmIXD_2sEkvHemAIyuPanIusWn94t6pi0',
              v: '3.30',
            }}
            defaultCenter={{ lat: 46.873189, lng: 8.456039 }}
            defaultZoom={8}
            yesIWantToUseGoogleMapApiInternals
            options={CommunitiesMap.mapOptions}
            onGoogleApiLoaded={({ map }) => this.apiIsLoaded(map)}
            onClick={() => this.onMapClick()}
          >
            {activeCommunities.map(item => this.createMarker(item))}
          </GoogleMapReact>
        </div>)}
      </div>
    );
  }
}

export default withStyles(s)(CommunitiesMap);
