// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2nhzB{padding-bottom:40px;background:#373737;color:#fff}._2nhzB h4{margin-bottom:21px;color:#fff;font-size:1.375rem}._2nhzB a{color:#fff}._26pKB{position:relative;max-width:1200px;margin:0 auto;padding:77px 15px 35px}._25Spx{display:block;width:205px;margin:0 auto;font-size:1.75em}._25Spx,._25Spx:hover{color:#fff;text-decoration:none}._1bQOV{color:#fff;font-size:1rem}.eIl73{background:#adff2f}.xskhS{display:table;margin:30px auto 0;text-align:center;border-spacing:40px 0}._2PXlW{display:table-cell;width:46px;height:46px;border:1px solid #fff;border-radius:50%;text-align:center;vertical-align:middle}.FmWjO{color:#fff;font-size:1.5rem}._3EqXa{max-width:1200px;margin:0 auto;padding:0 15px}._2QeMJ{background:#ea5b0c}._1W3uT{margin-top:-30px}.footer-nav,.marvinal-nav{text-align:left}.footer-col{margin-bottom:45px;text-align:center}.footer-col:last-child{margin-bottom:0}.footer-col p:last-child{margin-bottom:0}@media(min-width:768px){._1bQOV{font-size:.6875rem}._25Spx{margin:-20px 0 20px}.footer-col{margin-bottom:0;text-align:left}._2nhzB{padding-bottom:0}._2nhzB h4{font-size:1.25rem}._26pKB{padding:77px 15px}._2PXlW{width:61px;height:61px}.FmWjO{font-size:1.9rem}}@media(min-width:992px){.marginal-nav{text-align:center}}@media(min-width:992px){._25Spx{margin:0}}", ""]);
// Exports
exports.locals = {
	"footer": "_2nhzB",
	"container": "_26pKB",
	"brand": "_25Spx",
	"brandTxt": "_1bQOV",
	"marginalContainer": "eIl73",
	"socialIconContainer": "xskhS",
	"socialIconWrap": "_2PXlW",
	"socialIcon": "FmWjO",
	"footerNavContainer": "_3EqXa",
	"footerNav": "_2QeMJ",
	"logo": "_1W3uT"
};
module.exports = exports;
