// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2iwYz{position:relative;width:100%;height:264px;background-color:#ea5b0c;background-repeat:no-repeat;background-position:50%;background-size:cover;text-align:center}._2iwYz h1,._2iwYz h4{color:#fff}._1s6Ud:before{position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(55,58,60,.5);content:\"\"}.b6t0J{font-size:1.875rem;font-weight:600}._2240r{display:none}._3DVTs{height:100%}._2iYui{display:table;position:relative;width:100%;max-width:1200px;height:100%;margin:auto;padding:10px 15px}._5PExl{display:table-cell;vertical-align:middle}._2HWhm{display:block;width:100%;height:100%}._2HWhm:before{display:block;position:absolute;width:100%;height:100%;-webkit-transition:width .5s ease-in;transition:width .5s ease-in;background:rgba(234,91,12,.7);content:\"\"}._1s6Ud ._2HWhm:before{content:none}._3Mk9K{display:table-cell;text-align:right;vertical-align:middle}.HEa-y{display:inline;max-width:80%;max-height:80%}@media(max-width:768px){._1cyUY ._2iYui{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}._1cyUY ._5PExl{display:block;text-align:center}._1cyUY ._3Mk9K{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}}@media(min-width:768px){._2iwYz{height:312px;text-align:left}._3TAR9{display:table-cell;width:50%}._5PExl{width:50%}._2HWhm:before{width:50%}._2240r{display:block}.b6t0J{font-size:3rem;font-weight:700}}", ""]);
// Exports
exports.locals = {
	"pageHeader": "_2iwYz",
	"isFaded": "_1s6Ud",
	"headline": "b6t0J",
	"subheadline": "_2240r",
	"row": "_3DVTs",
	"contentContainer": "_2iYui",
	"titleContainer": "_5PExl",
	"headerContainer": "_2HWhm",
	"logoContainer": "_3Mk9K",
	"logo": "HEa-y",
	"hasLogo": "_1cyUY",
	"emptyCell": "_3TAR9"
};
module.exports = exports;
