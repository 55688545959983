// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3bMN6{position:fixed;top:0;width:100%;background:#fff;-webkit-box-shadow:0 2px 10px 0 rgba(0,0,0,.07);box-shadow:0 2px 10px 0 rgba(0,0,0,.07);z-index:990}.appbanner ._3bMN6{top:70px}.is-navigation-overlay-open{overflow:hidden}._2Sdg6{clear:both}.izfMl{position:relative;max-width:1200px;height:72px;margin:0 auto;padding:10px 15px;background:#fff;z-index:2010}._1LNy6{width:100%}._1-TOO{display:inline-block;position:relative;padding:0 12px;background:#fff;z-index:1001}._1N9U4{display:block;width:auto;height:2px;margin:24px 9px;background:#979797;opacity:.2}._1lOm0{display:none;position:absolute;top:48px;width:100%;height:1px;background:#979797;opacity:.3;z-index:1}._1AtfL{width:auto;height:55px}@media(min-width:992px){.izfMl{height:120px;-webkit-transition:height .3s ease;transition:height .3s ease;background:rgba(0,0,0,0)}._3LiqC{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}._3LiqC,._1LNy6{position:relative;-webkit-box-flex:0;width:100%;min-height:1px;padding-right:15px;padding-left:15px}._1LNy6{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}._1lOm0{display:block}._1AtfL{height:100px;-webkit-transition:height .3s ease;transition:height .3s ease}.is-header-shrunk .izfMl{height:61px}.is-header-shrunk ._1AtfL{height:40px}.is-header-shrunk ._1lOm0{display:none}}", ""]);
// Exports
exports.locals = {
	"header": "_3bMN6",
	"clear": "_2Sdg6",
	"container": "izfMl",
	"containerNav": "_1LNy6",
	"brand": "_1-TOO",
	"mobileDivider": "_1N9U4",
	"divider": "_1lOm0",
	"logo": "_1AtfL",
	"containerLogo": "_3LiqC"
};
module.exports = exports;
