// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1skPT{padding:20px;font-family:Montserrat,sans-serif;font-weight:600;text-align:left;text-transform:uppercase}._3AMj1{width:130px;color:#ea5b0c;font-size:1rem;font-weight:600;word-break:break-all}._1hzBU{color:#949eb2;font-size:.875rem}._3ZEiw{padding-top:10px;padding-left:10px;font-size:1.375rem}._2OwDW,._1Rzql{display:-webkit-box;display:-ms-flexbox;display:flex;margin-bottom:34px}._2mjF0{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}._3Rsvm{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}._1BEDk{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}.zsobt:hover{text-decoration:none}.ufyB0{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}._2gB-V ._3ZEiw{opacity:0}@media(min-width:992px){.ufyB0{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}._2mjF0{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}._3Rsvm{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}._1Rzql{display:block}._1Rzql ._1HoAP{margin-top:70px;margin-bottom:-70px}._1Rzql:first-child{margin-right:158px}._1Rzql:last-child{margin-left:158px}._2OwDW{display:block}._1skPT{padding:0;text-align:center}._3ZEiw{padding:0}._3AMj1{display:table-cell;width:180px;height:48px;font-size:.875rem;text-align:center;vertical-align:middle}._1hzBU{margin-bottom:11px}._1HoAP{height:220px}.pCC7y{display:table;width:100%;margin-top:12px}}", ""]);
// Exports
exports.locals = {
	"communityInfoWrap": "_1skPT",
	"communityName": "_3AMj1",
	"communityMinutes": "_1hzBU",
	"communityTrend": "_3ZEiw",
	"inner": "_2OwDW",
	"outer": "_1Rzql",
	"rank1": "_2mjF0",
	"rank2": "_3Rsvm",
	"rank3": "_1BEDk",
	"communityLink": "zsobt",
	"wrap": "ufyB0",
	"isPassive": "_2gB-V",
	"badgeWrap": "_1HoAP",
	"communityNameWrap": "pCC7y"
};
module.exports = exports;
