// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3T3Gl{position:relative;width:100%}._3OkPX{position:relative;margin-top:60px;margin-bottom:70px;font-size:30px;text-align:center}._3OkPX:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._1rOBW{display:table;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "_3T3Gl",
	"title": "_3OkPX",
	"table": "_1rOBW"
};
module.exports = exports;
