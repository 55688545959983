import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EventItemFuture.scss';
import Link from '../../Link';
import { renderDateTime } from '../../../utils/eventItemUtil';

class EventItemFuture extends React.Component {
  static propTypes = {
    event: PropTypes.shape({

    }).isRequired,
    title: PropTypes.string,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    title: null,
  };

  render() {
    const { i18n } = this.context;
    const { event } = this.props;

    let eventClassName = '';
    if (event.isHighlighted) {
      eventClassName = `${s.highlighted}`;
    }

    let selfScanningVisibility = '';
    if (event.typeId === 3) {
      selfScanningVisibility = 'visible';
    }

    return (
      <div className={`${s.row} ${eventClassName}`}>
        <div className={`${s.cell} ${s.cellIcon}`}>
          <Link to={event.url} target={event.target} className={s.link}>
            <div className={`${s.iconSelfscanning} ${selfScanningVisibility}`}>
              <i className="icon icon-smartphone-android" />
            </div>
          </Link>
        </div>
        <div className={`${s.cell} ${s.showMobile}`}>
          <Link to={event.url} target={event.target} className={s.link}>
            <span className={s.title}>{event.name}</span>
            <div className={s.dateTime} dangerouslySetInnerHTML={{ __html: renderDateTime(event, i18n) }} />
            {event.address && <span className={s.address}>{event.address}</span>}
            {event.location && <span className={s.location}>{event.location}</span>}
          </Link>
        </div>
        <div className={`${s.cell} ${s.hideMobile}`}>
          <Link to={event.url} target={event.target} className={s.link}>
            <div className={s.dateTime} dangerouslySetInnerHTML={{ __html: renderDateTime(event, i18n) }} />
          </Link>
        </div>
        <div className={`${s.cell} ${s.hideMobile}`}>
          <Link to={event.url} target={event.target} className={s.link}>
            <span className={s.title}>{event.name}</span>
          </Link>
        </div>
        <div className={`${s.cell} ${s.hideMobile}`}>
          <Link to={event.url} target={event.target} className={s.link}>
            {event.address && <span className={s.address}>{event.address}</span>}
            {event.location && <span className={s.location}>{event.location}</span>}
          </Link>
        </div>
        <div className={s.cell}>
          <Link to={event.url} target={event.target} className={s.link}>
            <div className={s.moreLink}><span>{i18n.t('map.more')}</span></div>
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(EventItemFuture);
