// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zHsux{max-width:1200px;padding:55px 15px 50px}.zHsux h1,.zHsux h2,.zHsux h3,.zHsux h4{position:relative;margin-bottom:60px;text-align:center}.zHsux h1:after,.zHsux h2:after,.zHsux h3:after,.zHsux h4:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}.zHsux p{max-width:580px;margin:0 auto 60px;text-align:left}.woY5A{max-width:1200px;margin:0 auto}.tabContainer{padding-top:15px}.gND0a{display:block;margin-top:10px;overflow:hidden}.gND0a,.cIqGy{position:relative}.cIqGy{display:-webkit-box;display:-ms-flexbox;display:flex;margin:0 auto;border-bottom:1px solid rgba(148,158,178,.4);text-align:center;overflow:scroll}._1zV9L{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;padding:0 15px}._3_UiR{display:inline-block;position:relative;margin:0 5px 0 0;padding:12px 40px;border:0;border-radius:5px 5px 0 0;background:rgba(148,158,178,.2);color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase;white-space:nowrap;z-index:2}._3_UiR:last-of-type{margin-right:0}._3_UiR.active{outline:0;background:#ea5b0c;color:#fff}._3_UiR.active:hover{color:#fff}._3_UiR:hover{outline:0;color:#ea5b0c;cursor:pointer}._15_8e{display:none;position:relative;padding:32px 15px 42px;background-color:#fff}._15_8e.active{display:block}._15_8e>div{margin-bottom:50px}._15_8e>div:last-child{margin-bottom:0}@media(min-width:768px){.cIqGy{display:block;overflow:hidden}.gND0a{margin:30px 0 0;padding:0;overflow:visible}.gND0a:after,.gND0a:before{height:47px}}", ""]);
// Exports
exports.locals = {
	"container": "zHsux",
	"tabContentContainer": "woY5A",
	"tabList": "gND0a",
	"tabNav": "cIqGy",
	"tabNavInner": "_1zV9L",
	"tabButton": "_3_UiR",
	"tabContent": "_15_8e"
};
module.exports = exports;
