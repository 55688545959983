import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './Header.scss';
import Link from '../Link';
import MarginalNavigation from '../MarginalNavigation';
import BurgerMenu from '../BurgerMenu';
import MainNavigation from '../MainNavigation';
import MobileNavigationOverlay from '../MobileNavigationOverlay';
import LocaleSwitcherMobile from '../LocaleSwitcherMobile';
import MobileNavigation from '../MobileNavigation';
import MobileNavigationMarginal from '../MobileNavigationMarginal';
import * as ScrollHandler from '../../utils/scrollHandler';
import * as Utility from '../../utils/utility';


class Header extends React.Component {
  static propTypes = {
    navigationOverlayOpen: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    simplified: PropTypes.bool,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    navigationOverlayOpen: false,
    simplified: false,
  };

  state = {
    navigationOverlayOpen: this.props.navigationOverlayOpen,
  };

  componentDidMount() {
    ScrollHandler.addScrollHandler((currentScroll, lastScroll, viewport, resized) => {
      if (currentScroll.y > 120 && lastScroll.y <= 120) {
        Utility.addClass(document.body, 'is-header-shrunk');
      } else if (currentScroll.y <= 120 && lastScroll.y > 120) {
        Utility.removeClass(document.body, 'is-header-shrunk');
      }
    });
  }

  toggle() {
    const navigationOverlayOpen = !this.state.navigationOverlayOpen;
    if (navigationOverlayOpen) {
      Utility.addClass(document.body, 'is-navigation-overlay-open');
    } else {
      Utility.removeClass(document.body, 'is-navigation-overlay-open');
    }
    this.setState({
      navigationOverlayOpen,
    }, () => {
      const { toggleModal } = this.props;
      toggleModal(navigationOverlayOpen);
    });
  }

  onBurgerMenuToggle() {
    this.toggle();
  }

  onMainNavigationClick() {
    this.toggle();
  }

  onServiceNavigationClick() {
    this.toggle();
  }

  render() {
    const { i18n } = this.context;
    const { simplified } = this.props;

    return (
      <div className={s.header}>
        <div className={s.container}>
          <div className="row">
            <div className={s.containerLogo}>
              <Link className={s.brand} to="/">
                <img
                  src={i18n.t('header.logoUrl')}
                  className={s.logo}
                  width="38"
                  height="38"
                  alt={i18n.t('meta.title')}
                />
              </Link>
            </div>
            <div className={s.containerNav}>
              <MarginalNavigation simplified={simplified} />
              <div className={s.clear} />
              {!simplified && <MainNavigation />}
              <div className={s.clear} />
              {!simplified && (
                <BurgerMenu active={this.state.navigationOverlayOpen} onClick={() => this.onBurgerMenuToggle()} />
              )}
            </div>
          </div>
        </div>
        <div className={s.divider} />
        <MobileNavigationOverlay open={this.state.navigationOverlayOpen}>
          <MobileNavigation onClick={item => this.onMainNavigationClick(item)} />
          <div className={s.mobileDivider} />
          <MobileNavigationMarginal onClick={item => this.onServiceNavigationClick(item)} />
          <div className={s.mobileDivider} />
          <LocaleSwitcherMobile onClick={item => this.onServiceNavigationClick(item)} />
        </MobileNavigationOverlay>
      </div>
    );
  }
}

export default withStyles(s)(Header);
