import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Sponsors.scss';
import SponsorList from '../../components/SponsorList';
import CircleButton from '../../components/CircleButton';
import PageHeader from '../../components/PageHeader';
import ComponentType from '../../components/ComponentType';
import { withInjectedLinks } from '../helper';

class Sponsors extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      navigation_name: PropTypes.string,
      pageTitle: PropTypes.string,
      sponsors: PropTypes.array,
      slices: PropTypes.array,
    }).isRequired,
  };

  render() {
    const { content } = this.props;
    let circleButton = null;

    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton label={contest.text} link={contest.link} />;
    }

    return (
      <div>
        <PageHeader
          title={content.pageTitle}
          subheadline={content.subheadline}
          image={content.pageHeader}
        />
        {circleButton}
        {content.sponsors.map((item, index) => (
          <SponsorList key={index} data={item} />
        ))}
        {content.slices.map(slice => (
          <ComponentType data={slice} />
        ))}
      </div>
    );
  }
}

export default withStyles(s)(withInjectedLinks(Sponsors));
