import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './LocaleSwitcherMobile.scss';

class LocaleSwitcherMobile extends React.Component {
  static contextTypes = {
    route: PropTypes.object,
    locale: PropTypes.string,
    locales: PropTypes.array,
    i18n: PropTypes.object,
    onClick: PropTypes.func,
  };

  static propTypes = {
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
  };

  static disableOption(locale, currentLocale, alternateLocales) {
    if (locale === currentLocale) {
      return false;
    }

    return !Object.hasOwnProperty.call(alternateLocales, locale);
  }

  state = {
    opened: false,
  };

  handleClick(item) {
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  }

  toggle = () => {
    this.setState({
      opened: !this.state.opened,
    });
  }

  /**
   * Forces a browser reload, if a different language is selected.
   * @param item
   */
  change(locale) {
    const { route: { alternateLocales } } = this.context;
    const alternate = alternateLocales[locale];
    const { protocol, port } = window.location;

    if (alternate) {
      const { hostname, path } = alternate;
      window.location = `${protocol}//${hostname}${port ? `:${port}` : ''}${path}`;
    }

    this.handleClick(locale);
  }

  render() {
    const { locales, i18n } = this.context;
    const { locale, route: { alternateLocales } } = this.context;

    return (
      <div className={s.languageMenu}>
        {locales.map(loc => (
          <button
            className={s.languageButton}
            onClick={() => this.change(loc)}
            disabled={LocaleSwitcherMobile.disableOption(loc, locale, alternateLocales)}
            key={loc}
          >
            {i18n.t(`locale.${loc}.language`)}
          </button>
        ))}
      </div>
    );
  }
}

export default withStyles(s)(LocaleSwitcherMobile);
