// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2loob{margin-bottom:22px;border-radius:5px;background:#fff;-webkit-box-shadow:0 10px 10px 0 rgba(0,0,0,.15);box-shadow:0 10px 10px 0 rgba(0,0,0,.15);overflow:hidden}._2loob:last-of-type{margin-bottom:0}._1Lm7k{position:relative;width:100%;height:220px;background-repeat:no-repeat;background-position:50%;background-size:cover}._2ZLdK{position:relative;padding:26px 30px 42px;white-space:pre-line}.jK8X5{margin-bottom:21px;color:#000;font-size:22px;font-weight:600;line-height:32px}._23Wej{margin-bottom:18px;color:#949eb2;font-size:13px;text-transform:uppercase}._3DJvS,._23Wej{position:relative}._3DJvS a{color:#ea5b0c}._3DJvS a:hover{color:#ef5350;text-decoration:none}@media(min-width:768px){._1Lm7k{height:387px}}", ""]);
// Exports
exports.locals = {
	"news": "_2loob",
	"imageWrap": "_1Lm7k",
	"textWrap": "_2ZLdK",
	"headline": "jK8X5",
	"date": "_23Wej",
	"content": "_3DJvS"
};
module.exports = exports;
