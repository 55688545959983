import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PageHeader.scss';

class PageHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
    image: PropTypes.object,
    logo: PropTypes.object,
    faded: PropTypes.bool,
  };

  static defaultProps = {
    subheadline: null,
    image: {},
    logo: {},
    faded: false,
  };

  render() {
    const { title, subheadline, image, logo, faded } = this.props;
    const inline = {};

    if (image && image.url) {
      inline.backgroundImage = `url(${image.url})`;
    }

    return (
      <div className={`${s.pageHeader} ${faded ? s.isFaded : ''}`} style={inline}>
        <div className={s.headerContainer}>
          <div className={s.contentContainer}>
            <div className={s.titleContainer}>
              <h1 className={s.headline}>{title}</h1>
              <h4 className={s.subheadline}>{subheadline}</h4>
            </div>
            {logo.url ? (
              <div className={s.logoContainer}>
                <img src={logo.url} className={s.logo} alt={title} />
              </div>
            ) : <div className={s.emptyCell} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(PageHeader);
