import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SponsorItem.scss';
import Link from '../../Link';

class SponsorItem extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      url: PropTypes.shape({
        url: PropTypes.string,
      }),
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { data: { url, image } } = this.props;

    return (
      <div className={s.sponsor}>
        <Link
          target={url.target}
          to={url.url}
        >
          <img src={image.url} className={s.image} alt={image.alt} />
        </Link>

      </div>
    );
  }
}

export default withStyles(s)(SponsorItem);
