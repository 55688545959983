// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".oIbJT{max-width:270px;margin:0 auto}.oIbJT,.oIbJT:focus{border-color:#fff;color:#fff}.oIbJT::-webkit-input-placeholder{color:hsla(0,0%,100%,.38)}.oIbJT::-moz-placeholder{color:hsla(0,0%,100%,.38)}.oIbJT:-ms-input-placeholder{color:hsla(0,0%,100%,.38)}.oIbJT::-ms-input-placeholder{color:hsla(0,0%,100%,.38)}.oIbJT::placeholder{color:hsla(0,0%,100%,.38)}._3A_xE{width:100%;max-width:270px}.MzN5g{margin-top:10px;font-size:.85rem}@media(min-width:768px){.oIbJT{max-width:328px;margin:0}}", ""]);
// Exports
exports.locals = {
	"emailInput": "oIbJT",
	"submit": "_3A_xE",
	"hint": "MzN5g"
};
module.exports = exports;
