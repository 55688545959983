// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ZJ8K{max-width:580px;margin:0 auto}._1Pj4r{font-weight:700}._2OZ77{position:relative;margin-bottom:70px;font-size:30px;text-align:center}._2OZ77:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._3UruT a{color:#ea5b0c}._3UruT a:hover{color:#ef5350;text-decoration:none}._3T7_d{margin:48px 0 0}._20jbf{color:#e40521;font-weight:700}._3NXBO,._20jbf{margin-top:12px}", ""]);
// Exports
exports.locals = {
	"container": "_1ZJ8K",
	"deadline": "_1Pj4r",
	"title": "_2OZ77",
	"privacy": "_3UruT",
	"form": "_3T7_d",
	"exceeded": "_20jbf",
	"alert": "_3NXBO"
};
module.exports = exports;
