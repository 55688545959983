import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PdfDownloadLink.scss';

class PdfDownloadLink extends React.Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    showArrow: PropTypes.bool,
  };

  static defaultProps = {
    showArrow: false,
  };

  render() {
    const { link, label, showArrow } = this.props;

    return (
      <a target="_blank" href={link} className={s.link}>
        <i className={`icon icon-collection-pdf ${s.iconPdf}`} />
        {' '}
        <span className="pdfLabel">{label}</span>
        {' '}
        {showArrow && <i className={`icon icon-arrow-right ${s.iconArrow}`} />}
      </a>
    );
  }
}

export default withStyles(s)(PdfDownloadLink);
