import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Helmet } from 'react-helmet';
import globals from '../../styles/globals.scss';

// external-global styles must be imported in your JS.
import s from './Layout.scss';
import classNames from '../../utils/classNames';
import Header from '../Header';
import Footer from '../Footer';
import AppBanner from '../AppBanner';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    simplified: PropTypes.bool,
  };

  static contextTypes = {
    season: PropTypes.object,
  };

  static defaultProps = {
    className: null,
    simplified: false,
  };

  state = {
    modalOpen: false,
  };

  toggleModal(forced) {
    // if (this.modalOpen)
    this.setState({
      modalOpen: forced,
    });
  }

  render() {
    const {
      className,
      simplified,
      ...props
    } = this.props;

    const { season } = this.context;

    let rootClassNames = classNames(s, {
      'modal--open': this.state.modalOpen,
    });

    if (className) {
      rootClassNames += ` ${className}`;
    }

    return (
      <div className={rootClassNames} {...props}>
        <Helmet>
          <meta name="robots" content="index,follow" />
        </Helmet>
        {season.participantAppActive && (
          <AppBanner />
        )}
        <Header toggleModal={forced => this.toggleModal(forced)} simplified={simplified} />
        <div className={s.contentWrap}>
          {this.props.children}
        </div>
        <Footer simplified={simplified} />
      </div>
    );
  }
}

export default withStyles(globals, s)(Layout);
