import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PageHeaderMicrosite.scss';

class PageHeaderMicrosite extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    rank: PropTypes.number,
    short: PropTypes.string.isRequired,
    micrositeLogoUrl: PropTypes.string,
    micrositeBackgroundImageUrl: PropTypes.string,
  };

  static defaultProps = {
    rank: null,
    micrositeLogoUrl: null,
    micrositeBackgroundImageUrl: null,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  getBadge(micrositeLogoUrl) {
    if (micrositeLogoUrl && micrositeLogoUrl !== 'string') {
      return `url(${micrositeLogoUrl})`;
    }

    return 'url(/badge_placeholder.gif)';
  }

  getBackground(micrositeBackgroundImageUrl) {
    if (micrositeBackgroundImageUrl && micrositeBackgroundImageUrl !== 'string') {
      return `url(${micrositeBackgroundImageUrl})`;
    }

    return 'none';
  }

  render() {
    const { name, rank, short, micrositeLogoUrl, micrositeBackgroundImageUrl } = this.props;
    const { i18n } = this.context;

    return (
      <div className={s.pageHeader} style={{ backgroundImage: this.getBackground(micrositeBackgroundImageUrl) }}>
        <div className={s.headerContainer}>
          <div className={s.contentContainer}>
            <div className={s.badgeContainer}>
              {rank && (
                <div className={s.rankWrap}>
                  <span className={s.rankLabel}>{i18n.t('map.rank')}</span>
                  <span className={s.rank}>{rank}</span>
                </div>
              )}
              <img src={`/badge_${short}.svg`} className={s.badge} />
              <div className={s.communityBadge} style={{ backgroundImage: this.getBadge(micrositeLogoUrl) }} />
              <div className={s.badgeBackground} />
            </div>
            <div className={s.titleContainer}>
              <h1 className={s.headline}>{name}</h1>
            </div>
            <div className={s.emptyCell} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(PageHeaderMicrosite);
