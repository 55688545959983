// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2bgpe{margin-top:0;margin-bottom:70px;text-align:center}._2bgpe:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}.jEc1F{max-width:380px;width:100%;height:41px;margin:20px auto}._116NS{font-size:.8125rem;margin:20px auto;text-align:center}._2YqB0{display:inline-block;border:none;background:rgba(0,0,0,0);cursor:pointer}._2RP4U{font-size:3rem;display:block;margin:15px 0;color:#373737;font-family:Montserrat,sans-serif;font-weight:700;line-height:3rem}.rp2mO{list-style-type:none}.rp2mO a{color:#ea5b0c}._3Sx5d{max-width:580px;margin:0 auto 2.5em;font-size:1.5rem;text-align:center}.contentContainer .rp2mO{padding-left:0}@media(min-width:768px){._2RP4U{margin:20px 0}}", ""]);
// Exports
exports.locals = {
	"title": "_2bgpe",
	"select": "jEc1F",
	"buttonContainer": "_116NS",
	"resetButton": "_2YqB0",
	"colTitle": "_2RP4U",
	"colList": "rp2mO",
	"intro": "_3Sx5d"
};
module.exports = exports;
