import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './NotFound.scss';

class NotFound extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  render() {
    const { title } = this.props;
    const { i18n } = this.context;

    return (
      <div className="contentContainer">
        <div className={s.container}>
          <h2>{title}</h2>
          <p>{i18n.t('notFound.text')}</p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(NotFound);
