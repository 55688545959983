// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Y3M7 h3{margin-top:0;margin-bottom:70px;text-align:center}._3Y3M7 h3:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._2gApP{display:block;margin-top:2.625rem;text-align:center}._1CHVv{position:relative;color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase}._1CHVv:hover{color:#727a8b;text-decoration:none}._1CHVv:after{position:absolute;padding-top:3px;padding-left:20px;font-family:Material-Design-Iconic-Font;content:\"\"}._3maEt{display:block;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}._3bq4g{width:100%;text-align:center}._3IY1r{width:180px}._3WfHZ{display:block}._3WfHZ:hover{cursor:pointer}@media(min-width:768px){._3maEt{display:-webkit-box;display:-ms-flexbox;display:flex}._3bq4g{width:auto;padding:0 20px}}@media(min-width:992px){._3bq4g{padding:0 60px}}", ""]);
// Exports
exports.locals = {
	"sponsorTeaser": "_3Y3M7",
	"moreLinkWrap": "_2gApP",
	"moreLink": "_1CHVv",
	"sponsorTeaserWrap": "_3maEt",
	"item": "_3bq4g",
	"image": "_3IY1r",
	"link": "_3WfHZ"
};
module.exports = exports;
