// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Qs5x{display:none;float:right}.SIzO4{font-size:.6875rem}.kDf8P{color:#949eb2}.kDf8P._35CsI,.kDf8P:hover{color:#ea5b0c}.IkuwC{padding:0 15px}@media(min-width:992px){._3Qs5x{display:-webkit-box;display:-ms-flexbox;display:flex}.is-header-shrunk ._3Qs5x{display:none}}", ""]);
// Exports
exports.locals = {
	"marginalNavigation": "_3Qs5x",
	"navItem": "SIzO4",
	"navLink": "kDf8P",
	"active": "_35CsI",
	"link": "IkuwC"
};
module.exports = exports;
