// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hp3xk{padding:32px 15px 42px;background-color:#fff}._3O5nX{max-width:1200px;margin:30px auto 50px}._1r6EP{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:50px}._3bS7j,._1r6EP{display:-webkit-box;display:-ms-flexbox;display:flex;margin:0 -15px;background-color:#f5f7fa}._3bS7j{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:15px;padding:50px 15px 0}@media(min-width:768px){._3bS7j{padding:50px 95px 0}}._3PMhQ{width:100%}._3PMhQ tr{vertical-align:middle}._3PMhQ td{width:25%}@media(min-width:768px){._3PMhQ td:first-child{padding-left:100px}}@media(min-width:768px){._3PMhQ td:last-child{padding-right:124px}}@media(max-width:768px){._3PMhQ td{width:100%}}._3PMhQ thead{color:rgba(0,0,0,.38);font-size:1rem}._3PMhQ thead td{padding:0 9px 33px}@media(max-width:768px){._3PMhQ thead{display:none}}._3PMhQ tbody{color:#373a3c}._3PMhQ tbody tr:nth-child(2n){background:hsla(0,0%,100%,.6)}._3PMhQ tbody tr:nth-child(odd){background:#f5f7fa}@media(max-width:768px){._3PMhQ tbody tr{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;padding:15px}}@media(min-width:768px){._3PMhQ tbody td{padding:11px 9px}}@media(min-width:768px){._3PMhQ tbody{color:#000}}._3Qcjc{font-size:.875rem}._3Qcjc a{color:#ea5b0c}@media(min-width:768px){._3Qcjc{font-family:Montserrat,sans-serif;font-size:1.125rem}}._2c_Oq{display:block;width:100%;height:41px;padding-right:30px;border:0;border-bottom:1px solid #949eb2;background:rgba(0,0,0,0)}", ""]);
// Exports
exports.locals = {
	"events": "hp3xk",
	"content": "_3O5nX",
	"tableContainer": "_1r6EP",
	"filters": "_3bS7j",
	"table": "_3PMhQ",
	"tableCell": "_3Qcjc",
	"input": "_2c_Oq"
};
module.exports = exports;
