import React from 'react';
import { Helmet } from 'react-helmet';
import Challenge from './Challenge';
import Layout from '../../components/Layout/Layout';

async function action(context) {
  const { content, route } = context;

  return {
    ...route,
    ...content.meta,
    component: (
      <Layout simplified>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Challenge content={content} />
      </Layout>
    ),
  };
}

export default action;
