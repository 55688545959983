import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './BackToTop.scss';

class BackToTop extends React.Component {
  static propTypes = {
    scrollStepInPx: PropTypes.string.isRequired,
    delayInMs: PropTypes.string.isRequired,
  };

  state = {
    intervalId: 0,
  };

  componentWillUnmount() {
    window.clearInterval(this.state.intervalId);
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      window.clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    const intervalId = window.setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId });
  }

  render() {
    return (
      <button className={s.backToTop} onClick={() => { this.scrollToTop(); }}>
        <i className={`icon icon-chevron-up ${s.icon}`} />
      </button>
    );
  }
}

export default withStyles(s)(BackToTop);
