// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3uQXU{position:relative;width:18px;height:24px;margin-top:-37px;margin-right:19px;float:right;border:0;border-radius:0;background:rgba(0,0,0,0);cursor:pointer;-webkit-appearance:none;-moz-appearance:none;appearance:none;z-index:2000}._3uQXU:focus,._3uQXU:hover{outline:none}._264n3{position:absolute;left:0;width:100%;height:3px;-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out;border-radius:2px;background:#949eb2;-webkit-transform-origin:50% 50%;-ms-transform-origin:50% 50%;transform-origin:50% 50%}.WBskE ._264n3{height:3px}._1lXYZ{top:2px}.WBskE ._1lXYZ{left:5%;width:90%;-webkit-transform:translateY(5px) rotate(45deg);-ms-transform:translateY(5px) rotate(45deg);transform:translateY(5px) rotate(45deg)}.n9-jL{top:calc(50% - 4px)}.WBskE .n9-jL{height:4px;opacity:0}.KRCbu{bottom:7px}.WBskE .KRCbu{left:5%;width:90%;-webkit-transform:translateY(-7px) rotate(-45deg);-ms-transform:translateY(-7px) rotate(-45deg);transform:translateY(-7px) rotate(-45deg)}@media(min-width:992px){._3uQXU{display:none}}", ""]);
// Exports
exports.locals = {
	"burger-menu": "_3uQXU",
	"burger-menu__bar": "_264n3",
	"burger-menu--active": "WBskE",
	"burger-menu__bar--1": "_1lXYZ",
	"burger-menu__bar--2": "n9-jL",
	"burger-menu__bar--3": "KRCbu"
};
module.exports = exports;
