import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './NewsList.scss';
import NewsItem from './NewsItem';

class NewsList extends React.Component {
  static propTypes = {
    news: PropTypes.array.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  render() {
    const { i18n } = this.context;
    const { news } = this.props;

    return (
      <div className={s.container}>
        {news.map((item, index) => (
          <NewsItem key={item.id} news={item} />
        ))}
      </div>
    );
  }
}

export default withStyles(s)(NewsList);
