import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ImageList.scss';
import Link from '../Link';
/* eslint-disable react/no-danger */

class ImageList extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      linkLabel: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        target: PropTypes.string,
      }),
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    })).isRequired,
  };

  renderImageListItem(item, linkLabel) {
    return (
      <div className="row">
        <div className={`col-md-6 col-sm-12 ${s.imageCol}`}>
          <div className={s.imageWrap}>
            <img className={s.image} src={item.image.url} />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className={s.text} dangerouslySetInnerHTML={{ __html: item.text }} />
          <div className={s.linkWrap}>
            <div className={s.more} dangerouslySetInnerHTML={{ __html: linkLabel }} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { items } = this.props;
    return (
      <div className={s.imageList}>
        <div className={`row ${s.imageListRow}`}>
          {items.map((item, index) => {
            let linkLabel = '';
            if (item.linkLabel) {
              const split = item.linkLabel.split(' ');
              split[split.length - 1] = `<div class="moreIcon">${split[split.length - 1]}</div>`;
              linkLabel = split.join(' ');
            }

            if (item.link && item.link.url) {
              return (
                <div className={`col-md-6 col-sm-12 ${s.imageListItem}`} key={index}>
                  <Link to={item.link.url} target={item.link.target} className={s.link}>
                    {this.renderImageListItem(item, linkLabel)}
                  </Link>
                </div>
              );
            }

            return (
              <div className={`col-md-6 col-sm-12 ${s.imageListItem}`} key={index}>
                {this.renderImageListItem(item, linkLabel)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ImageList);
