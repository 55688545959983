// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2QYFq{margin-right:5px}._1DJUD{position:relative;padding-left:30px;color:#ea5b0c;font-family:Montserrat,sans-serif;font-size:14px;font-weight:600;line-height:14px;text-transform:uppercase;overflow-wrap:break-word;word-wrap:break-word}._1DJUD:focus,._1DJUD:hover{color:#ea5b0c;text-decoration:none}.OKWQM{margin-left:10px}._15HYd{display:block;margin-top:-25px;overflow-wrap:break-word;word-wrap:break-word;margin-left:40px;line-height:24px}._2TVJd{position:relative}", ""]);
// Exports
exports.locals = {
	"iconPdf": "_2QYFq",
	"link": "_1DJUD",
	"iconArrow": "OKWQM",
	"label": "_15HYd",
	"linkWrap": "_2TVJd"
};
module.exports = exports;
