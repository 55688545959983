
export default function classNames(stylesheet, ...args) {
  const classes = [];

  if (arguments.length < 2 || !args) {
    return classes;
  }

  for (let arg, i = 0, l = args.length; i < l; i += 1) {
    arg = args[i];
    if (arg) {
      const argType = typeof arg;

      if (argType === 'string' || argType === 'number') {
        classes.push(stylesheet[arg]);
      } else if (Array.isArray(arg)) {
        classes.push(classNames(stylesheet, ...arg));
      } else if (argType === 'object') {
        for (let c, j = 0, keys = Object.keys(arg), m = keys.length; j < m; j += 1) {
          c = stylesheet[keys[j]];
          if (arg[keys[j]]) {
            classes.push(c);
          }
        }
      }
    }
  }

  return classes.join(' ');
}
