// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3I4Qq{margin-bottom:-60px}._3I4Qq h2,._3I4Qq h3,._3I4Qq h4{margin-top:0;margin-bottom:70px;text-align:center}._3I4Qq h2:after,._3I4Qq h3:after,._3I4Qq h4:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}", ""]);
// Exports
exports.locals = {
	"sponsorList": "_3I4Qq"
};
module.exports = exports;
