import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import PageHeader from '../../components/PageHeader';
import SelectBox from '../../components/SelectBox';
import ComponentType from '../../components/ComponentType';
import getTeaserOrientation from '../../utils/teaserOrientation';
import { withInjectedLinks } from '../helper';
import s from './Events.scss';

class Events extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      navigation_name: PropTypes.string,
      pageTitle: PropTypes.string,
      footerTeasers: PropTypes.array,
      events: PropTypes.array,
      disciplines: PropTypes.array,
      communities: PropTypes.array,
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  state = {
    date: '',
    selectedDiscipline: null,
    selectedCommunity: null,
  };

  onDisciplineChange(discipline) {
    this.setState({
      selectedDiscipline: discipline,
    });
  }

  onCommunityChange(community) {
    this.setState({
      selectedCommunity: community,
    });
  }

  onDateChange(date) {
    this.setState({
      date,
    });
  }

  filterList(list) {
    const { date, selectedDiscipline, selectedCommunity } = this.state;

    if (date === '' && selectedDiscipline === null && selectedCommunity === null) {
      return list;
    }

    if (selectedDiscipline) {
      list = this.filterListByDiscipline(list, selectedDiscipline);
    }

    if (selectedCommunity) {
      list = this.filterListByCommunity(list, selectedCommunity);
    }

    if (date) {
      list = this.filterListByDate(list, date);
    }

    return list;
  }

  filterListByDiscipline(list, discipline) {
    return list.filter(event => event.disciplineId === discipline.value);
  }

  filterListByCommunity(list, community) {
    const { content: { communities } } = this.props;

    const current = communities.find(c => c.id === community.value);
    const parent = communities.find(c => c.id === current.parentCommunityId);

    return list.filter(event => event.communityId === current.id || event.communityId === parent?.id);
  }

  filterListByDate(list, date) {
    if (!/\d{1,2}\.\d{1,2}\.\d{2,4}/.exec(date)) {
      return list;
    }

    const parsed = moment(date, 'DD.MM.YYYY');
    return list.filter(event => moment(event.startDate).isSame(parsed, 'day'));
  }

  render() {
    const { content } = this.props;
    const { date, selectedDiscipline, selectedCommunity } = this.state;
    const { i18n } = this.context;
    const { events, disciplines, communities } = content;

    const disciplinesOptions = disciplines.map(discipline => ({
      value: discipline.id,
      label: discipline.name,
    }));

    const communitiesOptions = communities.map(community => ({
      value: community.id,
      label: community.parentCommunityId !== null
        ? `${community.name} (${communities.find(c => c.id === community.parentCommunityId).name})`
        : community.name,
    }));

    const list = this.filterList(events);

    let footerTeaserOrientationStart = 'left';
    let footerTeaser = '';

    moment.locale(i18n.t('moment.locale'));

    return (
      <div>
        <PageHeader
          title={content.pageTitle}
          subheadline={content.subheadline}
          image={content.pageHeader}
        />
        <div className={s.events}>
          <div className={s.content}>
            <div className={s.filters}>
              <input
                className={s.input}
                value={date}
                onChange={event => this.onDateChange(event.target.value)}
                placeholder={`${i18n.t('events.selectDate')} (dd.mm.yyyy)`}
              />
              <SelectBox
                name="disciplineFilter"
                selected={selectedDiscipline}
                options={disciplinesOptions}
                onChange={option => this.onDisciplineChange(option)}
                placeholder={i18n.t('events.selectDiscipline')}
              />
              <SelectBox
                name="communityFilter"
                selected={selectedCommunity}
                options={communitiesOptions}
                onChange={option => this.onCommunityChange(option)}
                placeholder={i18n.t('events.selectCommunity')}
              />
            </div>
            <div className={s.tableContainer}>
              <table className={s.table}>
                <thead>
                  <tr>
                    <td>{i18n.t('events.event')}</td>
                    <td>{i18n.t('events.discipline')}</td>
                    <td>{i18n.t('events.location')}</td>
                    <td>{i18n.t('events.date')}</td>
                  </tr>
                </thead>
                <tbody>
                  {list.map(event => (
                    <tr key={event.id}>
                      <td className={s.tableCell}>
                        <a href={event.url}>
                          {event.name}
                        </a>
                      </td>
                      <td className={s.tableCell}>
                        {event.discipline ? event.discipline.name : '-'}
                      </td>
                      <td className={s.tableCell}>
                        <a href={event.communityUrl}>
                          {event.communityName}
                        </a>
                      </td>
                      <td className={s.tableCell}>
                        {moment.tz(event.startDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateShort'))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {content.footerTeasers[0] && (
          <div className="footerTeasers">
            {content.footerTeasers.map(teaser => {
              teaser.orientation = footerTeaserOrientationStart;
              footerTeaser = <ComponentType key={teaser.id} additionalClasses="" data={teaser} />;
              footerTeaserOrientationStart = getTeaserOrientation(footerTeaserOrientationStart);
              return footerTeaser;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(withInjectedLinks(Events));
