import React from 'react';
import Layout from '../../components/Layout';
import Microsite from './Microsite';

async function action(context) {
  const { content } = context;

  return {
    ...content.route,
    alternateLocales: content.alternateLocales,
    component: (
      <Layout>
        <Microsite content={content} />
      </Layout>
    ),
  };
}

export default action;
