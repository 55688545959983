import React from 'react';
import Map from './Map';
import LayoutIframe from '../../components/LayoutIframe';

async function action() {
  return {
    id: 'iframe-map',
    path: '/iframe-map',
    uid: 'iframe-map',
    component: (
      <LayoutIframe>
        <Map />
      </LayoutIframe>
    ),
  };
}

export default action;
