import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';
import * as Utility from '../../utils/utility';
import s from './AppBanner.scss';

class AppBanner extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object,
  };

  static isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
  }

  componentDidMount() {
    if (AppBanner.isAndroid()) {
      if (localStorage.getItem('smartBannerTime')) {
        const smartBannerTime = moment(localStorage.getItem('smartBannerTime'));
        if (smartBannerTime < moment()) {
          this.showBanner();
          localStorage.removeItem('smartBannerTime');
        }
      } else {
        this.showBanner();
      }
    }
  }

  showBanner() {
    Utility.addClass(document.body, 'appbanner');
    if (document.getElementById('appbanner')) {
      Utility.removeClass(document.getElementById('appbanner'), 'hidden');
    }
  }

  close() {
    Utility.removeClass(document.body, 'appbanner');
    Utility.addClass(document.getElementById('appbanner'), 'hidden');
    window.localStorage.setItem('smartBannerTime', moment().add(7, 'd').format());
  }

  render() {
    const { i18n } = this.context;

    return (
      <div className={`${s.banner} hidden`} id="appbanner">
        <button className={s.closeLink} onClick={this.close}>
          <i className="icon icon-close" />
        </button>
        <div className={s.touchIconWrap}>
          <img className={s.touchIcon} src={i18n.t('app.touchIcon')} />
        </div>
        <div className={s.infoWrap}>
          <div className={s.title}>{i18n.t('app.title')}</div>
          <div className={s.subtitle}>{i18n.t('app.subtitle')}</div>
          <div className={s.infoText}>{i18n.t('app.infoText')}</div>
        </div>
        <a href={i18n.t('app.android.url')} target="_blank" className={s.openButton}>
          {i18n.t('app.buttonText')}
        </a>
      </div>
    );
  }
}

export default withStyles(s)(AppBanner);
