/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './TabList.scss';
import TabItem from './TabItem/TabItem';
import * as Utility from '../../utils/utility';

const activeClass = 'active';

class TabList extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      slices: PropTypes.array,
    })).isRequired,
  };

  handleClick(e) {
    if (!Utility.hasClass(e.target, 'active')) {
      const activeTabValue = e.target.dataset.tab;
      const tabList = e.target.closest('.tabWrap');
      const activeElements = tabList.getElementsByClassName(activeClass);
      const activeElementsArray = Array.from(activeElements);

      activeElementsArray.forEach(element => {
        Utility.removeClass(element, activeClass);
      });

      const newActiveElements = tabList.querySelectorAll(`[data-tab='${activeTabValue}']`);

      [].forEach.call(newActiveElements, element => {
        Utility.addClass(element, activeClass);
      });
    }
  }

  render() {
    const { items } = this.props;

    return (
      <div className={`tabWrap ${s.tabList}`}>
        <div className={s.tabNav}>
          <div className={s.tabNavInner}>
            {items.map((item, index) => {
              if (index === 0) {
                return (
                  <button onClick={(e => this.handleClick(e))} key={index} data-tab={index} className={`tabButton ${s.tabButton} ${activeClass}`}>
                    {item.name}
                  </button>
                );
              }
              return (
                <button onClick={(e => this.handleClick(e))} key={index} data-tab={index} className={`tabButton ${s.tabButton}`}>
                  {item.name}
                </button>
              );
            })}
          </div>
        </div>
        {items.map((item, index) => (
          <TabItem name={item.name} slices={item.slices} key={index} index={index} />
        ))}
      </div>
    );
  }
}

export default withStyles(s)(TabList);
