import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import s from './LocaleSwitcher.scss';

class LocaleSwitcher extends React.Component {
  static contextTypes = {
    route: PropTypes.object,
    locale: PropTypes.string,
    locales: PropTypes.array,
    i18n: PropTypes.object,
  };

  static disableOption(locale, currentLocale, alternateLocales) {
    if (locale === currentLocale) {
      return false;
    }

    return !Object.hasOwnProperty.call(alternateLocales, locale);
  }

  state = {
    opened: false,
  };

  toggle = () => {
    this.setState({
      opened: !this.state.opened,
    });
  }

  /**
   * Forces a browser reload, if a different language is selected.
   * @param item
   */
  change(locale) {
    const { route: { alternateLocales } } = this.context;
    const alternate = alternateLocales[locale];
    const { protocol, port } = window.location;

    if (alternate) {
      const { hostname, path } = alternate;
      window.location = `${protocol}//${hostname}${port ? `:${port}` : ''}${path}`;
    }
  }

  render() {
    const { locales, i18n } = this.context;
    const { locale, route: { alternateLocales } } = this.context;
    const { opened } = this.state;

    return (
      <ButtonDropdown className={s.dropdown} isOpen={opened} toggle={this.toggle}>
        <DropdownToggle className={s.dropdownToggle} caret color="">
          {i18n.t(`locale.${locale}.languageCode`)}
        </DropdownToggle>
        <DropdownMenu className={`dropdown-menu-right ${s.dropdownMenu}`} right>
          {locales.map(loc => (
            <DropdownItem
              className={s.dropdownItem}
              onClick={() => this.change(loc)}
              key={loc}
              disabled={LocaleSwitcher.disableOption(loc, locale, alternateLocales)}
              active={locale === loc}
            >
              {i18n.t(`locale.${loc}.language`)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

export default withStyles(s)(LocaleSwitcher);
