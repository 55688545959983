/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from '../Link';
import s from './Top3.scss';
import CommunityBadge from '../CommunityBadge';
import TrendIcon from '../TrendIcon';
import { formatNumber } from '../../utils/utility';

function wrapLink(target, renderContent) {
  if (!target) {
    return renderContent();
  }

  return (
    <Link to={target} className={s.communityLink}>
      {renderContent()}
    </Link>
  );
}

class Top3 extends React.Component {
  static propTypes = {
    rank1: PropTypes.shape().isRequired,
    rank2: PropTypes.shape().isRequired,
    rank3: PropTypes.shape().isRequired,
    short: PropTypes.string.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  getCommunityInfo(community, i18n) {
    const minutes = formatNumber(community.currentTotalMinutes);

    return (
      <div className={s.communityInfoWrap}>
        <div className={s.communityNameWrap}>
          <div className={s.communityName}>
            {community.name}
          </div>
        </div>
        <div className={s.communityMinutes}>
          {minutes}
          {' '}
          {i18n.t('ranking.minutes')}
        </div>
        <div className={s.communityTrend}>
          <TrendIcon now={community.currentRank} previous={community.previousRank} />
        </div>
      </div>
    );
  }

  render() {
    const { rank1, rank2, rank3, short } = this.props;
    const { i18n } = this.context;

    return (
      <div className={s.wrap}>
        {rank2 && (
          <div className={`${s.outer} ${s.rank2}`}>
            <div className={s.badgeWrap}>
              {wrapLink(rank2.url, () => (
                <CommunityBadge micrositeLogoUrl={rank2.micrositeLogoUrl} rank={rank2.currentRank} short={short} />
              ))}
            </div>
            {wrapLink(rank2.url, () => this.getCommunityInfo(rank2, i18n))}
          </div>
        )}
        {rank1 && (
          <div className={`${s.inner} ${s.rank1}`}>
            <div className={s.badgeWrap}>
              {wrapLink(rank1.url, () => (
                <CommunityBadge micrositeLogoUrl={rank1.micrositeLogoUrl} size="big" rank={rank1.currentRank} short={short} />
              ))}
            </div>
            {wrapLink(rank1.url, () => this.getCommunityInfo(rank1, i18n))}
          </div>
        )}
        {rank3 && (
          <div className={`${s.outer} ${s.rank3}`}>
            <div className={s.badgeWrap}>
              {wrapLink(rank3.url, () => (
                <CommunityBadge micrositeLogoUrl={rank3.micrositeLogoUrl} rank={rank3.currentRank} short={short} />
              ))}
            </div>
            {wrapLink(rank3.url, () => this.getCommunityInfo(rank3, i18n))}
          </div>
        )}
      </div>

    );
  }
}

export default withStyles(s)(Top3);
