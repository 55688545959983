// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Gxun{margin:35px 0}._12ku7{display:block;padding:0 20px 20px;text-align:center}._12ku7,._12ku7:hover{color:#fff}@media(min-width:768px){._12ku7{display:inline-block;padding:0 20px;text-align:left}.last ._12ku7{padding-right:0}._12ku7:first-child{padding-left:0}}", ""]);
// Exports
exports.locals = {
	"root": "_2Gxun",
	"link": "_12ku7"
};
module.exports = exports;
