import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import App from './components/App';
import i18n from './i18n-client';
import createFetch from './utils/createFetch';
import history from './utils/history';
import { getInvariantPath, pushDataLayer, upperLocale } from './utils/utility';
import * as domUtils from './utils/DOMUtils';
import router from './router';
import Store from './utils/store';

// Grab the injected values from the server
const {
  apiUrl,
  defaultLocale,
  initialState,
  i18nResource,
  locale,
  locales,
  season,
} = window.App;

// Global (context) variables that can be easily accessed from any React component
i18n.addResourceBundle(locale, 'translation', i18nResource, true);
i18n.changeLanguage(locale);

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  // Universal HTTP client
  fetch: createFetch(fetch, {
    baseUrl: apiUrl,
    locale,
    defaultLocale,
  }),
  // Use initial state of server as store
  store: new Store(initialState),
  // Locale
  locale,
  defaultLocale,
  locales,
  i18n,
  season,
};

// Remove the initial state from the global namespace
delete window.App.initialState;

const container = document.getElementById('app');
let currentLocation = history.location;
let appInstance;

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  if (currentLocation.key) {
    scrollPositionsHistory[currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
    };
  }
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    const { hostname, pathname, protocol, search } = document.location;

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({
      ...context,
      pathname,
      query: queryString.parse(search),
      hostname,
      protocol: protocol.slice(0, -1), // remove : from http:
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    // Augment context with route information
    const appContext = {
      ...context,
      route: {
        id: route.id,
        uid: route.uid,
        path: route.path,
        title: route.title,
        alternateLocales: route.alternateLocales,
      },
    };

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
      <StyleContext.Provider value={{ insertCss: context.insertCss }}>
        <App context={appContext}>{route.component}</App>
      </StyleContext.Provider>,
      container,
      () => {
        if (isInitialRender) {
          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        const url = `${protocol}//${hostname}${pathname}`;

        // Update necessary tags in <head> at runtime here, ie:
        document.title = route.title;
        domUtils.updateLink('canonical', url);
        domUtils.updateMeta('description', route.description);
        domUtils.updateCustomMeta('og:title', route.title);
        domUtils.updateCustomMeta('og:description', route.description);
        domUtils.updateCustomMeta('og:url', url);
        domUtils.updateCustomMeta('og:image', route.ogImage.url);
        // updateMeta('keywords', route.keywords);
        // etc.

        domUtils.removeCustomMetaTag('og:locale:alternate');
        Object.keys(route.alternateLocales).forEach(altLocale => {
          domUtils.addCustomMetaTag('og:locale:alternate', upperLocale(altLocale, '-', '_'));
        });

        domUtils.removeLink('alternate');
        Object.entries(route.alternateLocales).forEach(([altLocale, altRoute]) => {
          domUtils.addLink('alternate', `${protocol}//${altRoute.hostname || hostname}${altRoute.path}`, altLocale);
        });

        let scrollX = 0;
        let scrollY = 0;
        let pos;

        if (location.key) {
          pos = scrollPositionsHistory[location.key];
        }

        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
          window.scrollTo(scrollX, scrollY);
        } else {
          const targetHash = location.hash.substr(1);
          if (!targetHash) {
            window.scrollTo(scrollX, scrollY);
          }
        }

        pushDataLayer({
          event: 'pageview',
          languageInvariantPath: getInvariantPath(defaultLocale, route),
        });
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
