// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kid4G{position:absolute;top:80px;right:0;left:0;text-align:center;z-index:900}@media(min-width:992px){.kid4G{top:130px;margin:0 auto}}", ""]);
// Exports
exports.locals = {
	"message": "kid4G"
};
module.exports = exports;
