// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2JOKl{position:relative;width:100%}.sBn7L{max-width:580px;margin:0 auto 40px;text-align:left}.sBn7L p{margin-bottom:24px}._3NwoO{position:relative;margin-bottom:70px;text-align:center}._3NwoO:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._173G_{margin:50px auto 0;text-align:center}._1ZRec{position:relative;border:0;background:rgba(0,0,0,0);color:#949eb2;font-family:Montserrat,sans-serif;font-size:14px;font-weight:600;text-transform:uppercase}._1ZRec:focus,._1ZRec:hover{outline:0;color:#727a8b;text-decoration:none;cursor:pointer}._1ZRec:after{position:absolute;padding-left:20px;font-family:Material-Design-Iconic-Font;content:\"\"}", ""]);
// Exports
exports.locals = {
	"container": "_2JOKl",
	"paragraph": "sBn7L",
	"title": "_3NwoO",
	"moreLinkWrap": "_173G_",
	"moreLink": "_1ZRec"
};
module.exports = exports;
