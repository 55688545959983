// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3cid2{position:relative;width:100%}@media(min-width:768px){._3cid2{width:580px;margin:0 auto}}", ""]);
// Exports
exports.locals = {
	"container": "_3cid2"
};
module.exports = exports;
