import React from 'react';
import history from '../utils/history';
import { isExternalUrl } from '../utils/utility';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function handleClick(event) {
  if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
    return;
  }
  if (isExternalUrl(this.href) || !this.pathname) {
    return;
  }

  if (event.defaultPrevented === true) {
    return;
  }

  event.preventDefault();
  history.push(this.pathname);
}

/**
 * A higher order component that listens to click events on hyperlinks.
 * This is necessary because certain links are injected without using the <Link>-Component
 * @param Component
 * @returns {Component}
 */
export function withInjectedLinks(Component) {
  return class extends React.Component {
    componentDidMount() {
      this.aTags = [].slice.call(document.querySelectorAll('a'));
      this.aTags.forEach(a => a.addEventListener('click', handleClick));
    }

    componentWillUnmount() {
      this.aTags.forEach(a => a.removeEventListener('click', handleClick));
      delete this.aTags;
    }

    render() {
      return <Component {...this.props} />;
    }
  };
}

export async function getPageContent(url, store, fetch, serverCall) {
  let content = store.get(url);

  if (!content) {
    if (process.env.BROWSER) {
      content = await fetch(url)
        .then(response => response.json());
    } else {
      // Gets the content within the server, without
      // doing a HTTP Request
      content = await serverCall();
    }

    store.set(url, content);
  }

  return content;
}
