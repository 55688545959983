// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3AMOX{max-width:580px;margin:0 auto}._38qQs{width:100%;max-width:1200px;margin:0 auto}._328cK{margin-bottom:40px}._1YRUy{position:relative;margin-bottom:60px}._1YRUy:last-child{margin-bottom:0}.EthJd{position:relative;margin-bottom:22px;text-align:center}.rwXHm{width:100%}._1izai{position:relative;margin-bottom:70px;font-size:30px;text-align:center}._1izai:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}.sQasc{padding-bottom:6px;color:#373a3c}._2uJWu{width:100%}@media(min-width:480px){.rwXHm{max-width:280px}}@media(min-width:992px){.sQasc{padding-bottom:50px}._1No3y{position:absolute}.EthJd{margin-bottom:0}.J292z{padding:0 6px}.rwXHm{width:280px}}", ""]);
// Exports
exports.locals = {
	"container": "_3AMOX",
	"imageList": "_38qQs",
	"description": "_328cK",
	"imageListItem": "_1YRUy",
	"imageWrap": "EthJd",
	"image": "rwXHm",
	"title": "_1izai",
	"text": "sQasc",
	"appIcon": "_2uJWu",
	"linkWrap": "_1No3y",
	"imageCol": "J292z"
};
module.exports = exports;
