import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './MainNavigation.scss';
import Link from '../Link';
import getActiveClass from '../../utils/navigation';

class MainNavigation extends React.Component {
  static contextTypes = {
    store: PropTypes.object,
    route: PropTypes.shape({
      id: PropTypes.string,
    }),
    type: PropTypes.string,
  };

  state = {
    items: this.context.store.get('main-navigation') || [],
  };

  render() {
    const { items } = this.state;
    const { route: { id } } = this.context;

    return (
      <ul className={`nav ${s.mainNavigation}`}>
        {items.map(item => (
          <li className={`nav-item ${s.navItem}`} key={item.id}>
            <Link
              className={`nav-link ${s.navLink} ${getActiveClass(id, item.id)}`}
              key={item.id}
              to={item.redirect || item.route}
              target={item.redirect ? '_blank' : null}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default withStyles(s)(MainNavigation);
