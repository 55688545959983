import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Default.scss';
import CircleButton from '../../components/CircleButton';
import ComponentType from '../../components/ComponentType';
import PageHeader from '../../components/PageHeader';
import getTeaserOrientation from '../../utils/teaserOrientation';
import { withInjectedLinks } from '../helper';

class Default extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      navigation_name: PropTypes.string,
      pageTitle: PropTypes.string,
      slices: PropTypes.array,
      footerTeasers: PropTypes.array,
      season: PropTypes.shape({
        micrositesActive: PropTypes.bool,
      }),
    }).isRequired,
  };

  render() {
    const { content } = this.props;
    let circleButton = null;

    let footerTeaserOrientationStart = 'left';
    let footerTeaser = '';

    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton className={s.circleButton} label={contest.text} link={contest.link} />;
    }

    return (
      <div>
        <PageHeader
          title={content.pageTitle}
          subheadline={content.subheadline}
          image={content.pageHeader}
        />
        {circleButton}
        {content.slices.map((slice, index) => (
          <ComponentType key={index} data={slice} />
        ))}
        {content.footerTeasers[0] && (
          <div className="footerTeasers">
            {content.footerTeasers.map(teaser => {
              teaser.orientation = footerTeaserOrientationStart;
              footerTeaser = <ComponentType key={teaser.id} additionalClasses="" data={teaser} />;
              footerTeaserOrientationStart = getTeaserOrientation(footerTeaserOrientationStart);
              return footerTeaser;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(withInjectedLinks(Default));
