// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".b79AO{display:block;position:relative;width:110px;height:150px}._1gjoK.b79AO,._38XMf.b79AO{width:100px;height:137px}._1gjoK .iUTd5,._38XMf .iUTd5{top:35px;right:20px;bottom:12px;left:20px}.k6H1p{position:absolute;width:100%;color:#fff;font-family:Montserrat,sans-serif;font-weight:700;line-height:.875rem;text-align:center;z-index:1000}._1-Fbz{display:block;font-size:.5625rem;text-transform:uppercase}._1qvwu{position:relative;font-size:1.125rem}._1qvwu:after{position:absolute;bottom:.2em;content:\".\"}.iUTd5{position:absolute;top:45px;right:15px;bottom:30px;left:15px;background-repeat:no-repeat;background-position:50%;background-size:contain;z-index:100}._1LCKZ{position:relative;z-index:200}._1gIYG{position:absolute;top:35px;right:10px;bottom:12px;left:10px;border-radius:0 0 73px 73px;background:#fff;z-index:50}@media(min-width:992px){._1gIYG{top:35px;right:13px;bottom:12px;left:13px}.b79AO{width:200px;height:274px;margin:auto}._1-Fbz{margin-top:6px;font-size:1.1875rem;line-height:1.5rem}._1qvwu{font-size:2.375rem;line-height:2.1875rem}.iUTd5{position:absolute;top:50px;right:45px;bottom:30px;left:45px}._38XMf.b79AO{width:115px;height:157px}._38XMf .iUTd5{top:35px;right:25px;bottom:12px;left:25px}._38XMf ._1gIYG{right:10px;left:10px}._38XMf ._1-Fbz{margin-top:4px;font-size:.6875rem;line-height:.8125rem}._38XMf ._1qvwu{font-size:1.375rem;line-height:1.25rem}._1gjoK.b79AO{width:170px;height:233px}._1gjoK .iUTd5{top:35px;right:37px;bottom:12px;left:37px}._1gjoK ._1-Fbz{margin-top:4px;font-size:1rem;line-height:1.25rem}._1gjoK ._1qvwu{font-size:2.0625rem;line-height:1.75rem}}", ""]);
// Exports
exports.locals = {
	"badgeContainer": "b79AO",
	"big": "_1gjoK",
	"medium": "_38XMf",
	"communityBadge": "iUTd5",
	"rankWrap": "k6H1p",
	"rankLabel": "_1-Fbz",
	"rank": "_1qvwu",
	"badge": "_1LCKZ",
	"badgeBackground": "_1gIYG"
};
module.exports = exports;
