export function addTag(tagName, keyName, keyValue, attrName, attrValue, attrName2, attrValue2) {
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);

    if (attrName2 && attrValue2) {
      nextNode.setAttribute(attrName2, attrValue2);
    }

    document.head.appendChild(nextNode);
  }
}

export function updateTag(tagName, keyName, keyValue, attrName, attrValue) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    node.parentNode.removeChild(node);
  }

  addTag(tagName, keyName, keyValue, attrName, attrValue);
}

export function removeTag(tagName, keyName, keyValue) {
  const nodes = document.head.querySelectorAll(
    `${tagName}[${keyName}="${keyValue}"]`,
  );

  [].forEach.call(nodes, node => {
    if (node) {
      node.parentNode.removeChild(node);
    }
  });
}

export function updateMeta(name, content) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property, content) {
  updateTag('meta', 'property', property, 'content', content);
}

export function addCustomMetaTag(property, content) {
  addTag('meta', 'property', property, 'content', content);
}

export function removeCustomMetaTag(property) {
  removeTag('meta', 'property', property);
}

export function updateLink(rel, href) {
  updateTag('link', 'rel', rel, 'href', href);
}

export function addLink(rel, href, hrefLang) {
  addTag('link', 'rel', rel, 'href', href, 'hrefLang', hrefLang);
}

export function removeLink(rel) {
  removeTag('link', 'rel', rel);
}
