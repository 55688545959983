import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CommunitiesTeaser.scss';
import CommunitiesMap from '../CommunitiesMap';
import Link from '../Link';

class CommunitiesTeaser extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
      linkLabel: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
      }),
      season: PropTypes.shape({
        startDateTime: PropTypes.string,
        endDateTime: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  static contextTypes = {
    fetch: PropTypes.func,
    i18n: PropTypes.object,
  };

  isSeasonRunning() {
    const { data: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);
    const end = +new Date(season.endDateTime);

    return now >= start && now <= end;
  }

  hasSeasonStarted() {
    const { data: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);

    return now >= start;
  }

  render() {
    const { data: { title, linkLabel, link } } = this.props;
    const showCounter = this.hasSeasonStarted();

    return (
      <div>
        {title && <h3 className={s.title}>{title}</h3>}
        <CommunitiesMap showCounter={showCounter} />
        <div className={s.moreLinkWrap}>
          {linkLabel && (
            <Link className={s.moreLink} target={link.target} to={link.url}>
              {linkLabel}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CommunitiesTeaser);
