import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CircleButton.scss';
import Link from '../Link';

const SPLITTER = '|';

class CircleButton extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  renderButton() {
    const { label } = this.props;
    const parts = label.split(SPLITTER);

    return (
      <div className={s.button}>
        <span className={s.label}>
          {parts.map((part, i) => (
            <span key={i} className={s.part}>{part}</span>
          ))}
        </span>
        <i className="icon icon-chevron-right" />
      </div>
    );
  }

  render() {
    const { link, className } = this.props;
    const button = this.renderButton();

    if (!link || !link.url) {
      return null;
    }

    return (
      <div className={`${s.container} ${className}`}>
        <Link to={link.url} target={link.target}>{button}</Link>
      </div>
    );
  }
}

export default withStyles(s)(CircleButton);
