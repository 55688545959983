export class Store {
  constructor(initial = {}) {
    this.internalMap = new Map();

    // Fills the internal map
    Object.keys(initial).forEach(key => this.internalMap.set(key, initial[key]));
  }

  clear() {
    return this.internalMap.clear();
  }

  delete(key) {
    return this.internalMap.delete(key);
  }

  export() {
    const map = {};
    this.internalMap.forEach((value, key) => {
      map[key] = value;
    });

    return map;
  }

  get(key) {
    return this.internalMap.get(key);
  }

  has(key) {
    return this.internalMap.has(key);
  }

  keys() {
    return this.internalMap.keys();
  }

  set(key, value) {
    return this.internalMap.set(key, value);
  }

  get size() {
    return this.internalMap.size;
  }
}

export default Store;

/**
 * Gets the navigations and puts the into the store
 *
 * @param store
 * @param prismicApi
 * @returns {Promise<void>}
 */
export async function createNavigationStore(store, prismicApi) {
  let items;

  if (!store.has('main-navigation')) {
    items = await prismicApi.getNavigation('main-navigation');
    store.set('main-navigation', items);
  }
  if (!store.has('marginal-navigation')) {
    items = await prismicApi.getNavigation('marginal-navigation');
    store.set('marginal-navigation', items);
  }
  if (!store.has('footer-navigation')) {
    items = await prismicApi.getNavigation('footer-navigation');
    store.set('footer-navigation', items);
  }
}

/**
 * Gets the navigations and puts the into the store
 *
 * @param store
 * @param prismicApi
 * @returns {Promise<void>}
 */
export async function createRoutesStore(store, prismicApi) {
  if (!store.has('routes')) {
    // Lazy load the routes
    const routes = prismicApi.getRoutes();
    store.set('routes', routes);
  }
}
