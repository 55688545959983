// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bZ4o9{margin:-20px 0 26px;font-family:Montserrat,sans-serif;font-size:1.375rem;text-align:center;text-transform:uppercase}._20U3W{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 -15px -60px;padding:50px 15px 110px;background-color:#f5f7fa}.Pgn3V{width:100%}.TdN6i{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:0;cursor:pointer}.TdN6i input{position:absolute;left:-999em;width:0;height:0;overflow:hidden}._27DE2{display:inline-block;position:relative;width:1.5em;height:1.5em;margin-right:.625em;border:1px solid #ccc;background-color:#fff}input:checked+._27DE2:after{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);color:#449d44;content:\"✔\"}._2EDfT{margin-bottom:52px;color:#949eb2;text-align:center}@media(min-width:768px){.bZ4o9{margin:8px 0 26px}._20U3W{padding:50px 100px 110px}}", ""]);
// Exports
exports.locals = {
	"categoryDescription": "bZ4o9",
	"listFilter": "_20U3W",
	"listFilterContainer": "Pgn3V",
	"showPassive": "TdN6i",
	"checkbox": "_27DE2",
	"prizes": "_2EDfT"
};
module.exports = exports;
