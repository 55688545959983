// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._23bwZ{margin:50px auto 0;text-align:center}._3inxt{position:relative;border:0;background:rgba(0,0,0,0);color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase}._3inxt:focus,._3inxt:hover{outline:0;color:#727a8b;text-decoration:none;cursor:pointer}._3inxt:after{position:absolute;padding-left:20px;font-family:Material-Design-Iconic-Font;content:\"\"}.IFF_p{display:none;width:42px;height:50px;background-repeat:no-repeat;background-position:50%;background-size:contain}.IFF_p:before{display:inline-block;height:100%;content:\"\";vertical-align:middle}._255aA{word-break:break-all}.g-328,._255aA{color:#373a3c}._1PLxi{margin:-15px}._26bv8{width:100%}._26bv8 tr{vertical-align:middle}._26bv8 td:first-child{padding-left:15px}._26bv8 td:last-child{padding-right:15px}._26bv8 thead{color:rgba(0,0,0,.38);font-size:1rem}._26bv8 thead td{padding:0 9px 33px}._26bv8 thead td:first-child{width:20%}._26bv8 thead td:last-child{width:40%}._26bv8 tbody{color:#373a3c}._26bv8 tbody tr:nth-child(2n){background:rgba(245,247,250,.4)}._26bv8 tbody tr:nth-child(odd){background:#f5f7fa}._26bv8 tbody td{padding:11px 9px}._11xjz{display:block;white-space:nowrap}._2rL1-,._6ZD2g,._2a1dP{font-size:.875rem;text-transform:uppercase}._6ZD2g{font-size:.8125rem}.A8Jkb{padding-left:12px;font-size:1.375rem}._364lk{display:block}._364lk:hover{text-decoration:none}._2hCSW{display:none}._3_Dly .g-328,._3_Dly ._255aA{opacity:.5}._3_Dly ._2T3cX{opacity:0}@media(min-width:768px){._255aA{padding-left:36px}._255aA:first-child{padding-left:0}._2hCSW{display:inline}.IFF_p{display:inline-block}.g-328,._255aA{color:#000}.A8Jkb{padding-left:33px}.A8Jkb:first-child{padding-left:0}._2rL1-,._6ZD2g,._2a1dP{font-family:Montserrat,sans-serif;font-size:1.125rem}._2a1dP{font-size:1.375rem}._26bv8 tbody{color:#000}._26bv8 td:first-child{padding-left:100px}._26bv8 td:last-child{padding-right:124px}}", ""]);
// Exports
exports.locals = {
	"moreLinkWrap": "_23bwZ",
	"moreLink": "_3inxt",
	"communityBadge": "IFF_p",
	"name": "_255aA",
	"minutesWrap": "g-328",
	"tableContainer": "_1PLxi",
	"table": "_26bv8",
	"positionWrap": "_11xjz",
	"cellItem": "_2rL1-",
	"cellMinutes": "_6ZD2g",
	"cellPosition": "_2a1dP",
	"rank": "A8Jkb",
	"communityLink": "_364lk",
	"minutesDescription": "_2hCSW",
	"passiveCommunity": "_3_Dly",
	"trendIcon": "_2T3cX"
};
module.exports = exports;
