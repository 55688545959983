// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qbuQ{display:-webkit-box;display:-ms-flexbox;display:flex;position:fixed;top:0;width:100%;height:70px;padding:5px 20px;background:#fff;-webkit-box-shadow:0 2px 10px 0 rgba(0,0,0,.47);box-shadow:0 2px 10px 0 rgba(0,0,0,.47);z-index:9000}._2qbuQ.hidden{display:none}._2Dv8Q{margin:0 10px}._2NT9n{width:60px;height:60px}._3As2r{margin:0;padding:0;border:0;background:rgba(0,0,0,0);color:#373737;font-size:30px;cursor:pointer}._3As2r:focus{outline:none}._2kFGY{padding-top:5px;font-family:Lato,sans-serif;line-height:16px}._25BB9{font-size:.85rem;font-weight:700}._1Is_T,.AGki2{font-size:.75rem;font-weight:700}.g-T5T{margin-top:18px;margin-left:auto;border:0;background:rgba(0,0,0,0);color:#ea5b0c;font-weight:700;text-transform:uppercase;cursor:pointer}.g-T5T:focus{outline:none}.g-T5T:hover{color:#ea5b0c;text-decoration:none}", ""]);
// Exports
exports.locals = {
	"banner": "_2qbuQ",
	"touchIconWrap": "_2Dv8Q",
	"touchIcon": "_2NT9n",
	"closeLink": "_3As2r",
	"infoWrap": "_2kFGY",
	"title": "_25BB9",
	"infoText": "_1Is_T",
	"subtitle": "AGki2",
	"openButton": "g-T5T"
};
module.exports = exports;
