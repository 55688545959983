import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Footer.scss';
import Link from '../Link';
import Navigation from '../Navigation';
import BackToTop from '../BackToTop';
import NewsletterForm from '../NewsletterForm';
import PrivacyBanner from '../PrivacyBanner';

class Footer extends React.Component {
  static contextTypes = {
    i18n: PropTypes.object,
  };

  static propTypes = {
    simplified: PropTypes.bool,
  };

  static defaultProps = {
    simplified: false,
  };

  render() {
    const { i18n } = this.context;
    const { simplified } = this.props;

    return (
      <div className={s.footer}>
        {/* <PrivacyBanner title={i18n.t('privacybanner.title')} text={i18n.t('privacybanner.text')} buttonText={i18n.t('privacybanner.buttonText')} linkText={i18n.t('privacybanner.linkText')} linkUrl=""/> */}
        {!simplified && (
          <div className={s.container}>
            <BackToTop scrollStepInPx="100" delayInMs="16.66" />
            <div className="row">
              <div className="col-md-4 footer-col">
                <h4>{i18n.t('footer.newsletterTitle')}</h4>
                <NewsletterForm />
              </div>
              <div className="col-md-5 footer-col text-center">
                <h4>{i18n.t('footer.socialTitle')}</h4>
                <div className={s.socialIconContainer}>
                  <a target="_blank" href={i18n.t('social.facebookUrl')} className={s.socialIconWrap}>
                    <i className={`icon icon-facebook-box ${s.socialIcon}`} />
                  </a>
                  <a target="_blank" href={i18n.t('social.instagramUrl')} className={s.socialIconWrap}>
                    <i className={`icon icon-instagram ${s.socialIcon}`} />
                  </a>
                </div>
              </div>
              <div className="col-md-3 footer-col">
                <h4>{i18n.t('footer.contactTitle')}</h4>
                <p>
                  {i18n.t('contact.companyName')}
                  <br />
                  {i18n.t('contact.address')}
                  <br />
                  {i18n.t('contact.city')}
                </p>
                <p>
                  <a target="_blank" href={`mailto:${i18n.t('contact.email')}`}>{i18n.t('contact.email')}</a>
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={s.footerNav}>
          <div className={s.footerNavContainer}>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <a className={s.brand} href={i18n.t('footer.websiteUrl')} target="_blank">
                  <span className={s.brandTxt}>{i18n.t('footer.brandText')}</span>
                  <img
                    src={i18n.t('footer.logoUrl')}
                    className={s.logo}
                    width="205"
                    alt={i18n.t('footer.logoAlt')}
                  />
                </a>
              </div>
              {!simplified && (
                <>
                  <div className="col-lg-6 col-md-8">
                    <Navigation id="marginal-navigation" classes="marvinal-nav" />
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <Navigation id="footer-navigation" classes="footer-nav last" />
                  </div>
                  <div className="col-md-12 d-md-block d-sm-block d-xs-block d-lg-none">
                    <Link className={s.brand} to="/">
                      <span className={s.brandTxt}>{i18n.t('footer.project')}</span>
                      <img
                        src={i18n.t('footer.logoUrl')}
                        className={s.logo}
                        width="205"
                        alt={i18n.t('footer.logoAlt')}
                      />
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
