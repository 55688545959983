import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SponsorTeaser.scss';
import Link from '../Link';

class SponsorTeaser extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
      lead: PropTypes.string,
      linkLabel: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
      }),
      sponsors: PropTypes.array,
    }).isRequired,
  };

  render() {
    const { data: { title, sponsors, linkLabel, link } } = this.props;

    if (!sponsors || sponsors.length < 2) {
      return <div />;
    }

    return (
      <div className={`teaser ${s.sponsorTeaser}`}>
        {title && <h3>{title}</h3>}
        <div className={s.sponsorTeaserWrap}>
          {sponsors[1].sponsors.map((item, index) => (
            <div className={s.item} key={index}>
              <Link className={s.link} target={item.url.target} to={item.url.url}>
                <img className={s.image} src={item.image.url} alt={item.name} />
              </Link>
            </div>
          ))}
        </div>
        <div className={s.moreLinkWrap}>
          {linkLabel && (
            <Link className={s.moreLink} target={link.target} to={link.url}>
              {linkLabel}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SponsorTeaser);
