import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import s from './EventList.scss';
import EventItem from './EventItem/EventItem';
import EventItemToday from './EventItemToday';
import EventItemFuture from './EventItemFuture';

class EventList extends React.Component {
  static propTypes = {
    groupedEvents: PropTypes.shape().isRequired,
    nextHighlight: PropTypes.shape(),
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  static defaultProps = {
    nextHighlight: null,
  };

  constructor(props, context) {
    super(props, context);

    const { i18n } = this.context;

    moment.locale(i18n.t('moment.locale'));
  }

  getDateTitle(timestamp, i18n) {
    const today = moment().endOf('day');
    const tomorrow = moment().add(1, 'day').endOf('day');
    const date = moment(timestamp, 'X');
    if (date.isSameOrBefore(today)) {
      return i18n.t('community.today');
    }
    if (date.isAfter(today)) {
      return i18n.t('community.upcomingEvents');
    }
    return moment.tz(timestamp, i18n.t('moment.timezone')).format(i18n.t('moment.dateFormat'));
  }

  isFuture(timestamp) {
    const day = moment.unix(timestamp);
    const today = moment().endOf('day');

    if (!today.isSame(day, 'day')) {
      return true;
    }
    return false;
  }

  render() {
    const { i18n } = this.context;
    const { groupedEvents, nextHighlight } = this.props;

    return (
      <div className={s.container}>
        {nextHighlight && nextHighlight.id && (
          <div>
            <EventItem event={nextHighlight} title={i18n.t('community.nextHighlight')} />
          </div>
        )}
        {Object.keys(groupedEvents).map((timestamp, index) => (
          <div key={index}>
            <h3 className={s.title}>{this.getDateTitle(timestamp, i18n)}</h3>
            {this.isFuture(timestamp) && (
              <div className={s.table}>
                {groupedEvents[timestamp].map(event => (
                  <EventItemFuture key={event.id} event={event} />
                ))}
              </div>
            )}
            {!this.isFuture(timestamp) && (
              <div>
                {groupedEvents[timestamp].map(event => (
                  <EventItemToday key={event.id} event={event} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(s)(EventList);
