import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './MobileNavigation.scss';
import Link from '../Link';
import getActiveClass from '../../utils/navigation';

class MobileNavigation extends React.Component {
  static contextTypes = {
    store: PropTypes.object,
    route: PropTypes.shape({
      id: PropTypes.string,
    }),
    onClick: PropTypes.func,
    type: PropTypes.string,
  };

  static propTypes = {
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
  };

  state = {
    items: this.context.store.get('main-navigation') || [],
  };

  handleClick(item) {
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  }

  render() {
    const { items } = this.state;
    const { route: { id } } = this.context;

    return (
      <ul className={`nav ${s.mobileNavigation}`}>
        {items.map(item => (
          <li className={`nav-item ${s.navItem}`} key={item.id}>
            <Link
              className={`nav-link ${s.navLink} ${getActiveClass(id, item.id)}`}
              key={item.id}
              to={item.redirect || item.route}
              target={item.redirect ? '_blank' : null}
              onClick={() => this.handleClick(item)}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default withStyles(s)(MobileNavigation);
