// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._39LB7{display:block;padding:2rem 1.625rem .6875rem}._2_1DS{font-size:.875rem}.Dnpn3{padding:12px 17px;color:#949eb2;font-family:Montserrat,sans-serif;font-weight:600;text-transform:uppercase}.Dnpn3.active,.Dnpn3:hover{position:relative;color:#ea5b0c}.Dnpn3.active:before,.Dnpn3:hover:before{position:absolute;top:0;bottom:0;left:0;width:2px;height:24px;margin:auto 0;padding:0;background:#ea5b0c;content:\"\"}", ""]);
// Exports
exports.locals = {
	"mobileNavigation": "_39LB7",
	"navItem": "_2_1DS",
	"navLink": "Dnpn3"
};
module.exports = exports;
