import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EngageTeaser.scss';
import Link from '../Link';
import FlipClock from '../FlipClock';

class EngageTeaser extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    linkLabel: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }),
  };

  static defaultProps = {
    linkLabel: null,
    link: null,
  };

  render() {
    const { title, toDate, linkLabel, link } = this.props;

    return (
      <div>
        <h3 className={s.countdownTitle}>{title}</h3>
        <div className={s.countdownContainer}>
          <FlipClock
            id="engage-teaser-counter"
            type="countdown"
            toDate={toDate}
          />
        </div>
        <div className={s.moreLinkWrap}>
          {linkLabel && link.url && (
            <Link className={s.moreLink} target={link.target} to={link.url}>
              {linkLabel}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(EngageTeaser);
