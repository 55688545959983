import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Ranking.scss';
import CircleButton from '../../components/CircleButton';
import ComponentType from '../../components/ComponentType';
import PageHeader from '../../components/PageHeader';
import RankList from '../../components/RankingList';
import getTeaserOrientation from '../../utils/teaserOrientation';
import { withInjectedLinks } from '../helper';
import * as Utility from '../../utils/utility';
import 'url-polyfill';

const activeClass = 'active';

class Ranking extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      navigation_name: PropTypes.string,
      pageTitle: PropTypes.string,
      footerTeasers: PropTypes.array,
      rankings: PropTypes.array,
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  componentDidMount() {
    this.onHashChange = () => this.changeTabUsingHash();
    window.addEventListener('hashchange', this.onHashChange);

    if (!this.changeTabUsingHash()) {
      // No hash was used to activate tab, use first tab
      const { content } = this.props;
      const { rankings } = content;

      if (rankings.length) {
        window.location.hash = rankings[0].short;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
    this.onHashChange = null;
  }

  changeTab(activeTabValue) {
    const { tabList } = this;
    const activeElements = tabList.getElementsByClassName(activeClass);
    const activeElementsArray = Array.from(activeElements);
    const newActiveElements = tabList.querySelectorAll(`[data-tab='${activeTabValue}']`);

    if (newActiveElements && newActiveElements.length) {
      activeElementsArray.forEach(element => {
        Utility.removeClass(element, activeClass);
      });

      [].forEach.call(newActiveElements, element => {
        Utility.addClass(element, activeClass);
      });
    }
  }

  changeTabUsingHash() {
    const { hash } = window.location;
    if (hash) {
      const parts = hash.split('#');

      if (parts[1]) {
        // Removes the # and extracts the key
        this.changeTab(parts[1]);
        return true;
      }
    }
    return false;
  }

  handleClick(e) {
    if (!Utility.hasClass(e.target, 'active')) {
      const activeTabValue = e.target.dataset.tab;

      if (activeTabValue) {
        window.location.hash = activeTabValue;
      }
    }
  }

  render() {
    const { content } = this.props;
    const { rankings } = content;
    const { i18n } = this.context;

    let circleButton = null;
    let footerTeaserOrientationStart = 'left';
    let footerTeaser = '';

    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton label={contest.text} link={contest.link} />;
    }

    return (
      <div>
        <PageHeader
          title={content.pageTitle}
          subheadline={content.subheadline}
          image={content.pageHeader}
        />
        {circleButton}
        <div className="componentContainer componentContainerWhite tabContainer">
          <div className={`tabWrap ${s.tabList}`} ref={el => { this.tabList = el; }}>
            <div className={s.tabNav}>
              <div className={s.tabNavInner}>
                {rankings.map(group => (
                  <button
                    onClick={(e => this.handleClick(e))}
                    key={group.short}
                    data-tab={group.short}
                    className={`tabButton ${s.tabButton}`}
                  >
                    {i18n.t(`ranking.category-${group.id}`)}
                  </button>
                ))}
              </div>
            </div>
            {rankings.map(group => (
              <div
                className={`tabContent ${s.tabContent}`}
                data-tab={group.short}
                key={group.short}
              >
                <div className={s.tabContentContainer}>
                  <RankList
                    group={{
                      id: group.id,
                      name: group.name,
                      short: group.short,
                      subtitle: i18n.t(`ranking.category-${group.id}-subtitle`),
                    }}
                    rest={[...group.top3, ...group.rest]}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {content.footerTeasers[0] && (
          <div className="footerTeasers">
            {content.footerTeasers.map(teaser => {
              teaser.orientation = footerTeaserOrientationStart;
              footerTeaser = <ComponentType key={teaser.id} additionalClasses="" data={teaser} />;
              footerTeaserOrientationStart = getTeaserOrientation(footerTeaserOrientationStart);
              return footerTeaser;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(withInjectedLinks(Ranking));
