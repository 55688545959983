import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './RegionalSponsors.scss';
import Link from '../Link';

class RegionalSponsors extends React.Component {
  static propTypes = {
    sponsors: PropTypes.array.isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  render() {
    const { i18n } = this.context;
    const { sponsors } = this.props;

    return (
      <div className={`teaser ${s.sponsorTeaser}`}>
        <h3>{i18n.t('community.regionalSponsorTitle')}</h3>
        <div className={`row ${s.sponsorTeaserWrap}`}>
          {sponsors.map((item, index) => (
            <div className="col-md-4" key={index}>
              <div className={s.item}>
                <Link className={s.link} target="_blank" to={item.websiteUrl}>
                  <img className={s.image} src={item.logoUrl} alt={item.name} />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(RegionalSponsors);
