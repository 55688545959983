import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Carousel, { Modal, ModalGateway } from 'react-images';
import s from './ImageGallery.scss';

/* eslint-disable react/no-danger */

class ImageGallery extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      caption: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }).isRequired,
    })).isRequired,
  };

  constructor() {
    super();

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  render() {
    const { items } = this.props;

    const images = [];

    [].forEach.call(items, item => {
      const image = {
        src: item.image.url,
        caption: item.caption,
      };
      images.push(image);
    });

    return (
      <div>
        <div className={`row ${s.imageGalleryRow}`}>
          {images.map((image, i) => (
            <div className={`col-6 col-sm-6 col-md-3 ${s.imageWrap}`} key={i}>
              <a href={image.src} onClick={e => this.openLightbox(i, e)}>
                <div className={s.image} style={{ backgroundImage: `url(${image.src})` }} />
              </a>
            </div>
          ))}
        </div>
        <ModalGateway>
          {this.state.lightboxIsOpen && (
            <Modal onClose={this.closeLightbox} closeOnBackdropClick>
              <Carousel views={images} currentIndex={this.state.currentImage} />
            </Modal>
          )}
        </ModalGateway>
      </div>
    );
  }
}

export default withStyles(s)(ImageGallery);
