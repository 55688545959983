import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import s from './NewsItem.scss';
import { createUrlsInString } from '../../../utils/utility';

class NewsItem extends React.Component {
  static propTypes = {
    news: PropTypes.shape({

    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    const { i18n } = this.context;

    moment.locale(i18n.t('moment.locale'));
  }

  render() {
    const { i18n } = this.context;
    const { news } = this.props;

    return (
      <div className={s.news}>
        {news.headerImageUrl && <div className={s.imageWrap} style={{ backgroundImage: `url(${news.headerImageUrl})` }} />}
        <div className={s.textWrap}>
          <div className={s.date}>
            {moment.tz(news.publishDateTime, i18n.t('moment.timezone')).format(i18n.t('moment.dateFull'))}
            &nbsp;
            {i18n.t('moment.hourShort')}
          </div>
          <h4 className={s.headline}>
            {news.headline}
          </h4>
          <div className={s.content} dangerouslySetInnerHTML={{ __html: createUrlsInString(news.content) }} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(NewsItem);
