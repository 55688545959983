// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2eXNE{max-width:580px;margin:0 auto;text-align:left}._2eXNE p{margin-bottom:24px}._2eXNE a{color:#ea5b0c;font-weight:700}._2eXNE h1,._2eXNE h2,._2eXNE h3,._2eXNE h4{position:relative;margin-top:60px;margin-bottom:70px;text-align:center}._2eXNE h1:after,._2eXNE h2:after,._2eXNE h3:after,._2eXNE h4:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}", ""]);
// Exports
exports.locals = {
	"paragraph": "_2eXNE"
};
module.exports = exports;
