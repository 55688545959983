import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable no-underscore-dangle */

class Flockler extends React.Component {
  static propTypes = {
    siteId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    const { siteId } = this.props;
    window._flockler = window._flockler || [];

    window._flockler.push({
      count: 20,
      refresh: 0,
      site: siteId,
      style: 'wall',
    });

    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://embed-cdn.flockler.com/embed-v2.js');
    document.body.appendChild(script);
    this.scriptTag = script;
  }

  componentWillUnmount() {
    // Reset
    window._flocklerInit = false;
    window._flockler = [];

    if (this.scriptTag) {
      this.scriptTag.parentNode.removeChild(this.scriptTag);
      this.scriptTag = null;
    }
  }

  render() {
    return (
      <div>
        <div id="flockler_container" />
      </div>
    );
  }
}

export default Flockler;
