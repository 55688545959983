import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';
import s from './Event.scss';
import PageHeaderMicrosite from '../../components/PageHeaderMicrosite';
import EventDetailView from '../../components/Event';
import Link from '../../components/Link';
import AppGuide from '../../components/AppGuide';
import EventRegistration from '../../components/EventRegistration';
import EventUnsubscribe from '../../components/EventUnsubscribe';
import CircleButton from '../../components/CircleButton';

const activeClass = 'active';

class Event extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
    }).isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  hasSeasonStarted() {
    const { content: { season } } = this.props;
    const now = +new Date();
    const start = +new Date(season.startDateTime);
    return now >= start;
  }

  render() {
    const { i18n } = this.context;
    const { content } = this.props;
    const now = moment();
    const seasonStarted = this.hasSeasonStarted();
    const rank = seasonStarted ? content.rank : null;
    let eventStart;
    let registrationEnd;

    if (content.event.registrationEndDate) {
      eventStart = moment(content.event.startDate);
      registrationEnd = moment(content.event.registrationEndDate);
    }

    let circleButton = null;
    if (content.contest) {
      const { contest } = content;
      circleButton = <CircleButton className={s.circleButton} label={contest.text} link={contest.link} />;
    }

    const selfScanningId = 3;

    return (
      <div>
        <EventUnsubscribe />
        <PageHeaderMicrosite
          name={content.name}
          short={content.group.short}
          rank={rank}
          micrositeLogoUrl={content.micrositeLogoUrl}
          micrositeBackgroundImageUrl={content.micrositeBackgroundImageUrl}
        />
        {circleButton}
        <div className="componentContainer componentContainerWhite">
          <div className="contentContainer">
            <Link to={`${content.url}#events`} className={s.backLink}>
              <i className="icon icon-arrow-left" />
              {' '}
              {i18n.t('community.backToOverview')}
            </Link>
            <EventDetailView event={content.event} />
          </div>
        </div>
        {content.event.typeId === selfScanningId && <AppGuide />}
        {(content.event.typeId !== selfScanningId) && content.event.registrationRequired && (
          <div>
            {!(now.isAfter(eventStart) || now.isAfter(registrationEnd)) && (
              <EventRegistration eventData={content.event} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(Event);
