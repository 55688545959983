// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._361DO{max-width:1200px;padding:55px 15px 50px}._361DO h1,._361DO h2,._361DO h3,._361DO h4{position:relative;margin-bottom:60px;text-align:center}._361DO h1:after,._361DO h2:after,._361DO h3:after,._361DO h4:after{position:absolute;right:0;bottom:-27px;left:0;width:90px;height:1px;margin:0 auto;background:rgba(234,91,12,.7);content:\"\"}._361DO p{max-width:580px;margin:0 auto 60px;text-align:left}@media(min-width:768px){._3m5qK +.tabContainer{padding-top:40px}}", ""]);
// Exports
exports.locals = {
	"container": "_361DO",
	"circleButton": "_3m5qK"
};
module.exports = exports;
