// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16Ond{padding:20px;font-family:Montserrat,sans-serif;font-weight:600;text-align:center;text-transform:uppercase}.f0gJ3{width:130px;color:#ea5b0c;font-size:16px;font-weight:600;word-break:break-all}._3o-ZT{color:#949eb2;font-size:14px}._2BTCg,.dJn5M{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px 0}._3fjKf{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}._2wHeZ{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}._2_qFP{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}._2-5Uj{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding:20px 0}._2PtqG{display:block;width:110px;margin:0 auto}@media(min-width:992px){._2-5Uj{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;padding:0}._3fjKf{position:relative;top:-55px;margin-bottom:-30px;-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}._3fjKf ._2PtqG{width:240px}._2wHeZ{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}._2BTCg,.dJn5M{padding:0}.dJn5M{display:block}.dJn5M:first-child{margin-right:158px}.dJn5M:last-child{margin-left:158px}._2BTCg{display:block}._16Ond{padding:0}.f0gJ3{display:table-cell;width:180px;height:48px;font-size:14px;text-align:center;vertical-align:middle}._3o-ZT{margin-bottom:11px}._2OLVu{display:table;width:100%;margin-top:12px}}", ""]);
// Exports
exports.locals = {
	"teamInfoWrap": "_16Ond",
	"teamName": "f0gJ3",
	"teamMinutes": "_3o-ZT",
	"inner": "_2BTCg",
	"outer": "dJn5M",
	"rank1": "_3fjKf",
	"rank2": "_2wHeZ",
	"rank3": "_2_qFP",
	"wrap": "_2-5Uj",
	"position": "_2PtqG",
	"teamNameWrap": "_2OLVu"
};
module.exports = exports;
