// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2mRvh{display:block;padding:.6875rem 1.625rem}.iVaul{font-size:.875rem}._2k7Hn{padding:12px 17px;color:#949eb2}._2k7Hn.active,._2k7Hn:hover{position:relative;color:#ea5b0c}", ""]);
// Exports
exports.locals = {
	"mobileNavigation": "_2mRvh",
	"navItem": "iVaul",
	"navLink": "_2k7Hn"
};
module.exports = exports;
