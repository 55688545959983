import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { debounce } from 'underscore';
import s from './ListFilter.scss';

class ListFilter extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    delay: PropTypes.number,
    filter: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    placeholder: '',
    delay: 300,
    filter: '',
    className: '',
  };

  state = {
    filter: this.props.filter,
  };

  componentDidMount() {
    const { delay, onChange } = this.props;

    this.debouncedKeyUp = debounce(e => {
      if (e && e.target && e.target.value) {
        onChange(e.target.value);
      } else {
        onChange('');
      }
    }, delay);
  }

  componentWillReceiveProps(nextProps) {
    const { filter } = this.state;

    if (typeof nextProps.filter === 'string') {
      if (nextProps.filter !== filter) {
        this.setState({
          filter: nextProps.filter,
        });
      }
    }
  }

  onKeyChange(e) {
    this.setState({
      filter: e.target.value,
    });
    e.persist();
    this.debouncedKeyUp(e);
  }

  render() {
    const { placeholder, className } = this.props;
    const { filter } = this.state;

    return (
      <div className={className}>
        <div className={s.filterInputContainer}>
          <input
            className={s.filter}
            type="text"
            value={filter}
            name="filter"
            onChange={e => this.onKeyChange(e)}
            placeholder={placeholder}
          />
          <i className={`${s.filterIcon} icon icon-search`} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ListFilter);
