import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EventUnsubscribe.scss';
import { getUrlParams, removeClass } from '../../utils/utility';

class EventUnsubscribe extends React.Component {
  static propTypes = {
  };

  static contextTypes = {
    i18n: PropTypes.object,
  };

  state = {
    res: null,
  };

  componentDidMount() {
    const { i18n } = this.context;
    let res;
    // eslint-disable-next-line no-cond-assign
    if (res = getUrlParams(window.location.href, 'err')) {
      const label = `subscribe.response.${res}`;
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        res: i18n.t(label),
        type: 'danger',
      });
      this.timer = setTimeout(this.setAlertTimer, 7000);
    // eslint-disable-next-line no-cond-assign
    } else if (res = getUrlParams(window.location.href, 'suc')) {
      const label = `subscribe.response.${res}`;
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        res: i18n.t(label),
        type: 'success',
      });
      this.timer = setTimeout(this.setAlertTimer, 7000);
    }
  }

  setAlertTimer() {
    clearTimeout(this.timer);
    const alert = document.getElementById('responseAlert');
    removeClass(alert, 'show');
  }

  render() {
    const { i18n } = this.context;

    const {
      res,
      type,
    } = this.state;

    return (
      <div>
        {res && (
          <Alert color={type} id="responseAlert" className={s.message}>
            {res}
          </Alert>
        )}
      </div>
    );
  }
}

export default withStyles(s)(EventUnsubscribe);
