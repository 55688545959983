import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable react/no-danger */

class HtmlEmbed extends React.Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
  };

  render() {
    const { content } = this.props;

    return (
      <div className="html-embed" dangerouslySetInnerHTML={{ __html: content }} />
    );
  }
}

export default HtmlEmbed;
