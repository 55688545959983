// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2p84i{display:table;width:100%;font-family:Montserrat,sans-serif}.T92Yz{font-size:12px}._1DJsX{display:table-row;position:relative}._3TQ4x{display:table-cell;font-size:22px;font-weight:600}svg.rv-xy-plot__inner{overflow:visible}._2DInT{padding-left:15px}._3TQ4x,.wnCGc{padding:12px 0 18px;border-bottom:1px solid rgba(148,158,178,.5)}._3toM-{margin-bottom:28px;font-size:22px}._1TIny{display:block;height:24px;margin-right:5px;float:left}._3SYq6{margin-left:5px;color:#373a3c}._3SYq6:hover{color:#373a3c;text-decoration:none}.B8I24{display:block;width:100%}._19XgQ{margin-bottom:8px;font-size:20px}.P6nkp{white-space:pre}._3X3kp{display:inline-block;width:20px;height:24px;background-repeat:no-repeat;background-position:50%;background-size:contain}._48i0n{margin-right:18px}._48i0n:last-child{margin-right:0}.wnCGc{display:block;width:100%}._2eJSu{font-size:16px}@media(min-width:768px){._3TQ4x,.wnCGc,.B8I24{padding:12px 0 18px;border-bottom:1px solid rgba(148,158,178,.5)}.B8I24{padding-left:28px}.wnCGc,.B8I24{display:table-cell;width:auto}}", ""]);
// Exports
exports.locals = {
	"teamWrap": "_2p84i",
	"factorText": "T92Yz",
	"team": "_1DJsX",
	"rank": "_3TQ4x",
	"graphWrap": "_2DInT",
	"stats": "wnCGc",
	"trendTitle": "_3toM-",
	"minutesBar": "_1TIny",
	"tooltip": "_3SYq6",
	"teamInfo": "B8I24",
	"teamName": "_19XgQ",
	"trendWrap": "P6nkp",
	"communityBadge": "_3X3kp",
	"communityLink": "_48i0n",
	"minutes": "_2eJSu"
};
module.exports = exports;
