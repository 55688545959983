import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Alert } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EventRegistration.scss';
import { isValidEmail } from '../../utils/utility';
import { EVENT_CATEGORY_GYM, EVENT_CATEGORY_HIKE } from '../../api/gemeinde-duell/constants';

class EventRegistration extends React.Component {
  static propTypes = {
    eventData: PropTypes.shape().isRequired,
  };

  static contextTypes = {
    i18n: PropTypes.object,
    fetch: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    const { i18n } = this.context;

    moment.locale(i18n.t('moment.locale'));
  }

  state = {
    formSuccess: false,
    formFail: false,
    formMessage: null,
    alertSalutation: false,
    alertFirstName: false,
    alertLastName: false,
    alertEmail: false,
    alertDisclaimer: false,
    submitDisabled: false,
    firstName: '',
    lastName: '',
    email: '',
    privacyChecked: false,
    hideForm: false,
  };

  onSubmitSuccess() {
    this.setState({
      formSuccess: true,
      formFail: false,
      submitDisabled: false,
      firstName: '',
      lastName: '',
      email: '',
      privacyChecked: false,
    });
  }

  onSubmitError() {
    this.setState({
      formSuccess: false,
      formFail: true,
      submitDisabled: false,
    });
  }

  getTitle(categoryId, i18n) {
    if (categoryId === EVENT_CATEGORY_GYM) {
      return <h3 className={s.title}>{i18n.t('registration.titleGym')}</h3>;
    }

    if (categoryId === EVENT_CATEGORY_HIKE) {
      return <h3 className={s.title}>{i18n.t('registration.titleHike')}</h3>;
    }

    return <h3 className={s.title}>{i18n.t('registration.registerTitle')}</h3>;
  }

  getDescription(categoryId, i18n) {
    if (categoryId === EVENT_CATEGORY_GYM) {
      return <p dangerouslySetInnerHTML={{ __html: i18n.t('registration.descriptionGym') }} />;
    }

    if (categoryId === EVENT_CATEGORY_HIKE) {
      return <p dangerouslySetInnerHTML={{ __html: i18n.t('registration.descriptionHike') }} />;
    }

    return <p>{i18n.t('registration.infoText')}</p>;
  }

  handleInputChange = event => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event, id) => {
    const { salutation, firstName, lastName, email, privacyChecked, submitDisabled } = this.state;

    if (submitDisabled) {
      return;
    }

    const { fetch } = this.context;
    let validationError = false;

    event.preventDefault();

    this.setState({
      alertSalutation: false,
      alertFirstName: false,
      alertLastName: false,
      alertEmail: false,
      alertDisclaimer: false,
      submitDisabled: true,
    });

    if (!salutation) {
      this.setState({ alertSalutation: true });
      validationError = true;
    }

    if (!firstName || firstName.trim() === '') {
      this.setState({ alertFirstName: true });
      validationError = true;
    }

    if (!lastName || lastName.trim() === '') {
      this.setState({ alertLastName: true });
      validationError = true;
    }

    if (!isValidEmail(email)) {
      this.setState({ alertEmail: true });
      validationError = true;
    }

    if (!privacyChecked) {
      this.setState({ alertDisclaimer: true });
      validationError = true;
    }

    if (validationError) {
      this.setState({ submitDisabled: false });
      return;
    }

    if (!submitDisabled) {
      const data = {
        salutation,
        firstname: firstName,
        lastname: lastName,
        email,
      };

      this.setState({
        formSuccess: false,
        formFail: false,
        formMessage: '',
        submitDisabled: true,
      });

      fetch(`/api/events/${id}/subscribe`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        })
        .then(async res => {
          const responseData = await res.json();

          this.setState({ formMessage: responseData.message });

          if (res.ok) {
            this.onSubmitSuccess();
            this.setState({ hideForm: true });
          } else {
            this.onSubmitError();
          }
        })
        .catch(error => {
          if (error.message) {
            this.setState({ formMessage: error.message });
          }

          this.onSubmitError(error);
        });
    }
  };

  render() {
    const { i18n } = this.context;
    const { eventData } = this.props;
    const { hideForm } = this.state;

    const privacy = {
      url: `/${i18n.t('registration.disclaimerUrl')}`,
    };

    const {
      formSuccess,
      formFail,
      formMessage,
      submitDisabled,
      alertSalutation,
      alertFirstName,
      alertLastName,
      alertEmail,
      alertDisclaimer,
      privacyChecked,
      firstName,
      lastName,
      email,
    } = this.state;

    return (
      <div className="componentContainer">
        <div className="contentContainer">
          <div className={s.container}>
            {this.getTitle(eventData.categoryId, i18n)}
            <div>
              <p>
                {i18n.t('registration.deadline')}
:&nbsp;
                <span className={s.deadline}>
                  {moment.tz(eventData.registrationEndDate, i18n.t('moment.timezone')).format(i18n.t('moment.dateFull'))}
                </span>
              </p>
              <div>
                {this.getDescription(eventData.categoryId, i18n)}
              </div>
            </div>
            {!hideForm && (
              <form id="registrationForm" className={`form ${s.form} `} onSubmit={event => this.handleSubmit(event, eventData.id)}>
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="salutationM">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="salutation"
                        id="salutationM"
                        value={i18n.t('registration.salutationM')}
                        onChange={this.handleInputChange}
                        disabled={eventData.registrationLimitExceeded}
                      />
                      {i18n.t('registration.salutationM')}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="salutationF">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="salutation"
                        id="salutationF"
                        value={i18n.t('registration.salutationF')}
                        onChange={this.handleInputChange}
                        disabled={eventData.registrationLimitExceeded}
                      />
                      {i18n.t('registration.salutationF')}
                    </label>
                  </div>
                  {alertSalutation && (
                    <div className="form-error-label">
                      {i18n.t('subscribe.validation.invalidSalutation')}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className={(alertFirstName) ? 'form-control alert' : 'form-control'}
                    type="text"
                    placeholder={i18n.t('registration.firstname')}
                    name="firstName"
                    value={firstName}
                    onChange={this.handleInputChange}
                    disabled={eventData.registrationLimitExceeded}
                  />
                  {alertFirstName && (
                    <div className="form-error-label">
                      {i18n.t('subscribe.validation.invalidFirstname')}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className={(alertLastName) ? 'form-control alert' : 'form-control'}
                    type="text"
                    placeholder={i18n.t('registration.lastname')}
                    name="lastName"
                    value={lastName}
                    onChange={this.handleInputChange}
                    disabled={eventData.registrationLimitExceeded}
                  />
                  {alertLastName && (
                    <div className="form-error-label">
                      {i18n.t('subscribe.validation.invalidLastname')}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className={(alertEmail) ? 'form-control alert' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder={i18n.t('registration.email')}
                    value={email}
                    onChange={this.handleInputChange}
                    disabled={eventData.registrationLimitExceeded}
                  />
                  {alertEmail && (
                    <div className="form-error-label">
                      {i18n.t('subscribe.validation.invalidEmail')}
                    </div>
                  )}
                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="privacyCheck">
                    <input
                      type="checkbox"
                      checked={privacyChecked}
                      onChange={() => this.setState({ privacyChecked: !privacyChecked })}
                      name="privacyCheck"
                      className={(alertDisclaimer) ? 'form-check-input alert' : 'form-check-input'}
                      id="privacyCheck"
                      disabled={eventData.registrationLimitExceeded}
                    />
                    {' '}
                    <span className={s.privacy} dangerouslySetInnerHTML={{ __html: i18n.t('registration.disclaimer', privacy) }} />
                    {alertDisclaimer && (
                      <div className="form-error-label">
                        {i18n.t('subscribe.validation.invalidDisclaimer')}
                      </div>
                    )}
                  </label>
                </div>
                <div className="form-submit">
                  <button type="submit" disabled={submitDisabled || eventData.registrationLimitExceeded} className="btn btn-outline-primary">
                    {i18n.t('registration.registerNow')}
                  </button>
                  {eventData.registrationLimitExceeded && (
                    <div className={s.exceeded}>
                      {i18n.t('subscribe.validation.registrationLimitExceeded')}
                    </div>
                  )}
                </div>
                {(formSuccess || formFail) && (
                  <div className={s.alert}>
                    {formSuccess && (
                      <Alert color="success">
                        {formMessage}
                      </Alert>
                    )}
                    {formFail && (
                      <Alert color="danger">
                        {formMessage}
                      </Alert>
                    )}
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(EventRegistration);
