import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Paragraph.scss';
/* eslint-disable react/no-danger */

class Paragraph extends React.Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
  };

  render() {
    const { content } = this.props;
    return (
      <div className={`paragraph ${s.paragraph}`} dangerouslySetInnerHTML={{ __html: content }} />
    );
  }
}

export default withStyles(s)(Paragraph);
