// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3s4xH{padding:.6875rem 1.625rem 1.625rem}._2vHhC{display:block;position:relative;padding:0 14px 24px;border:0;background:rgba(0,0,0,0);color:#949eb2;font-family:Montserrat,sans-serif;font-size:.875rem;font-weight:600;text-transform:uppercase}._2vHhC.active,._2vHhC:hover{background:#fff;color:#ea5b0c;cursor:pointer}._2vHhC.active:before,._2vHhC:hover:before{position:absolute;top:-2px;left:0;width:1px;height:24px;background:red;content:\"\"}", ""]);
// Exports
exports.locals = {
	"languageMenu": "_3s4xH",
	"languageButton": "_2vHhC"
};
module.exports = exports;
